import { Component, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { Globals } from 'app/globals';

@Component({
  selector: 'app-consent-popup',
  templateUrl: './consent-popup.component.html',
  styleUrls: ['./consent-popup.component.scss']
})
export class ConsentPopupComponent implements ICellEditorAngularComp, AfterViewInit{
  consent: any = [];

  constructor(public globals: Globals){

  }

  ngAfterViewInit(){
  }

  agInit(params: any): void{
    this.consent = params.value;
  }

  focusIn(): void{
  }

  focusOut(): void{
  }

  getValue(): any{
  }

  isCancelAfterEnd(): boolean{
    return true;
  }

  isCancelBeforeStart(): boolean{
    return false;
  }

  isPopup(): boolean{
    return true;
  }
}
