import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Globals } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-aggrid-name-column',
  templateUrl: './aggrid-name-column.component.html',
  styleUrls: ['./aggrid-name-column.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgGridNameColumnComponent implements ICellRendererAngularComp{

  public data: any;

  public candidateData: any = null;

  private female;

  private male;

  private type;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public globals: Globals){

  }

  agInit(params: any): void{
    this.type = params.type;
    this.female = this.globals.femaleAvatar;
    this.male = this.globals.avatar;
    this.candidateData = this.transformNameObject(params.data);
  }

  transformNameObject(data){
    return {
      blacklisted: data.blacklisted,
      blacklisted_candidate: data.blacklisted_candidate,
      name: (typeof data.name === 'string') ? data.name : data.name.name,
      linkedin: data.linkedin ? data.linkedin : data.name.linkedin,
      photo: data.photo ? data.photo : data.name.photo,
      id: data.id ? data.id : data.candidate_uuid,
      comments: data.comments,
      gender: data.gender
    };
  }

  refresh(): boolean{
    return true;
  }

  setAvatar = () => {
    if (this.candidateData.photo){
      return this.candidateData.photo;
    } else if (this.candidateData.gender === 'Female'){
      return this.female;
    } else {
      return this.male;
    }
  };

  getLastCommentTag(comments){
      for (let i = comments.length - 1; i >= 0; i--){
          if (comments[i].market_mapping_tag){
              return comments[i].market_mapping_tag;
          }
      }
      return null;
  }

  borderSetter = () => {
    if (this.type == 'market_mapping' && this.globals.checkIfExists('permission', 'view_market_mapping_candidates')){
      const comments = [...this.candidateData.comments];
      const distinctTag = this.getLastCommentTag(comments);
      return distinctTag ? `3px solid ${distinctTag.color_code} ` : `none`;
    }
    return null;
  };

  tagStyleSetter(type){
      switch (type){
        case "color": {
          if (this.candidateData.blacklisted && "text_color" in this.candidateData.blacklisted){
            return this.candidateData.blacklisted.text_color;
          }
          if (this.candidateData.blacklisted_candidate && "text_color" in this.candidateData.blacklisted_candidate){
            return this.candidateData.blacklisted_candidate.text_color;
          }
          return null;
        }
        case "backgroundColor": {
          if (this.candidateData.blacklisted && "background_color" in this.candidateData.blacklisted){
            return this.candidateData.blacklisted.background_color;
          }
          if (this.candidateData.blacklisted_candidate && "background_color" in this.candidateData.blacklisted_candidate){
            return this.candidateData.blacklisted_candidate.background_color;
          }
          return null;
        }
        default:
          return "#fff";
      }
  }

  nameClick(e: Event){
    e.preventDefault();
    if (this.candidateData.id){
        this.router.navigate([],
            {
              relativeTo: this.activatedRoute,
              queryParams: {
                'drawer_parent': 'candidate-drawer',
'type': 'view-candidate',
                'job_uuid': this.router.parseUrl(this.router.url).root.children.primary.segments[1] ? this.router.parseUrl(this.router.url).root.children.primary.segments[1].toString() : null,
                'candidate_uuid': this.candidateData.id
              }
            });
    }
  }
}
