<div class="d-flex align-items-center">
    <div class="mr-3">
        <img [src]="setAvatar()" class="height-35 width-35 rounded-circle margin-table-pic" [ngStyle]="{border: borderSetter()}">
    </div>
    <div class="line-height-20-px">
        <div class="d-flex">
            <a *ngIf="candidateData.linkedin" class="mr-2" [href]="candidateData.linkedin" target="_blank">
                <img [src]="globals.linkedin">
            </a>
            <div *ngIf="candidateData.name" class="cursor-pointer big-search-primary-color" style="text-decoration: underline;" (click)="nameClick($event)">
                {{candidateData.name}}
            </div>
        </div>
        <div class="blacklist-toggle" *ngIf="(candidateData.blacklisted ||candidateData.blacklisted_candidate) && (globals.checkIfExists('group', 'internal'))">
            <span class="badge-tags-inverse nowrap align-self-center d-inline"
            [ngStyle]="{
                color: tagStyleSetter('color'),
                backgroundColor: tagStyleSetter('backgroundColor')}"
            [appStickyPopover]="popContent" container="body" placement="right">Blacklisted</span>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="card scroll aggird-popover">
        <ul class="list-group list-group-flush">
          <li *ngIf="candidateData.blacklisted" class="list-group-item ">
               <h5 class="card-title">Blacklisted company</h5>
               <p class="card-text">{{candidateData.name}} current company {{candidateData.blacklisted.hover_data}}</p>
        </li>
          <li *ngIf="candidateData.blacklisted_candidate" class="list-group-item">
            <h5 class="card-title">Blacklisted candidate</h5>
            <p class="card-text">{{candidateData.name}} is {{candidateData.blacklisted_candidate.hover_data}}</p>
            <div *ngIf="candidateData.blacklisted_candidate.data.reason">
                <p class="card-title">Blacklisted reason</p>
                <p class="card-text" [innerHTML]="candidateData.blacklisted_candidate.data.reason"></p>
            </div>
            </li>
        </ul>
      </div>
</ng-template>

