import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class DataCandidateService{
    getCandidatesExport(job_uuid, type, body): Observable<any>{
        return this.httpClient.post(environment.platformBackendURL + '/candidates/job/' + job_uuid + '/export?type=' + type, body, { responseType: 'blob' });
    }

    // Observable string sources
    private onCommentAdded = new Subject<any>();

    // Observable string streams
    onCommentAdded$ = this.onCommentAdded.asObservable();

    constructor(private httpClient: HttpClient){ }

    updateCandidate(uuid, body): Observable<any>{
      return this.httpClient.put(environment.platformBackendURL + '/candidates/' + uuid, body);
    }

    updateCandidateApplication(uuid, body): Observable<any>{
      return this.httpClient.put(environment.platformBackendURL + '/candidates/applications/' + uuid, body);
    }

    // Service message commands
    emitComment(comment, candidate_id){
        this.onCommentAdded.next({ 'comment': comment, 'candidate_id': candidate_id });
    }

    getCardsData(jobId): Observable<any>{
        return this.httpClient.get(environment.platformBackendURL + '/candidates/job/' + jobId + '/pipelineData');
    }

    getCandidates(jobId, type, body?): Observable<any>{
        return this.httpClient.post(environment.platformBackendURL + '/candidates/job/' + jobId + '?type=' + type, body);
    }

    createCustomView(type, userView): Observable<any>{
        const data = {
            custom_table_view: {
                type: 'mapped_candidates',
                sub_type: type,
                view: userView
            }
        };
        return this.httpClient.put(environment.platformBackendURL + '/me', data);
    }

    createCalibrationList(data): Observable<any>{
        return this.httpClient.post(environment.platformBackendURL + '/calibration', data);
    }

    updateCalibrationList(data): Observable<any>{
        return this.httpClient.put(environment.platformBackendURL + '/calibration', data);
    }

    introduceCandidates(data): Observable<any>{
        return this.httpClient.post(environment.platformBackendURL + '/cit', data);
    }

    getUsers(stage, uuid: any): Observable<any>{
        let params = uuid ? '?job_uuid=' + uuid : '';
        if (stage === 'market_mapping'){
            params = '?group=internal' + (uuid ? '&job_uuid=' + uuid : '');
        }
        return this.httpClient.get(environment.platformBackendURL + '/resources/users' + params);
    }

    saveComment(data): Observable<any>{
        return this.httpClient.post(environment.platformBackendURL + '/candidates/comment', data);
    }

    getResources(value, params): Observable<any>{
      return this.httpClient.get(environment.platformBackendURL + '/resources/' + value + params);
    }

    getResourcesByType(type, origin?): Observable<any>{
      return this.httpClient.get(environment.platformBackendURL + '/resources?type=' + type + (origin ? '&origin=' + origin : ''));
    }

    getAsyncResources(value, name): Observable<any>{
      return this.httpClient.get(environment.platformBackendURL + '/resources/' + value + (name ? '?name=' + name : ''));
    }

    getPodReport(job_uuid){
        const url: string = environment.djangoURL + '/rpo/report?uuid=' + job_uuid;
        return this.httpClient.get(url, { responseType: 'blob' });
    }

    getCompanyDealroom(body): Observable<any>{
        return this.httpClient.post(environment.dealroomURL + '/companies', body);
    }

    sendExportDataToServer(job_uuid: string, user_uuid: string, data: string){
        const body = {
            "user_uuid": user_uuid,
            "job_uuid": job_uuid,
            "data": data
        };
        return this.httpClient.post(environment.platformBackendURL + '/users/userExports', body);
    }
}
