import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

declare let mixpanel: any;

@Injectable()
export class Globals{
  data: any;

  avatar = 'assets/img/svg/user.svg';

  femaleAvatar = 'assets/img/svg/user-female.svg';

  companyAvatar = 'assets/img/svg/company_avatar.svg';

  teamAvatar = 'assets/img/svg/team_avatar.png';

  linkedin = '/assets/img/svg/linkedin.svg';

  loading = 'assets/img/oval.svg';

  labels: any;

  substatuses = 'connection_request,reach_out,reference_partner,re-engage,engaged,on_hold,not_our_candidate,personalized_message';

  constructor(private http: HttpClient, private injector: Injector){
  }

  load(){
    if (localStorage.getItem('access-token')){
      return new Promise((resolve) => {
        this.http.get(environment.platformBackendURL + '/me').subscribe(
          (data) => {
            this.data = data;
            this.identifyMixpanelUser();
            resolve(true);
          },
          () => {
            const route = this.injector.get(Router);
            route.navigate(['/login']);
            resolve(false);
          },
          () => {
            this.labels = this.data.labels;
          });
      });
    }
    return null;
  }

  countJobs(){
    if (this.data.jobs){
      return this.data.jobs.length;
    } else {
      return 0;
    }
  }

  getFirstJob(){
    if (this.data.jobs){
      return this.data.jobs[0].uuid;
    } else {
      return null;
    }
  }

  checkIfExists(array, groupValue): boolean{
    if (array == 'group'){
      return this.data.groups.some(function (el){
        return el.value === groupValue;
      });
    } else if (array == 'permission'){
      return this.data.permissions.some(function (el){
        return el.value === groupValue;
      });
    } else if (array == 'role'){
      return this.data.roles.some(function (el){
        return el.value === groupValue;
      });
    }
    return null;
  }

  identifyMixpanelUser(): void{
    const userId = this.data.id;
    const userEmail = this.data.email;
    const userName = this.data.contact.name;
    const userRole = this.data.roles[0].value;

    if (mixpanel){
      mixpanel.people.set({
        '$email': userEmail,
        '$name': userName,
        '$role': userRole
      });

      mixpanel.identify(userId);
    }
  }
}
