import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';
import { DataCandidateService } from '../../candidatesData.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comment-popup.component.html',
  styleUrls: ['./comment-popup.component.scss']
})
export class CommentsComponent implements ICellEditorAngularComp, AfterViewInit{
  params: any = [];

  newComment: any;

  taggedUser: any = [];

  indexOf: any = 0;

  filteredUsers: any = [];

  disableSendBtn = false;

  marketMappingResources: any = [];

  tag: any = null;

  @ViewChild('tagsDropDown', { static: true }) tagsDropDown: NgbDropdown;

  @ViewChild('commentsDiv', { read: ViewContainerRef }) public container;

  public innerHeight: number;

  constructor(public globals: Globals, private candidateDataService: DataCandidateService){
  }

  ngAfterViewInit(){
    if (this.params.value.comments.length){
      setTimeout(() => {
        this.container.element.nativeElement.focus();
      });
    }

  }

  agInit(params: any): void{
    this.innerHeight = window.innerHeight;
    this.params = [];
    this.params = params;
    this.params.value.additionalData.resources.comments = this.params.value.additionalData.resources.comments.filter(item => item.value != 'not_reviewed');
    if (this.params.value.additionalData.resources.comments.find(item => item.active && item.active == true)){
      this.params.value.additionalData.resources.comments.find(item => item.active && item.active == true).active = false;
    }
  }

  focusIn(): void{
  }

  focusOut(): void{
  }

  getValue(): any{
  }

  destroy(): void{
    this.params = [];
  }

  emitComment(comment, candidate_id){
    this.candidateDataService.emitComment(comment, candidate_id);
  }

  isCancelAfterEnd(): boolean{
    return true;
  }

  isCancelBeforeStart(): boolean{
    return false;
  }

  isPopup(): boolean{
    return true;
  }

  myOnKeyDown(event){
    const key = event.which || event.keyCode;
    if (key){
      event.stopPropagation();
    }
  }

  applyCommentFilter(filter){
    if (this.params.value.additionalData.resources.comments.find(item => item.value != filter.value && (item.active && item.active == true))){
      this.params.value.additionalData.resources.comments.find(item => item.value != filter.value && (item.active && item.active == true)).active = false;
    }

    filter.active = !filter.active;
    filter.active ? this.tag = filter : this.tag = null;
  }

  getTagName(character){
    if (character && character.includes('@')){
      this.indexOf = character.lastIndexOf('@');
      this.filteredUsers = [];
      let user = [];

      if (this.params.value.additionalData.stage === 'market_mapping'){
        user = this.params.value.additionalData.resources.users;
      } else {
        user = this.params.value.additionalData.resources.usersLonglist;
      }

      user.forEach(element => {
        if (element.name.toLowerCase().startsWith(character.substr(this.indexOf + 1).toLowerCase())){
          this.filteredUsers.push(element);
        }
      });

      if (this.filteredUsers.length > 0){
        this.tagsDropDown.open();
      }
    } else {
      this.tagsDropDown.close();
      this.filteredUsers = [];
    }
  }

  chooseUser(user){
    this.filteredUsers = [];
    this.newComment = this.newComment.substring(0, this.indexOf);
    this.newComment += user.name + ' ';
    this.taggedUser.push({ 'uuid': user.uuid, 'name': user.name });
    this.tagsDropDown.close();
  }

  onCommentSave(description){
    this.disableSendBtn = true;
    if (this.taggedUser){
      this.taggedUser.forEach((el) => {
        if (el.name){
          const element = `<strong>@` + el.name + `</strong>`;
          if (!description.includes(element)){
            description = description.split(el.name).join(element);
          }
        }
      });
    }
    if (description || this.tag){
      const users = [];
      if (this.taggedUser){
        this.taggedUser.forEach((el) => {
          if (el.uuid){
            users.push({ 'uuid': el.uuid });
          }
        });
      }
      const data = {
        candidate_uuid: this.params.node.data.id,
        description: description ? description : null,
        job_uuid: this.params.value.additionalData.job_id,
        users: users ? users : [],
        tag: this.tag
      };
      this.candidateDataService.saveComment(data)
        .subscribe(
          (dataComment) => {
            this.params.value.comments.push(dataComment);
            this.emitComment(dataComment, data.candidate_uuid);
            this.newComment = null;
            if (this.tag){
              this.params.value.additionalData.resources.comments.find(item => item.value == this.tag.value).active = false;
              this.tag = null;
            }

          });
      this.disableSendBtn = false;
      this.taggedUser = [];
    } else {
      this.disableSendBtn = false;
      this.taggedUser = [];
    }
  }
}
