import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommentsComponent } from './table-popups/comment-poput/comment-popup.component';
import { Globals } from 'app/globals';
import { JobHistoryPopupComponent } from './table-popups/job-history-popup/job-history-popup.component';
import { RowNode } from 'ag-grid-community';
import { ConsentPopupComponent } from './table-popups/consent-popup/consent-popup.component';
import { CandidateCompensationPopupComponent } from './table-popups/candidate-compensation-popup/candidate-compensation-popup.component';
import { CapitalizeFirstPipe } from 'app/shared/pipes/capitalize-fist.pipe';
import { RemoveUnderscorePipe } from 'app/shared/pipes/remove-underscore.pipe';
import { AgGridDealroomColumnComponent } from 'app/shared/selectors/aggrid-dealroom-column/aggrid-dealroom-column.component';
import { CandidateRatingPopupComponent } from './candidate-rating-popup/candidate-rating-popup.component';
import { AgGridNameColumnComponent } from 'app/shared/selectors/aggrid-name-column/aggrid-name-column.component';

@Injectable()
export class CandidatesHelperService{
    femaleAvatar: any;

    maleAvatar: any;

    linkedInIcon: any;

    componentAlive = true;

    job: string = null;

    type: string = null;

    popupResources: any = [];

    constructor(private router: Router, private activatedRoute: ActivatedRoute, public globals: Globals,
        private removeUnderscorePipe: RemoveUnderscorePipe, private capitalizeFirstPipe: CapitalizeFirstPipe){
        this.femaleAvatar = this.globals.femaleAvatar;
        this.maleAvatar = this.globals.avatar;
        this.linkedInIcon = this.globals.linkedin;
    }

    createCustomColumn(column, job_id, type, popupResources?){
        let generatedColumn = {};
        const defaultColumnFields = {
            headerName: this.capitalizeFirstPipe.transform(this.removeUnderscorePipe.transform(column)),
            description: this.capitalizeFirstPipe.transform(this.removeUnderscorePipe.transform(column)),
            field: column,
            lockPosition: column === 'name' ? true : false,
            cellClass: column === 'name' ? "locked-col" : '',
            resizable: true,
            filter: false,
            filterType: null,
            filterResource: null,
            sortable: false,
            sort: null,
            comparator: () => 0
        };
        this.job = job_id;
        this.type = type;
        this.popupResources = popupResources;
        if (column === 'name'){
            generatedColumn = {
                disabled: true,
                minWidth: 250,
                width: 250,
                sortable: true,
                cellRendererFramework: AgGridNameColumnComponent,
                cellRendererParams: {
                    type: type
                }
            };
        } else if (column === 'consent'){
            generatedColumn = {
                filterType: "custom",
                filterResource: this.getDropdownResource(column),
                headerName: 'Consent (Double click to see consent)',
                sortable: true,
                editable: (params) => params.data.consent,
                tooltip: () => 'Click to see full consent',
                cellRenderer: (params) => {
                    if (params.data.consent){
                        const divMain = document.createElement('div');
                        divMain.classList.add('row');
                        divMain.innerHTML = '<div class="col"> ' +
                            '<a class="mr-2 mb-2">' +
                            'View consent' +
                            '</a>';
                        return divMain;
                    }
                    return null;
                },
                cellEditorFramework: ConsentPopupComponent,
                valueGetter: (params) => {
                    if (params.data.consent){
                        return params.data.consent;
                    }
                    return null;
                }
            };
        } else if (column === 'comments'){
            generatedColumn = {
                filterType: "custom",
                filterResource: this.getDropdownResource(column),
                headerName: 'Comments (Double click to see full conversation)',
                editable: true,
                sortable: true,
                cellClass: "line-height-20-px",
                tooltip: () => 'Click to see full conversation',
                cellRenderer: (params) => {
                    const totalComments = params.data.comments.length;
                    if (totalComments){
                        const marketMappingTag = this.getLastCommentTag(params.data.comments);
                        if (marketMappingTag && this.type == 'market_mapping' && this.globals.checkIfExists('permission', 'view_market_mapping_candidates')){
                            const div = document.createElement('span');
                            div.classList.add('display-inline-flex');
                            div.innerHTML = "<div class='badge-tag white' style='background-color: " + marketMappingTag.color_code + "'>" + marketMappingTag.description + "</div>";
                            const commentSpan = document.createElement('span');
                            commentSpan.style.padding = "2px 6px";
                            commentSpan.innerHTML = this.getLatestComment(params.data.comments);
                            div.appendChild(commentSpan);
                            return div;
                        } else {
                            const divComments = document.createElement('div');
                            divComments.innerHTML = this.getLatestComment(params.data.comments);
                            divComments.classList.add('cursor-pointer');
                            return divComments;
                        }
                    }
                    return null;
                },
                cellEditorFramework: CommentsComponent,
                valueGetter: (rowNode: RowNode) => {
                    rowNode.data.additionalData = { 'job_id': this.job, 'stage': this.type, 'resources': this.popupResources };
                    return rowNode.data;
                }
            };
        } else if (column === 'primary_email'){
            generatedColumn = {
                headerName: this.globals.checkIfExists('group', 'internal') ? ('Primary email ✎ (Double click on the cell to edit email)') : column.headerName,
                singleClickEdit: false,
                editable: this.globals.checkIfExists('group', 'internal') ? true : false,
                sortable: true
            };
        } else if (column === 'calibration_cit_exists'){
            generatedColumn = {
                filterType: "dropdown",
                filterResource: this.getDropdownResource(column),
                cellRenderer: (params) => {
                    const divMain = document.createElement('div');
                    divMain.classList.add('d-flex');
                    const div1 = document.createElement('div');
                    const div = document.createElement('div');
                    if (params.value){
                        if (params.data.calibration_cit_exists.calibration_exist){
                            const span = document.createElement('span');
                            span.classList.add('badge');
                            span.classList.add('ml-3');
                            span.classList.add('badge-secondary');
                            const i = document.createElement('i');
                            i.classList.add('fa');
                            i.classList.add('fa-circle');
                            span.appendChild(i);
                            div1.appendChild(span);
                            const label = document.createElement('div');
                            label.innerText = 'CL';
                            label.style.whiteSpace = "initial";
                            label.style.textAlign = "left";
                            label.style.display = "inline-flex";
                            div1.appendChild(label);
                        }
                        if (params.data.calibration_cit_exists.cit_exists){
                            const span = document.createElement('span');
                            span.classList.add('badge');
                            span.classList.add('ml-3');
                            span.classList.add('badge-primary');
                            const i = document.createElement('i');
                            i.classList.add('fa');
                            i.classList.add('fa-circle');
                            span.appendChild(i);
                            div.appendChild(span);
                            const label = document.createElement('div');
                            label.innerText = 'CIT';
                            label.style.whiteSpace = "initial";
                            label.style.textAlign = "left";
                            label.style.display = "inline-flex";
                            div.appendChild(label);
                        }
                    }
                    divMain.appendChild(div);
                    divMain.appendChild(div1);
                    return divMain;
                },
                valueGetter:
                    function (params){
                        if (params.data.calibration_cit_exists){
                            return params.data.calibration_cit_exists.calibration_exist + params.data.calibration_cit_exists.cit_exists;
                        }
                        return null;
                    }
            };
        } else if (column === 'candidate_rating'){
            generatedColumn = {
                headerName: this.globals.checkIfExists('group', 'internal') ? ('Candidate rating ✎ (Double click on the cell to edit candidate rating)') : column.headerName,
                filterType: "dropdown",
                filterResource: this.getDropdownResource(column),
                field: column,
                editable: this.globals.checkIfExists('group', 'internal') ? true : false,
                sortable: true,
                cellEditorFramework: CandidateRatingPopupComponent,
                valueGetter: function (params){
                  if (params.data[column])
                    return params.data[column].description;
                  return null;
                }
            };

        } else if (column === 'substatus'){
            generatedColumn = {
                filterType: "dropdown",
                filterResource: this.getDropdownResource(column),
                headerName: this.globals.checkIfExists('group', 'internal') ? 'Substatus ✎ (Double click on the cell to edit substatus)' : column.headerName,
                sortable: true,
                cellRenderer: (params) => {
                    if (params.data.substatus){
                        const divMain = document.createElement('div');
                        divMain.classList.add('row');
                        const div1 = document.createElement('div');
                        div1.classList.add('col-12');
                        const span = document.createElement('span');
                        span.classList.add('badge');
                        span.classList.add('m-0');
                        const i = document.createElement('i');
                        i.classList.add('fa');
                        i.classList.add('fa-circle');
                        i.style.color = this.globals.checkIfExists('group', 'client') ? params.data.substatus.client_color_code : params.data.substatus.color_code;
                        span.appendChild(i);
                        div1.appendChild(span);
                        const label = document.createElement('div');
                        label.innerText = this.globals.checkIfExists('group', 'client') ? params.data.substatus.client_description : params.data.substatus.description;
                        label.style.whiteSpace = "initial";
                        label.style.textAlign = "left";
                        label.style.lineHeight = "1.3";
                        label.style.display = "inline-flex";
                        div1.appendChild(label);
                        divMain.appendChild(div1);
                        return divMain;
                    }
                    return null;
                },
                valueFilterGetter: function (params){
                    if (params.data.substatus){
                        return params.data.substatus;
                    }
                    return null;
                },
                valueGetter: (rowNode: RowNode) => {
                    rowNode.data.substatusExport = rowNode.data.substatus ? (this.globals.checkIfExists('group', 'internal') ? rowNode.data.substatus.description : rowNode.data.substatus.client_description) : '';
                    return rowNode.data;
                }

            };
        } else if (column === 'stage_focus' || column === 'sector_focus' || column === 'other_experience_sector' || column === 'country_focus'){
            generatedColumn = {
                filterType: "dropdown",
                filterResource: this.getDropdownResource(column),
                sortable: true,
                cellRenderer: (params) => {
                    if (params.data[column] && params.data[column].length){
                        return params.data[column].join(', ');
                    }
                    return '';
                }
            };
        } else if (column === 'shortlisted_by'){
            generatedColumn = {
                filterType: "dropdown",
                filterResource: this.getDropdownResource(column),
                sortable: true,
                cellRenderer: (params) => {
                    if (params.data[column]){
                        return params.data[column].name + ' - ' + params.data[column].email;
                    }
                    return '';
                }
            };
        } else if (column === 'stage'){
            generatedColumn = {
                filterType: "dropdown",
                filterResource: this.getDropdownResource(column),
                sortable: true,
                cellRenderer: (params) => {
                    if (params.data[column]){
                        return params.data[column].description;
                    }
                    return '';
                }
            };
        } else if (column === 'location'){
            generatedColumn = {
                filterType: "dropdown",
                filterResource: this.getDropdownResource(column),
                sortable: true,
                cellRenderer: (params) => {
                    if (params.data[column]){
                        return params.data[column].description;
                    }
                    return '';
                }
            };
        } else if (column === 'reject_reason'){
            generatedColumn = {
                filterType: "dropdown",
                filterResource: this.getDropdownResource(column),
                sortable: false,
                cellRenderer: (params) => {
                    if (params.data[column]){
                        return params.data[column].created_by + ': <strong>' + params.data[column].reason_type + '</strong>';
                    }
                    return '';
                }
            };
        } else if (column === 'active_in_another_process' && this.type == 'market_mapping'){
            generatedColumn = {
                editable: (params) => params.data.active_in_another_process.length,
                cellRenderer: (params) => {
                    if (params.data.active_in_another_process){
                        const divMain = document.createElement('div');
                        divMain.classList.add('row');
                        const div1 = document.createElement('div');
                        div1.classList.add('col-12');
                        const span = document.createElement('span');
                        span.classList.add('badge');
                        span.classList.add('m-0');
                        const i = document.createElement('i');
                        i.classList.add('fa', 'fa-circle', params.data.active_in_another_process.length ? 'error-color' : 'success');
                        span.appendChild(i);
                        div1.appendChild(span);
                        const label = document.createElement('div');
                        label.innerHTML = params.data.active_in_another_process.length ? 'Active in another process<br>(Double click to view more details)' : 'Not active in any process';
                        label.style.whiteSpace = "initial";
                        label.style.textAlign = "left";
                        label.style.lineHeight = "1.3";
                        label.style.display = "inline-flex";
                        div1.appendChild(label);

                        divMain.appendChild(div1);
                        return divMain;
                    }
                    return null;
                },
                valueGetter: function (params){
                    return params.data.active_in_another_process;
                },
                cellEditorFramework: JobHistoryPopupComponent
            };
        } else if (column === 'hunting_ground' && this.type == 'market_mapping'){
            generatedColumn = {
                editable: () => false,
                cellRenderer: (params) => {
                    if (params.data.hunting_ground && params.data.hunting_ground.length){
                        const divMain = document.createElement('div');
                        params.data.hunting_ground.forEach(huntingGround => {
                            const div = document.createElement('span');
                            div.innerText = huntingGround.name;
                            div.style.wordWrap = 'break-word';
                            div.classList.add('cursor-pointer');
                            div.style.color = '#2c00c7';
                            div.style.textDecoration = 'underline';
                            div.addEventListener('click', (e) => {
                                e.preventDefault();
                                if (huntingGround.uuid){
                                    this.router.navigate(['/companies'],
                                        {
                                            queryParams: {
                                                'search_uuid': huntingGround.uuid,
                                                'job_uuid': job_id
                                            }
                                        });
                                }

                                div.style.color = '#2c00c7';
                            });
                            divMain.appendChild(div);
                        });
                        return divMain;
                    }
                    return null;

                },
                valueGetter: function (params){
                    return params.data.hunting_ground;
                }
            };
        } else if (column === 'current_employer'){
            generatedColumn = {
                headerName: 'Current employer (click to see company info)',
                sortable: true,
                cellRendererFramework: AgGridDealroomColumnComponent,
                cellRendererParams: {
                    origin: 'mapped_candidates'
                 }
            };
        } else if (column === 'compensation'){
            generatedColumn = {
                headerName: 'Compensation (Double click to see compensation)',
                editable: (params) => params.data.compensation,

                tooltip: () => 'Click to see full compensation',
                cellRenderer: (params) => {
                    if (params.data.compensation){
                        const divMain = document.createElement('div');
                        divMain.classList.add('row');
                        divMain.innerHTML = '<div class="col"> ' +
                            '<a class="mr-2 mb-2">' +
                            'View compensation' +
                            '</a>';
                        return divMain;
                    }
                    return null;
                },
                cellEditorFramework: CandidateCompensationPopupComponent,
                valueGetter: (params) => {
                    if (params.data.compensation){
                        return params.data.compensation;
                    }
                    return null;
                }
            };
        } else if (column === 'website' || column === 'skill' || column === 'ranking' || column === 'note'){
            generatedColumn = {
                filterType: "inputTag",
                filterResource: null,
                sortable: true
            };
        } else if (column === 'gender' || column === 'industries'
            || column === 'functional_expertise' || column === 'sub_functional_expertise' || column === 'nationality'){
            generatedColumn = {
                filterType: "dropdown",
                filterResource: this.getDropdownResource(column),
                sortable: true
            };
        } else if (column === 'current_job_title' || column === 'skype' || column === 'linkedin' || column === 'phone' || column === 'mobile' || column === 'created_at' || column === 'updated_at' ||
            column === 'market_mapping_date' || column === 'shortlisted_date' || column === 'sent_date' || column === '1st_interview_date' || column === '2nd_interview_date' || column === 'invoice_date'
            || column === 'offer_date' || column === 'placed_date' || column === 'rejected_date'){
            generatedColumn = {
              sortable: true
            };
        } else if (column === 'num_of_board_roles'){
            generatedColumn = {
              filterType: "dropdown",
              filterResource: this.getDropdownResource(column),
              sortable: false
          };
        }

        for (const [key, value] of (<any>Object).entries(defaultColumnFields)){
            if (!Object.keys(generatedColumn).includes(key)){
                generatedColumn[key] = value;
            }

        }
        return generatedColumn;

    }

    getContrastYIQ(hexcolor){
        const r = parseInt(hexcolor.substr(0, 2), 16);
        const g = parseInt(hexcolor.substr(2, 2), 16);
        const b = parseInt(hexcolor.substr(4, 2), 16);
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    getLastCommentTag(comments){
        for (let i = comments.length - 1; i >= 0; i--){
            if (comments[i].market_mapping_tag){
                return comments[i].market_mapping_tag;
            }
        }
        return null;
    }

    getDropdownResource(column){
        switch (column){
            case 'gender':
                return {
                    resource: 'gender_type',
                    byType: true,
                    async: false
                };
            case 'candidate_rating':
                return {
                    resource: 'candidate_application_rating',
                    byType: true,
                    async: false
                };
            case 'nationality':
                return {
                    resource: 'nationalities',
                    byType: true,
                    async: true
                };
            case 'functional_expertise':
                return {
                    resource: 'functionalExpertise',
                    byType: false,
                    async: false
                };
            case 'location':
                return {
                    resource: 'locations',
                    byType: false,
                    async: false
                };
            case 'country_focus':
                return {
                    resource: 'country',
                    byType: true,
                    async: true
                };
            case 'stage_focus':
                return {
                    resource: 'investmentStage',
                    byType: false,
                    async: false
                };
            case 'sector_focus' || 'other_experience_sector':
                return {
                    resource: 'investmentSector',
                    byType: false,
                    async: false
                };
            case 'comments':
                return {
                    resource: 'market_mapping_comment_tags',
                    byType: true,
                    async: false
                };
            case 'stage':
                return {
                    resource: 'applicationStages',
                    byType: false,
                    async: false
                };
            case 'substatus':
                return {
                    resource: 'subStatuses',
                    byType: false,
                    async: false
                };
            case 'sub_functional_expertise':
                return {
                    resource: 'filteredSfe',
                    byType: false,
                    async: false
                };
            case 'reject_reason':
                return {
                    resource: 'reject_reasons',
                    byType: true,
                    async: false
                };
            case 'shortlisted_by':
                return {
                    resource: 'internalUsers',
                    byType: false,
                    async: false
                };
            case 'consent':
                return [{
                    description: 'Obtained through type',
                    value: 'obtained_through_type',
                    resource: 'candidate_consent_obtained_through',
                    byType: true,
                    async: false
                },
                {
                    description: 'Type',
                    value: 'type',
                    resource: 'candidate_consent_type',
                    byType: true,
                    async: false
                }];
            default:
                return {
                    resource: column,
                    byType: false,
                    async: false
                };
        }

    }

    getLatestComment(comments){
        for (let i = comments.length - 1; i >= 0; i--){
            if (comments[i].body){
                return comments[i].body;
            }
        }
        return null;
    }
}
