import { Component, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { Globals } from 'app/globals';

@Component({
  selector: 'app-candidate-compensation-popup',
  templateUrl: './candidate-compensation-popup.component.html',
  styleUrls: ['./candidate-compensation-popup.component.scss']
})
export class CandidateCompensationPopupComponent implements ICellEditorAngularComp, AfterViewInit{
  compensation: any = [];

  constructor(public globals: Globals){

  }

  ngAfterViewInit(){
  }

  agInit(params: any): void{
    this.compensation = params.value;
  }

  focusIn(): void{
  }

  focusOut(): void{
  }

  getValue(): any{
  }

  isCancelAfterEnd(): boolean{
    return true;
  }

  isCancelBeforeStart(): boolean{
    return false;
  }

  isPopup(): boolean{
    return true;
  }
}
