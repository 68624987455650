import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class ViewJobService{

  constructor(private angulartics2: Angulartics2, private http: HttpClient){ }

  getResoursesData(type): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=' + type);
  }

  getResources(uuid): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/jobs/' + uuid);
  }

  getCompany(company): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/companies?name=' + company);
  }

  getLocation(location): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/city?name=' + location);
  }

  getContacts(company): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/contacts?company_id=' + company);
  }

  getIndustry(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/industries');
  }

  getFunctionalExpertise(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/functionalExpertise');
  }

  getSubFunctionalExpertise(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/subFunctionalExpertise');
  }

  updateJob(uuid, body): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Updated job',
      properties: { uuid, body }
    });

    return this.http.put(environment.platformBackendURL + '/jobs/' + uuid, body);
  }

  getPods(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/pods');
  }

  getInternalUsers(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/users?group=internal');
  }

  getRole(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/userRoles');
  }

  deleteUser(job_uuid, user_uuid): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Deleted job user',
      properties: { job_uuid, user_uuid }
    });

    return this.http.delete(environment.platformBackendURL + '/jobs/' + job_uuid + '/user/' + user_uuid);
  }

  getActivity(jobUUID): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/activities?job_id=' + jobUUID);
  }

  deleteRole(jobUUID, userUUID, role_uuid): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Deleted job role',
      properties: { jobUUID, userUUID, role_uuid }
    });

    return this.http.delete(environment.platformBackendURL + '/jobs/' + jobUUID + '/user/' + userUUID + '?role_uuid=' + role_uuid);
  }

  getHuntingGroundData(jobUUID): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/companies/searches/job/' + jobUUID);
  }

  deletehuntingGround(jobuuid, hguuid): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Deleted job hunting ground',
      properties: { jobuuid, hguuid }
    });

    return this.http.delete(environment.platformBackendURL + '/companies/searches/' + jobuuid + '?job_uuid=' + hguuid);
  }

  addFile(uuid, body): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Added file to job',
      properties: { uuid, body }
    });

    return this.http.post(environment.platformBackendURL + '/jobs/' + uuid + '/assets', body);
  }

  deleteFile(file_uuid, uuid): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Deleted job file',
      properties: { uuid, file_uuid }
    });

    return this.http.delete(environment.platformBackendURL + '/jobs/' + uuid + '/assets/' + file_uuid);
  }

  updateFile(uuid, body){
    this.angulartics2.eventTrack.next({
      action: 'Updated job file',
      properties: { uuid, body }
    });

    return this.http.put(environment.platformBackendURL + '/assets/' + uuid, body);
  }

  addCompany(company): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Added company',
      properties: { company }
    });

    const body = {
      "company_name": company
    };
    return this.http.post(environment.platformBackendURL + '/companies', body);
  }

  addContact(company, user, email): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Added job contact',
      properties: { company, user, email }
    });

    const body = {
      "company": {
        uuid: company
      },
      "name": user,
      "email": email
    };
    return this.http.post(environment.platformBackendURL + '/contacts', body);
  }

  editFile(jobUUID, assetUUID, body){
    this.angulartics2.eventTrack.next({
      action: 'Edited job file',
      properties: { jobUUID, assetUUID, body }
    });

    return this.http.put(environment.platformBackendURL + '/jobs/' + jobUUID + '/assets/' + assetUUID, body);
  }

  closeJob(uuid, body): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Closed job',
      properties: { uuid, body }
    });

    return this.http.put(environment.platformBackendURL + '/jobs/' + uuid + '/close', body);
  }

  reopenJob(reopenJobdata, jobUUID): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Reopened job',
      properties: { reopenJobdata, jobUUID }
    });

    return this.http.put(environment.platformBackendURL + '/jobs/' + jobUUID + '/reopen', reopenJobdata);
  }

  getAffinityOpportunities(): Observable<any>{
    return this.http.get(environment.djangoURL + '/integrations/affinity/opportunities?field_id=99672&text_values=Pitch,Negotiation,Closed Won');
  }

  getMedianSalary(uuid: string): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/jobs/' + uuid + '/medianSalary');
  }

  getJobMetrics(uuid): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/jobs/' + uuid + '/campaignMetrics');
  }

  getLocations(parameter: string): Observable<any>{
    const body = {
      "input_text": parameter
    };
    return this.http.post(environment.djangoURL + '/geocoder/autocomplete/', body);
  }
}
