<div class="col-12 p-0 bg-white" (keydown)="myOnKeyDown($event)">
  <div class="card-body" *ngIf="!params.value.comments.length">
    <div class="overflow-x-hidden" [ngClass]="innerHeight > 780 ? 'height-250' : 'height-100'">
      <div class="hcenter text-center">
        <i class="icon-ttjoboverview tt-icon-job "></i>
        <div class="card-body-content m-3">
          Click below to create a note
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="params.value.comments.length">
    <div class="overflow-x-hidden mb-2" #commentsDiv [scrollTop]="commentsDiv.scrollHeight" [ngClass]="innerHeight > 780 ? 'height-250' : 'height-100'">
      <div *ngFor="let comment of params.value.comments; let i = index">
        <div class="card-body">
          <div class="card-block">
            <div class="media">
              <img class="media-object align-self-center d-flex height-40 width-40 rounded-circle"
                [src]="comment.photo ? comment.photo : globals.avatar" (error)="comment.photo = globals.avatar" />
              <div class="media-body align-self-center pagination-centered">
                <div class="display-inline-flex">
                  <div class="media-body-title">{{comment.user}}
                    <span class="media-time-ago">{{comment.created_date | dateDiff:comment.created_date }}</span>
                    <span class="display-inline-flex" *ngIf="comment.market_mapping_tag">
                      <div class="badge-tag ml-3 white"
                        [ngStyle]="{'background-color': comment.market_mapping_tag.color_code}">
                        {{comment.market_mapping_tag.description}}
                      </div>
                    </span>
                  </div>
                </div>
                <div [innerHTML]="comment.body"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row"
      *ngIf="params.value.additionalData.resources.comments.length && globals.checkIfExists('permission', 'view_market_mapping_candidates') && 'market_mapping' == params.value.additionalData.stage">
      <div class="btn bg-btn-outline col-2 ml-2" [ngStyle]="{'background-color': button.color_code}"
        *ngFor="let button of params.value.additionalData.resources.comments;let first = first"
        (click)="applyCommentFilter(button)" [ngClass]="{'active': button.active, 'ml-4': first}"
        [class]="button.color_code">
        {{button.description}}
      </div>
    </div>
    <div ngbDropdown placement="top-left" class="full-width display-inline-flex" #tagsDropDown="ngbDropdown">
      <div class="scroll tag-height" ngbDropdownMenu aria-labelledby="dropdownBasic2"
        [ngClass]="{'border': filteredUsers.length > 0}">
        <div *ngFor="let user of filteredUsers">
          <div class="media p-2 tag-hover"  (click)="chooseUser(user);tagsDropDown.close();">
            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle align-self-center"
              [src]="user.photo ? user.photo : globals.avatar" (error)="user.photo = globals.avatar" />
            <div class="media-body align-self-center">
              <p class="media-body-title mb-0 ">
                {{user.name}}
              </p>
              <p class="mb-0">
                {{user.email ? user.email : globals.labels.placeholders.shared_page.add_job.empty_email}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <input class="form-control position-relative" id="dropdownBasic2" ngbDropdownToggle
        (keyup)="getTagName(newComment)" (keyup.enter)="disableSendBtn || onCommentSave(newComment)" type="text"
        [(ngModel)]="newComment" (click)="tagsDropDown.close();"
        placeholder="Click here to insert note. Use @ mentions to notify team members." />
      <div class="input-group-append">
        <span class="input-group-btn">
          <a (click)="disableSendBtn || onCommentSave(newComment)">
            <i class="fa p-3 icon-ttsend-button tt-icon-lg"></i>
          </a>
        </span>
      </div>
    </div>
  </div>
</div>
