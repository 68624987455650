import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class DealroomWidgetService{
    constructor(private httpClient: HttpClient){ }

    getCompanyDealroom(body): Observable<any>{
        return this.httpClient.post(environment.dealroomURL + '/companies', body);
    }
}
