import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Globals } from 'app/globals';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class ViewCandidateService{
  constructor(private angulartics2: Angulartics2, private httpClient: HttpClient, private globals: Globals){ }

  saveComment(data): Observable<any>{
    return this.httpClient.post(environment.platformBackendURL + '/candidates/comment', data);
  }

  getCandidateProfile(uuid: any): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/candidates/' + uuid);
  }

  getComments(candidate_uuid, job_uuid): Observable<any>{
    if (job_uuid){
      return this.httpClient.get(environment.platformBackendURL + '/candidates/comments/' + candidate_uuid + '?job_uuid=' + job_uuid);
    } else {
      return this.httpClient.get(environment.platformBackendURL + '/candidates/comments/' + candidate_uuid);
    }
  }

  getLocations(parameter: string): Observable<any>{
    const body = {
      "input_text": parameter
    };
    return this.httpClient.post(environment.djangoURL + '/geocoder/autocomplete/', body);
  }

  getActivity(candidate_uuid, filters, type, candidate_email): Observable<any>{
    const body = {
      "candidate_emails": [candidate_email],
      "candidate_uuid": candidate_uuid,
      "filters": filters
    };

    this.angulartics2.eventTrack.next({
      action: 'Loaded candidate activity',
      properties: { candidate_uuid, filters, type, candidate_email }
    });

    switch (type){
      case 'insights': {
        return this.httpClient.post(environment.platformBackendURL + '/activities/insights', body);
      }
      case 'email': {
        return this.httpClient.post(environment.graphAPIURL + '/mongodb/mails/', body);
      }
      case 'role_activity': {
        return this.httpClient.post(environment.platformBackendURL + '/activities/roleActivities', body);
      }
      default: {}
    }
    return null;
  }

  getFeResources(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/functionalExpertise');
  }

  getSfeResources(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/subFunctionalExpertise');
  }

  getIndustryResources(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/industries');
  }

  getResources(type): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources?type=' + type);
  }

  getLanguageResources(language): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/languages?name=' + language);
  }

  getLanguageLevelResources(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/languageLevels');
  }

  updateCandidate(uuid, body){
    return this.httpClient.put(environment.platformBackendURL + '/candidates/' + uuid, body);
  }

  updateExperience(uuid, body){
    return this.httpClient.put(environment.platformBackendURL + '/candidates/workExperience/' + uuid, body);
  }

  updateEducation(uuid, body){
    return this.httpClient.put(environment.platformBackendURL + '/candidates/education/' + uuid, body);
  }

  updateLanguage(uuid, body){
    return this.httpClient.put(environment.platformBackendURL + '/candidates/language/' + uuid, body);
  }

  updateFile(candidate_uuid, asset_uuid, body){
    return this.httpClient.put(environment.platformBackendURL + '/candidates/' + candidate_uuid + '/assets/' + asset_uuid, body);
  }

  updateFileName(uuid, body){
    return this.httpClient.put(environment.platformBackendURL + '/assets/' + uuid, body);
  }

  updateSubstatus(body){
    return this.httpClient.put(environment.platformBackendURL + '/candidates/applications/substatus', body);
  }

  addToJob(body): Observable<any>{
    body.job_uuid = body.job.uuid;
    return this.httpClient.post(environment.platformBackendURL + '/candidates/applications', body);
  }

  getJobs(type: any): Observable<any>{
    const url: string = environment.platformBackendURL + '/jobs?type=' + type;
    return this.httpClient.get(url);
  }

  getInitialSubStatuses(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/subStatuses?value=' + this.globals.substatuses);
  }

  getSubStatuses(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/subStatuses');
  }

  addExperience(body): Observable<any>{
    return this.httpClient.post(environment.platformBackendURL + '/candidates/workExperience', body);
  }

  addEducation(body): Observable<any>{
    return this.httpClient.post(environment.platformBackendURL + '/candidates/education', body);
  }

  addFile(candidate_uuid, body): Observable<any>{
    return this.httpClient.post(environment.platformBackendURL + '/candidates/' + candidate_uuid + '/assets', body);
  }

  addLanguage(body): Observable<any>{
    return this.httpClient.post(environment.platformBackendURL + '/candidates/language', body);
  }

  deleteExperience(uuid): Observable<any>{
    return this.httpClient.delete(environment.platformBackendURL + '/candidates/workExperience/' + uuid);
  }

  deleteEducation(uuid): Observable<any>{
    return this.httpClient.delete(environment.platformBackendURL + '/candidates/education/' + uuid);
  }

  deleteLanguage(uuid): Observable<any>{
    return this.httpClient.delete(environment.platformBackendURL + '/candidates/language/' + uuid);
  }

  deleteFile(candidate_uuid, candidate_asset_uuid): Observable<any>{
    return this.httpClient.delete(environment.platformBackendURL + '/candidates/' + candidate_uuid + '/assets/' + candidate_asset_uuid);
  }

  getNationalityResources(data): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/nationalities?name=' + data);
  }

  getStageFocusResources(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/investmentStage');
  }

  getSectorFocusResources(sectorFocus): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/investmentSector?name=' + sectorFocus);
  }

  getCountryFocusResources(country): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/country?name=' + country);
  }

  getActivityFilterResources(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources?type=candidate_activity');
  }

  getCampaignsResources(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/campaigns');
  }

  getInternalUsers(params?): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/users' + params);
  }

  getUsers(params): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/users' + params);
  }

  getCandidateJobsResources(type, candidate_uuid): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/jobs' + (candidate_uuid ? '?candidate_uuid=' + candidate_uuid : '') + (type ? '?type=' + type : ''));
  }

  getStageStatus(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/applicationStages?current_stage=null');
  }

  getActivityTags(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/activityTags?filter=candidates');
  }

  addActivityToDB(body){
    const url: string = environment.platformBackendURL + '/activities';
    return this.httpClient.post(url, body);
  }

  getCompensation(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources?type=currencies,bonus_type,fee_type');
  }

  getConsentType(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources?type=candidate_consent_type');
  }

  getObtainedType(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources?type=candidate_consent_obtained_through');
  }

  getNavisJobs(linkedin_url):Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/navis/api/candidates/jobHistory?linkedin_url=' + linkedin_url);
  }

}
