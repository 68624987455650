import { Component, OnInit } from '@angular/core';
import { Globals } from './globals';
import { VersionCheckService } from './version-check.service';
import { Router, NavigationEnd } from '@angular/router';

import { environment } from 'environments/environment';
import { Angulartics2GoogleAnalytics, Angulartics2Mixpanel } from 'angulartics2';
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics, angulartics2Mixpanel: Angulartics2Mixpanel, public globals: Globals, private versionCheckService: VersionCheckService, public router: Router){
    this.router.events.subscribe(event => {
      // We will replace GA soon, so this will serve as temp fix
      if (event instanceof NavigationEnd && this.globals.data){
        if (typeof ga == 'function'){
          ga('create', {
            trackingId: 'UA-129359474-1',
            cookieDomain: 'auto',
            userId: this.globals.data.uuid
          });
          ga('set', 'transport', 'beacon', {
            userId: this.globals.data.uuid
          });
          ga('send', {
            hitType: 'pageview',

            userId: this.globals.data.uuid
          });
        }
      }
    });
  }

  ngOnInit(){
    if (environment.production){
      this.versionCheckService.initVersionCheck('./version.json');
    }
  }
}
