<aside id="filter-drawer" class="filter-drawer d-sm-block m-0 overflow-hidden"
[ngStyle]="{'width': width >= 1200 ? '80vh' : '100%'}">
  <a class="filter-drawer-close" (click)="dismiss()">
    <i class="ft-x font-medium-3"></i>
  </a>
  <div class="side-nav filter-drawer-content" [ngSwitch]="data.type">
    <app-candidates-filter *ngSwitchCase="'filter-candidates'" [data]="data.filters" (dismissDrawer)="dismiss()"></app-candidates-filter>
    <app-companies-filter *ngSwitchCase="'filter-companies'" [data]="data" (dismissDrawer)="dismiss()"></app-companies-filter>
    <app-jobs-filter *ngSwitchCase="'filter-jobs'" [data]="data" (dismissDrawer)="dismiss()"></app-jobs-filter>
  </div>
</aside>