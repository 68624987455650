import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
//ReactiveFormsModule for formgroup guill, no need
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
//component: sidebar -> dropdown jobs
//component: navbar -> create new template
import { QuillModule } from 'ngx-quill';

import { DataService } from "./sidebar/sidebar.service";
import { NavbarService } from "./navbar/navbar.service";
import { OnFocusOutDirective } from './directives/on-focus-out.directive';
import { RemoveUnderscorePipe } from './pipes/remove-underscore.pipe';
import { CapitalizeFirstPipe } from './pipes/capitalize-fist.pipe';
import { FocusDirective } from './directives/focus.directive';
import { DatediffPipeNav } from './pipes/date-diff-nav.pipe';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TokenInterceptor } from "./auth/token.interceptor";
import { NgSelectModule } from '@ng-select/ng-select';

import { JobDrawerComponent } from './platform-drawers/job-drawer/job-drawer.component';
import { CloseJobComponent } from './platform-drawers/job-drawer/close-job/close-job.component';
import { CloseJobService } from './platform-drawers/job-drawer/close-job/close-job.service';
import { JobActivityComponent } from './platform-drawers/job-drawer/job-activity/job-activity.component';
import { JobActvityService } from './platform-drawers/job-drawer/job-activity/job-activity.service';
import { ViewJobService } from './platform-drawers/job-drawer/view-job/view-job.service';
import { AddDataDrawerComponent } from './platform-drawers/add-data-drawer/add-data-drawer.component';
import { AddRevenueComponent } from './platform-drawers/add-data-drawer/add-revenue/add-revenue.component';
import { AddRevenueService } from "./platform-drawers/add-data-drawer/add-revenue/add-revenue.service";
import { AddJobComponent } from './platform-drawers/add-data-drawer/add-job/add-job.component';
import { AddJobService } from './platform-drawers/add-data-drawer/add-job/add-job.service';
import { AddActivityComponent } from "./platform-drawers/add-data-drawer/add-activity/add-activity.component";
import { AddActivityService } from "./platform-drawers/add-data-drawer/add-activity/add-activity.service";
import { AddCandidateComponent } from './platform-drawers/add-data-drawer/add-candidate/add-candidate.component';
import { AddContactComponent } from './platform-drawers/add-data-drawer/add-contact/add-contact.component';

import { CandidateDrawerComponent } from './platform-drawers/candidate-drawer/candidate-drawer.component';
import { ViewCandidateComponent } from './platform-drawers/candidate-drawer/view-candidate/view-candidate.component';
import { ViewCandidateService } from './platform-drawers/candidate-drawer/view-candidate/view-candidate.service';

import { CompanyDrawerComponent } from './platform-drawers/company-drawer/company-drawer.component';
import { CompanyActivityComponent } from './platform-drawers/company-drawer/company-activity/company-activity.component';
import { CompanyActivityService } from './platform-drawers/company-drawer/company-activity/company-activity.service';

import { ContactDrawerComponent } from './platform-drawers/contact-drawer/contact-drawer.component';
import { ViewContactComponent } from './platform-drawers/contact-drawer/view-contact/view-contact.component';
import { ViewContactService } from './platform-drawers/contact-drawer/view-contact/view-contact.service';

import { FilterDrawerComponent } from './platform-drawers/filter-drawer/filter-drawer.component';
import { CandidatesFilterComponent } from './platform-drawers/filter-drawer/candidates-filter/candidates-filter.component';
import { CandidatesFilterService } from './platform-drawers/filter-drawer/candidates-filter/candidates-filter.service';
import { RemoveUnderscoreCapitalizeFirstPipe } from './pipes/remove-underscore-capitalize-frst.pipe';
import { TagInputModule } from 'ngx-chips';
import { ColorPickerModule } from 'ngx-color-picker';
import { DateDiffTimestampNavPipe } from './pipes/date-diff-timestamp-nav.pipe';
import { DateDiffTimestampFullNavPipe } from './pipes/date-diff-timestamp-full-nav.pipe';
import { CompaniesFilterComponent } from './platform-drawers/filter-drawer/companies-filter/companies-filter.component';

import { CompaniesFilterService } from './platform-drawers/filter-drawer/companies-filter/companies-filter.service';

import { GenerateFilterService } from './platform-drawers/filter-drawer/jobs-filter/jobs-filter.service';
import { ViewJobComponent } from './platform-drawers/job-drawer/view-job/view-job.component';
import { TimestampToDatepickerPipe } from './pipes/timestamp-to-datepicker.pipe';
import { RenderedDirective } from './platform-drawers/candidate-drawer/view-candidate/view-candidate.component';
import { NumberOnlyPipe } from './pipes/number-only.pipe';
import { JobsFilterDrawerComponent } from './platform-drawers/filter-drawer/jobs-filter/jobs-filter.component';
import { FileUploadModule } from 'ng2-file-upload';
import { MergeCandidatesModalComponent } from './platform-drawers/candidate-drawer/view-candidate/merge-candidates-modal/merge-candidates-modal.component';
import { ChooseMergeCandidateModalComponent } from './platform-drawers/candidate-drawer/view-candidate/choose-merge-candidate-modal/choose-merge-candidate-modal.component';
import { ChooseMergeCandidateModalService } from './platform-drawers/candidate-drawer/view-candidate/choose-merge-candidate-modal/choose-merge-candidate-modal.service';
import { MergeCandidatesModalService } from './platform-drawers/candidate-drawer/view-candidate/merge-candidates-modal/merge-candidates-modal.service';
import { LonglistCandidateModalComponent } from './platform-modals/longlist-candidate-modal/longlist-candidate-modal.component';
import { LonglistCandidateModalService } from './platform-modals/longlist-candidate-modal/longlist-candidate-modal.service';
import { CampaignDrawerComponent } from './platform-drawers/campaign-drawer/campaign-drawer.component';
import { ViewCampaignComponent } from './platform-drawers/campaign-drawer/view-campaign/view-campaign.component';
import { ViewCampaignService } from './platform-drawers/campaign-drawer/view-campaign/view-campaign.service';
import { ChartistModule } from 'ng-chartist';
import { ArchwizardModule } from 'angular-archwizard';
import { Angulartics2Module } from 'angulartics2';
import { CandidateEmailModalComponent } from './platform-drawers/campaign-drawer/view-campaign/modals/candidate-email-modal/candidate-email-modal.component';
import { CandidateTriggerTypeModalComponent } from './platform-drawers/campaign-drawer/view-campaign/modals/candidate-trigger-type-modal/candidate-trigger-type-modal.component';
import { CandidateTriggerTypeModalService } from './platform-drawers/campaign-drawer/view-campaign/modals/candidate-trigger-type-modal/candidate-trigger-type-modal.service';
import { CandidateEmailModalService } from './platform-drawers/campaign-drawer/view-campaign/modals/candidate-email-modal/candidate-email-modal.service';
import { DeleteCandidateModalComponent } from './platform-drawers/candidate-drawer/view-candidate/delete-candidate-modal/delete-candidate-modal.component';
import { DeleteCandidateModalService } from './platform-drawers/candidate-drawer/view-candidate/delete-candidate-modal/delete-candidate-modal.service';
import { CandidateNextStepEmailModalComponent } from './platform-drawers/campaign-drawer/view-campaign/modals/candidate-next-step-email-modal/candidate-next-step-email-modal.component';
import { CandidateNextStepEmailModalService } from './platform-drawers/campaign-drawer/view-campaign/modals/candidate-next-step-email-modal/candidate-next-step-email-modal.service';
import { CalibrationListDataService } from 'app/pages/full-layout-page/job/calibration-list/calibraiton-list.service';

import { UpdateApplicationModalComponent } from './platform-modals/update-application-modal/update-application-modal.component';
import { UpdateApplicationModalService } from './platform-modals/update-application-modal/update-application-modal.service';
import { CampaignsMetricsModalComponent } from './platform-modals/campaigns-metrics-modal/campaigns-metrics-modal.component';
import { CampaignsMetricsModalService } from './platform-modals/campaigns-metrics-modal/campaigns-metrics-modal.service';
import { AddCandidateService } from './platform-drawers/add-data-drawer/add-candidate/add-candidate.service';
import { CurrentlyUnemployedModalComponent } from './platform-drawers/add-data-drawer/add-candidate/currently-unemployed-modal/currently-unemployed-modal.component';
import { AddContactService } from './platform-drawers/add-data-drawer/add-contact/add-contact.service';
import { DeleteContactModalComponent } from './platform-drawers/contact-drawer/view-contact/delete-contact-modal/delete-contact-modal.component';
import { AddToJobModalComponent } from './platform-drawers/candidate-drawer/view-candidate/add-to-job-modal/add-to-job-modal.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { GeneralConfirmationModalComponent } from './platform-modals/general-confirmation-modal/general-confirmation-modal.component';
import { DealroomWidgetComponent } from './selectors/dealroom-widget/dealroom-widget.component';
import { DealroomWidgetService } from './selectors/dealroom-widget/dealroom-widget.service';
import { AgGridDealroomColumnComponent } from './selectors/aggrid-dealroom-column/aggrid-dealroom-column.component';
import { BlacklistCandidateModalComponent } from './platform-drawers/candidate-drawer/view-candidate/blacklist-candidate-modal/blacklist-candidate-modal.component';
import { BlacklistCandidateModalService } from './platform-drawers/candidate-drawer/view-candidate/blacklist-candidate-modal/blacklist-candidate-modal.service';
import { AgGridNameColumnComponent } from './selectors/aggrid-name-column/aggrid-name-column.component';
import { StickyPopoverDirective } from './directives/sticky-popover.directive';
import { InfiniteScrollModule } from "ngx-infinite-scroll";

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        NgbModule,
        FocusDirective,
        AddJobComponent,
        FileUploadModule,
        AddActivityComponent,
        CloseJobComponent,
        JobActivityComponent,
        AddRevenueComponent,
        AddDataDrawerComponent,
        CandidateDrawerComponent,
        CompanyDrawerComponent,
        ContactDrawerComponent,
        FilterDrawerComponent,
        JobDrawerComponent,
        AddCandidateComponent,
        AddContactComponent,
        ViewCandidateComponent,
        ViewContactComponent,
        CompanyActivityComponent,
        RemoveUnderscoreCapitalizeFirstPipe,
        DateDiffTimestampNavPipe,
        DateDiffTimestampFullNavPipe,
        TimestampToDatepickerPipe,
        RenderedDirective,
        ViewJobComponent,
        CampaignDrawerComponent,
        ViewCampaignComponent,
        StickyPopoverDirective
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        AngularMultiSelectModule,
        FormsModule,
        QuillModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgSelectModule,
        FileUploadModule,
        TagInputModule,
        ChartistModule,
        ArchwizardModule,
        ColorPickerModule,
        Angulartics2Module,
        InfiniteScrollModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        RemoveUnderscorePipe,
        CapitalizeFirstPipe,
        OnFocusOutDirective,
        FocusDirective,
        DatediffPipeNav,
        AddJobComponent,
        AddActivityComponent,
        CloseJobComponent,
        JobActivityComponent,
        AddRevenueComponent,
        AddDataDrawerComponent,
        CandidateDrawerComponent,
        CompanyDrawerComponent,
        ContactDrawerComponent,
        FilterDrawerComponent,
        JobDrawerComponent,
        AddCandidateComponent,
        AddContactComponent,
        ViewCandidateComponent,
        ViewContactComponent,
        CompanyActivityComponent,
        CandidatesFilterComponent,
        RemoveUnderscoreCapitalizeFirstPipe,
        DateDiffTimestampNavPipe,
        DateDiffTimestampFullNavPipe,
        CompaniesFilterComponent,
        ViewJobComponent,
        JobsFilterDrawerComponent,
        ViewJobComponent,
        TimestampToDatepickerPipe,
        NumberOnlyPipe,
        RenderedDirective,
        MergeCandidatesModalComponent,
        ChooseMergeCandidateModalComponent,
        LonglistCandidateModalComponent,
        UpdateApplicationModalComponent,
        CandidateEmailModalComponent,
        CandidateTriggerTypeModalComponent,
        CandidateNextStepEmailModalComponent,
        ViewCampaignComponent,
        CampaignDrawerComponent,
        DeleteCandidateModalComponent,
        CandidateNextStepEmailModalComponent,
        CampaignsMetricsModalComponent,
        GeneralConfirmationModalComponent,
        CurrentlyUnemployedModalComponent,
        DeleteContactModalComponent,
        AddToJobModalComponent,
        DealroomWidgetComponent,
        AgGridDealroomColumnComponent,
        AgGridNameColumnComponent,
        BlacklistCandidateModalComponent,
        StickyPopoverDirective

    ],
    entryComponents: [
        ChooseMergeCandidateModalComponent,
        MergeCandidatesModalComponent,
        LonglistCandidateModalComponent,
        UpdateApplicationModalComponent,
        CandidateEmailModalComponent,
        CandidateTriggerTypeModalComponent,
        CandidateNextStepEmailModalComponent,
        DeleteCandidateModalComponent,
        CandidateNextStepEmailModalComponent,
        CampaignsMetricsModalComponent,
        GeneralConfirmationModalComponent,
        CurrentlyUnemployedModalComponent,
        DeleteContactModalComponent,
        AddToJobModalComponent,
        AgGridDealroomColumnComponent,
        AgGridNameColumnComponent,
        BlacklistCandidateModalComponent
    ],
    providers: [
        DataService,
        CalibrationListDataService,
        NavbarService,
        AddActivityService,
        AddJobService,
        CloseJobService,
        JobActvityService,
        AddRevenueService,
        ViewCandidateService,
        ViewContactService,
        CompanyActivityService,
        CandidatesFilterService,
        CompaniesFilterService,
        BlacklistCandidateModalService,
        ViewCampaignService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        GenerateFilterService,
        ViewJobService,
        ChooseMergeCandidateModalService,
        MergeCandidatesModalService,
        LonglistCandidateModalService,
        UpdateApplicationModalService,
        CandidateTriggerTypeModalService,
        CandidateEmailModalService,
        CandidateNextStepEmailModalService,
        DeleteCandidateModalService,
        CandidateNextStepEmailModalService,
        CampaignsMetricsModalService,
        AddCandidateService,
        AddContactService,
        DealroomWidgetService
    ]
})
export class SharedModule{ }
