<aside id="job-drawer" class="job-drawer d-sm-block m-0 overflow-hidden"
[ngStyle]="{'width': width >= 1200 ? '80vh' : '100%'}">
  <a class="job-drawer-close" (click)="dismiss()">
    <i class="ft-x font-medium-3"></i>
  </a>
    <div class="side-nav job-drawer-content" [(ngSwitch)]="data.type">
      <app-close-job *ngSwitchCase="'close-job'" [uuid]="data.job_uuid" (dismissDrawer)="dismiss()"></app-close-job>
      <app-job-activity *ngSwitchCase="'job-activity'" [uuid]="data.job_uuid"></app-job-activity>
      <app-view-job *ngSwitchCase="'view-job'" [uuid]="data.job_uuid" (dismissDrawer)="dismiss()"></app-view-job>
    </div>
</aside>