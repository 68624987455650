import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'; // add this 1 of 4

@Pipe({
    name: 'dateDiff'
})
export class DatediffPipe implements PipeTransform{

    transform(date: any): any{
      const d = new Date(0);
      d.setUTCSeconds(date);
      return moment(d).fromNow();
    }
}
