<div class="card m-0 scroll height-250">
    <div class="card-block">
        <div class="card-body">
            <div *ngIf="compensation">
                <div class="card-body-title-basic">
                    <div class="form-group">
                        Compensation
                    </div>
                </div>
                <div class="card-body-content">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Base
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    {{compensation.base_salary ? compensation.base_salary: 'No information available' }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Currency
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    {{compensation.currency ? compensation.currency.description : 'No information available'}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Bonus
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    {{compensation.bonus_salary ? compensation.bonus_salary: 'No information available' }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Type
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    {{compensation.bonus_type ? compensation.bonus_type.description : 'No information available'}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-12">
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Equity
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    {{compensation.equity ? compensation.equity: 'No information available' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>