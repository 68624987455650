<div class="card m-0 scroll height-300">
  <div class="card-block">
    <div class="card-body">
      <div *ngIf="jobs.length">
        <div class="form">
          <div class="form-section">
            Active in another process
          </div>
        </div>
        <div *ngFor="let job of jobs">
          <div class="card-body-title-basic">
            <div class="form-group">
              {{job.job}}
            </div>
          </div>
          <div class="card-body-content">
            <div class="row" *ngIf="(job.stage && job.stage.value) || (job.substatus && job.substatus.description)">
              <div class="col-6" *ngIf="job.stage && job.stage.value">
                <div class="form-group">
                  Stage
                  <ng-template #tooltipOpen>
                    <div class="card card-preview">
                      <div class="card-body">
                        <div class="card-body-title-basic">
                          Stage: {{job.stage.description}}
                        </div>
                        <div class="card-body-content">
                          <div *ngIf="job.shortlisted_date">
                            Shortlisted date:
                            {{job.shortlisted_date | dateDiffTimestampFull:job.shortlisted_date}}
                          </div>
                          <div *ngIf="job.sent_date">
                            Sent date: {{job.sent_date | dateDiffTimestampFull:job.sent_date}}
                          </div>
                          <div *ngIf="job.first_interview_date">
                            First interview date:
                            {{job.first_interview_date | dateDiffTimestampFull:job.first_interview_date}}
                          </div>
                          <div *ngIf="job.second_interview_date">
                            Second interview date:
                            {{job.second_interview_date | dateDiffTimestampFull:job.second_interview_date}}
                          </div>
                          <div *ngIf="job.offer_date">
                            Offer date: {{job.offer_date | dateDiffTimestampFull:job.offer_date}}
                          </div>
                          <div *ngIf="job.placed_date">
                            Placed date: {{job.placed_date | dateDiffTimestampFull:job.placed_date}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #t let-fill="fill" let-last="last" let-index="index">
                    <span class="bullet" [class.full]="fill === 100">
                      <span class="bullet"
                        [ngClass]="fill === 100 && (job.substatus && job.substatus.success) ? 'success' : index == job.stage.current_stage-1 ? 'error' : 'success'"
                        [style.width.%]="fill" [ngbTooltip]="tooltipOpen" placement="bottom"
                        [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                      <span
                        [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                    </span>
                  </ng-template>
                  <ngb-rating [(rate)]="job.stage.current_stage" [starTemplate]="t" [readonly]="true"
                    [max]="job.stage.max_stage">
                  </ngb-rating>
                </div>
              </div>
              <div class="col-6" *ngIf="job.substatus && job.substatus.description">
                <div class="form-group">
                  <span class="badge m-0" [ngStyle]="{'color': job.substatus.color}">
                    <i class="fa fa-circle"></i>
                  </span>
                  <label>{{job.substatus.description}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>