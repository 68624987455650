<div *ngIf="jobDataIsDownloaded">
  <div class="media m-4">
    <div class="media-body">
      <div class="media-body-title form-group display-inline-flex">
        <span class="align-self-center">{{job.job_title}}</span>
        <span class="badge-tags-inverse ml-3 align-self-center white"
          [ngClass]="job.state == 'Open' ? 'bg-success' : 'bg-error-color'">
          {{job.state}}
        </span>
        <li class="nav-item" ngbDropdown placement="bottom-right" id="candidate_actions">
          <a class="nav-link position-relative dropdown-color" id="dropdownBasic3" ngbDropdownToggle>
            Actions
          </a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="card-shadow">
            <a class="dropdown-item py-1 dropdown-color" (click)="showCloseJobView()" *ngIf="job.state == 'Open'">
              <i class="tt-icon-sm fa fa-ban mr-2"></i>
              <span>Close job</span>
            </a>
            <a class="dropdown-item py-1 dropdown-color"
              (click)="reopenJobFlag = !reopenJobFlag; editJobFlag = !editJobFlag;" *ngIf="job.state != 'Open'">
              <i class="tt-icon-sm fa fa-repeat mr-2"></i>
              <span>Reopen job</span>
            </a>
          </div>
        </li>
      </div>
      <div class="media-body-content">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <div *ngIf="job.location.length">
                <span *ngFor="let location of job.location;let last = last;">
                  <div>
                    <i class="fa fa-map-marker pr-1 pl-1"></i>
                    {{location.description}}
                  </div>
                </span>
              </div>
              <div *ngIf="!job.location.length">
                <i class="fa fa-map-marker pr-1 pl-1"></i>No location available
              </div>
            </div>
            <div class="form-group">
              <i class="fa fa-envelope mr-1">
              </i>{{job.contact && job.contact.email ? job.contact.email : 'No contact information'}}
            </div>
            <div class="form-group">
              <strong>Company:
              </strong>{{job.company && job.company.name ? job.company.name : 'No company information'}}
            </div>
            <div class="form-group">
              <div
                *ngIf="job.jobCompensation && (job.jobCompensation.annual_salary || job.jobCompensation.salary_from);then showSalary else showMsgSalary">
              </div>
              <ng-template #showSalary>
                <div
                  *ngIf="job.jobCompensation.annual_salary && job.jobCompensation.currency &&  job.jobCompensation.currency.value == 'EUR'">
                  <strong>Salary: </strong> {{job.jobCompensation.annual_salary}} {{job.jobCompensation.currency.value}}
                  &euro;
                </div>
                <div
                  *ngIf=" job.jobCompensation.annual_salary && job.jobCompensation.currency &&  job.jobCompensation.currency.value == 'GBP'">
                  <strong>Salary: </strong>{{job.jobCompensation.annual_salary}} {{job.jobCompensation.currency.value}}
                  &#163;
                </div>
                <div
                  *ngIf="job.jobCompensation.annual_salary && job.jobCompensation.currency &&  job.jobCompensation.currency.value == 'USD'">
                  <strong>Salary: </strong>{{job.jobCompensation.annual_salary}} {{job.jobCompensation.currency.value}}
                  &#36;
                </div>
                <div
                  *ngIf="!job.jobCompensation.annual_salary && job.jobCompensation.salary_from && job.jobCompensation.salary_to && job.jobCompensation.currency && job.jobCompensation.currency.value == 'EUR'">
                  <strong>Salary: </strong> {{job.jobCompensation.salary_from}}-{{job.jobCompensation.salary_to}}
                  {{job.jobCompensation.currency.value}}
                  &euro;
                </div>
                <div
                  *ngIf="!job.jobCompensation.annual_salary && job.jobCompensation.salary_from && job.jobCompensation.salary_to && job.jobCompensation.currency && job.jobCompensation.currency.value == 'GBP'">
                  <strong>Salary: </strong>{{job.jobCompensation.salary_from}}-{{job.jobCompensation.salary_to}}
                  {{job.jobCompensation.currency.value}}
                  &#163;
                </div>
                <div
                  *ngIf="!job.jobCompensation.annual_salary && job.jobCompensation.salary_from && job.jobCompensation.salary_to && job.jobCompensation.currency && job.jobCompensation.currency.value == 'USD'">
                  <strong>Salary: </strong>{{job.jobCompensation.salary_from}}-{{job.jobCompensation.salary_to}}
                  {{job.jobCompensation.currency.value}}
                  &#36;
                </div>
              </ng-template>
              <ng-template #showMsgSalary>
                <strong>Salary: </strong>No salary information
              </ng-template>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <strong>Stage: </strong>
              <ng-template #t let-fill="fill" let-last="last" let-index="index">
                <span class="bullet cursor-pointer" [class.full]="fill === 100">
                  <span
                    [ngClass]="job.current_stage[index].status ? 'success' : job.current_stage[index].status == false ? 'error-color' : 'skip-color'"
                    [style.width.%]="fill" [ngbTooltip]="tooltipClose" placement="bottom"
                    [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"
                    [title]="jobTitles[index]">
                  </span>
                </span>
              </ng-template>
              <ngb-rating [(rate)]="stageRate" [starTemplate]="t" [readonly]="true" [max]="job.current_stage.length">
              </ngb-rating>
            </div>
            <div class="form-group">
              <strong>Status:
                <span [ngClass]="job.status && job.status.description == 'Active' ? 'success' : 'error-color'">
                  {{job.status ? job.status.description : 'Status is missing'}} </span></strong>
            </div>
            <div class="form-group">
              <strong>Type:</strong> {{job.type ? job.type.description : 'Job type is missing'}}
            </div>
            <div class="form-group">
              <strong>Priority:</strong> {{job.priority ? job.priority.description : 'No information'}}
            </div>
          </div>
          <div class="col-5">
            <div class="form-group">
              <strong> Campaigns metrics:</strong>
              <div
                *ngIf="job.metrics && job.metrics.response_rate && job.metrics.response_rate != '0.00';then showMetrics else showMetricsMsg">
              </div>
              <ng-template #showMetrics>
                <div class="card m-0 cursor-pointer" (click)="openMetricsModal()">
                  <div class="card-content">
                    <div class="px-3 py-3">
                      <div class="media">
                        <div class="media-body text-left">
                          <div class="card-metrics-title"> {{job.metrics.response_rate}}% Response rate
                            (View more details)
                          </div>
                        </div>
                        <div class="media-right align-self-center">
                          <i class="font-large-1 float-right success icon-pie-chart"></i>
                        </div>
                      </div>
                      <div class="mt-2 mb-0">
                        <ngb-progressbar type="success" [value]="job.metrics.response_rate" class="progress-bar-sm">
                        </ngb-progressbar>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template #showMetricsMsg>
                No information available
              </ng-template>
            </div>
            <div class="form-group">
              <strong>Created </strong> <span *ngIf="job.created_by.owner && job.created_by.owner.name"><strong> by
                </strong>{{job.created_by.owner.name}}</span><span *ngIf="job.created_by.created_at"><strong> @
                </strong>{{job.created_by.created_at | dateDiffTimestampNav:job.created_by.created_at}}</span>
            </div>
            <div class="form-group" *ngIf="job.state != 'Open' && job.closed_by">
              <strong>Closed </strong><span *ngIf="job.closed_by.owner && job.closed_by.owner.name"><strong> by
                </strong>{{job.closed_by.owner.name}}</span><span *ngIf="job.closed_by.closed_at"><strong> @
                </strong>{{job.closed_by.closed_at | dateDiffTimestampNav:job.closed_by.closed_at}}</span>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-2">
                  <strong>Team: </strong>
                </div>
                <div *ngIf="job.users.length;then showTeam else showTeamMsg"></div>
                <ng-template #showTeam>
                  <div class="col-6 position-left">
                    <div *ngFor="let user of job.users; let i = index" class="position-absolute podPicture">
                      <img class="d-flex height-30 width-30 rounded-circle cursor-pointer"
                        [src]="user.photo ? user.photo : globals.avatar" (error)="user.photo = globals.avatar"
                        data-toggle="tooltip" data-placement="top"
                        [title]="user.roles.length ? (takeUserRoles(user.roles) +': '+ user.name) : user.name" />
                    </div>
                  </div>

                </ng-template>
                <ng-template #showTeamMsg>
                  No team information
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="closeJobFlag" class="bg-white p-4">
    <i class="ft ft-arrow-left"></i> <strong (click)="editJobFlag = true; closeJobFlag = false"
      class="cursor-pointer">Back to the job</strong>
    <div class="form">
      <div class="form-section">
        {{globals.labels.placeholders.shared_page.close_job.close_job_title}}
      </div>
    </div>
    <div class="scroll scroll-closeJob pt-2 pl-3 pr-3">
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <div class="card-body-title-basic">
              <strong>
                <label> {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                  {{globals.labels.placeholders.shared_page.close_job.status}}
                  <span *ngIf="!validForm && !closingData.status" [ngClass]="{'primary-color': !closingData.status}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
              </strong>
            </div>
            <div class="card-body-content">
              <ng-select bindLabel="description" dropdownPosition="bottom" [items]="status"
                [(ngModel)]="closingData.status" [ngModelOptions]="{standalone: true}"
                (change)="this.closingData.reason = null;this.reason = [];"
                [placeholder]="globals.labels.placeholders.inputs.shared_page.close_job.select_status"
                (open)="getResources()">
              </ng-select>
            </div>
          </div>
          <div class="col-6">
            <div class="card-body-title-basic">
              <strong>
                <label> {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                  {{globals.labels.placeholders.shared_page.close_job.reason}}
                  <span *ngIf="!validForm && !closingData.reason" [ngClass]="{'primary-color': !closingData.reason}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
              </strong>
            </div>
            <ng-select bindLabel="description" dropdownPosition="bottom" [items]="reason" (open)="getReason()"
              [disabled]="!closingData.status" [(ngModel)]="closingData.reason" [ngModelOptions]="{standalone: true}"
              [placeholder]="globals.labels.placeholders.inputs.shared_page.close_job.select_reason">
            </ng-select>
          </div>
        </div>
      </div>
      <div *ngIf="closingData.status && 'lose' == closingData.status.value" class="form-group">
        <div class="card-body-title-basic">
          <strong>{{closeJobTitles.unsuccessful_reasons}}</strong>
        </div>
        <div class="card-body-content">
          <textarea rows="3" class="form-control" type="text" [(ngModel)]="closingData.unsuccessful_reasons"
            [placeholder]="closeJobPlaceholders.unsuccessful_reasons">
        </textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="card-body-title-basic">
          <strong>{{closeJobTitles.median_salary}}</strong>
        </div>
        <div class="card-body-content">
          <input [(ngModel)]="closingData.median_salary" [ngModelOptions]="{standalone: true}" class="form-control"
            type="text" onkeypress="return /^-?[0-9]*$/.test(this.value+event.key)">
        </div>
      </div>
      <div class="form-group">
        <div class="card-body-title-basic">
          <strong>{{closeJobTitles.hunting_grounds}}</strong>
        </div>
        <div class="card-body-content">
          <textarea rows="3" class="form-control" type="text" [(ngModel)]="closingData.hunting_grounds"
            [placeholder]="closeJobPlaceholders.hunting_grounds">
        </textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="card-body-title-basic">
          <strong>{{closeJobTitles.talent_pool}}</strong>
        </div>
        <div class="card-body-content">
          <textarea rows="3" class="form-control" type="text" [(ngModel)]="closingData.talent_pool"
            [placeholder]="closeJobPlaceholders.talent_pool">
        </textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="card-body-title-basic">
          <strong>{{closeJobTitles.top_candidates}}</strong>
        </div>
        <div class="card-body-content">
          <textarea rows="3" class="form-control" type="text" [(ngModel)]="closingData.top_candidates"
            [placeholder]="closeJobPlaceholders.top_candidates">
        </textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="card-body-title-basic">
          <strong>{{closeJobTitles.candidate_management}}</strong>
        </div>
        <div class="card-body-content">
          <textarea rows="3" class="form-control" type="text" [(ngModel)]="closingData.candidate_management"
            [placeholder]="closeJobPlaceholders.candidate_management">
        </textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="card-body-title-basic">
          <strong>{{closeJobTitles.client_management}}</strong>
        </div>
        <div class="card-body-content">
          <textarea rows="3" class="form-control" type="text" [(ngModel)]="closingData.client_management"
            [placeholder]="closeJobPlaceholders.client_management">
        </textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="card-body-title-basic">
          <strong>{{closeJobTitles.avoid_client}}</strong>
        </div>
        <div class="card-body-content display-inline-flex">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" [(ngModel)]="closingData.avoid_client" name="avoid_client" id="avoid_client_true"
              [value]=true class="custom-control-input">
            <label class="custom-control-label" for="avoid_client_true">Yes</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" [(ngModel)]="closingData.avoid_client" name="avoid_client" id="avoid_client_false"
              [value]=false class="custom-control-input">
            <label class="custom-control-label" for="avoid_client_false">No</label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="card-body-title-basic">
          <strong>{{closeJobTitles.bd_opportunities}}</strong>
        </div>
        <div class="card-body-content display-inline-flex">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" [(ngModel)]="closingData.bd_opportunities" name="bd_opportunities"
              id="bd_opportunities_true" [value]=true class="custom-control-input">
            <label class="custom-control-label" for="bd_opportunities_true">Yes</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" [(ngModel)]="closingData.bd_opportunities" name="bd_opportunities"
              id="bd_opportunities_false" [value]=false class="custom-control-input">
            <label class="custom-control-label" for="bd_opportunities_false">No</label>
          </div>
        </div>
      </div>
      <div *ngIf="closingData.bd_opportunities" class="form-group">
        <div class="card-body-title-basic">
          <strong>{{closeJobTitles.bd_opportunities_reason}}</strong>
        </div>
        <div class="card-body-content">
          <textarea rows="3" class="form-control" type="text" [(ngModel)]="closingData.bd_opportunities_reason"
            [placeholder]="closeJobPlaceholders.bd_opportunities_reason">
        </textarea>
        </div>
      </div>
      <div class="form-group">
        <div class="card-body-title-basic">
          <strong>{{closeJobTitles.other_learning}}</strong>
        </div>
        <div class="card-body-content">
          <textarea rows="3" class="form-control" type="text" [(ngModel)]="closingData.other_learning"
            [placeholder]="closeJobPlaceholders.other_learning">
        </textarea>
        </div>
      </div>
    </div>

    <button class="mt-2 btn pull-right bg-secondary-color position-bottom-close-job" (click)="closeJob()">Close
      job</button>
  </div>
  <div *ngIf="reopenJobFlag" class="bg-white p-4 scroll scroll-reopenJob">
    <i class="ft ft-arrow-left"></i> <strong (click)="editJobFlag = true; reopenJobFlag = false"
      class="cursor-pointer">Back to the job</strong>
    <div class="form">
      <div class="form-section">
        Reopen job
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <div class="card-body-title-basic">
            <strong> Reason</strong>
          </div>
        </div>
        <textarea placeholder="Insert reason" [(ngModel)]="reopenJobData.reason" maxlength="191" rows="3"
          class="form-control " type="text"></textarea>
      </div>
      <div class="col-6 text-center">
        <strong>Reopen till</strong>
        <div>
          <ngb-datepicker [(ngModel)]="reopenJobData.reopen_until_date" [ngModelOptions]="{standalone: true}">
          </ngb-datepicker>
        </div>
      </div>
    </div>
    <button class="btn bg-secondary-color pull-right mb-0 mt-1 position-bottom-reopen-job" (click)="reopenJob()">Reopen
      job</button>
  </div>
  <nav ngbNav id="tab-shadow" class="nav-tabs" #acc="ngbNav" [hidden]="!editJobFlag">
    <ng-container [ngbNavItem]="'ngb-tab-0'">
      <a ngbNavLink>General</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll scroll-tabs">
          <ngb-accordion id="filter-accordion" [(activeIds)]="activeId" #acc="ngbAccordion" class="ng-accordion-append"
            (panelChange)="preventDefault ? beforeChange($event) : ''">
            <ngb-panel id="details" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('details')">
                  <div class="form-section">
                    Details
                    <i (click)="changeDetails()" class="icon icon-note"></i>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent *ngIf="readEditFlagDetails">
                <div class="card">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label> * Job title </label>
                    </div>
                    <div class="card-body-content">
                      <input [(ngModel)]="job.job_title" class="form-control" type="text" placeholder="Insert job title"
                        (change)="editJob('job_title',job.job_title)" />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label> * Company </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [clearable]="false" [hideSelected]="true" [items]="companies$ | async" bindLabel="name"
                        placeholder="Select company" dropdownPosition="bottom" [minTermLength]="minLengthTerm"
                        [loading]="companyLoading" typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                        [typeahead]="companyInput$" [(ngModel)]="job.company" [addTag]="addTagCompany"
                        (focusout)="editJob('company',job.company)" (change)="clearContact()">
                        <ng-template ng-option-tmp let-item="item" let-index="i">
                          <div class="media mb-1">
                            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                              [src]="item.photo ? item.photo : globals.companyAvatar"
                              (error)="item.photo = globals.companyAvatar" />
                            <div class="media-body">
                              <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                              <p><i *ngIf="item.location" class="tt-icon-sm icon-ttrelocation"></i>{{item.location ?
                                item.location : globals.labels.placeholders.shared_page.add_job.empty_location}}
                              </p>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ng-tag-tmp let-search="searchTerm">
                          <button
                            class="btn bg-secondary-color btn-position pull-right mb-0">{{globals.labels.placeholders.shared_page.add_job.add_company}}
                            {{search}}</button>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label> * Contact </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [clearable]="false" bindLabel="name" dropdownPosition="bottom"
                        [items]="filteredContact" [addTag]="addTagContact" [disabled]="!job.company"
                        [(ngModel)]="job.contact" (open)="filterContacts(job.company.uuid)"
                        placeholder="Select contact name" (ngModelChange)="editJob('contact',job.contact)">
                        <ng-template ng-option-tmp let-item="item" let-index="i">
                          <div class="media mb-1">
                            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                              [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                            <div class="media-body">
                              <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                              <p><i class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ? item.email :
                                globals.labels.placeholders.shared_page.add_job.empty_email}}<br>
                                <i class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ? item.job_title :
                                globals.labels.placeholders.shared_page.add_job.empty_title}}
                              </p>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ng-tag-tmp let-search="searchTerm">
                          <div class="form-group">
                            <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                              {{globals.labels.placeholders.shared_page.add_job.contact_email}}
                              <span *ngIf="!email" [ngClass]="{'primary-color': !email}">
                                {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                              </span>
                            </label>
                            <input class="form-control" type="text" (click)="prevent($event)"
                              [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.contact_email"
                              [(ngModel)]="email" />
                          </div>
                          <button class="btn bg-secondary-color btn-position pull-right mb-0">
                            {{globals.labels.placeholders.shared_page.add_job.add_contact}} {{search}}
                            {{globals.labels.placeholders.global.at}} {{job.company.name}}
                          </button>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label> * Job type </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [clearable]="false" bindLabel="description" dropdownPosition="bottom" [items]="jobType"
                        [(ngModel)]="job.type" [ngModelOptions]="{standalone: true}" (open)="getJobType()"
                        placeholder="Select job type" (change)="editJob('type',job.type)">
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label> Status </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select (open)="getStatus()" dropdownPosition="bottom" placeholder="Select status"
                            bindLabel="description" [items]="statuses" [(ngModel)]="job.status"
                            [ngModelOptions]="{standalone: true}" (change)="editJob('status',job.status)">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label> Priority </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select (open)="getPriority()" [(ngModel)]="job.priority" bindLabel="description"
                            placeholder="Select priority" [items]="priorities"
                            (change)="editJob('priority',job.priority)"></ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label> Location </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [multiple]="true" [items]="locations$ | async" bindLabel="description"
                        placeholder="Select location" dropdownPosition="bottom" [minTermLength]="minLengthTerm"
                        [loading]="locationLoading" typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                        [typeahead]="locationInput$" [(ngModel)]="job.location" [ngModelOptions]="{standalone: true}"
                        (change)="editJob('location',job.location)">
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                            Functional Expertise </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select [clearable]="false" [(ngModel)]="job.fe" placeholder="Select functional expertise"
                            [ngModelOptions]="{standalone: true}" bindLabel="description" (open)="getFee()"
                            [items]="functionalExpertise" (change)="editJob('fe',job.fe )" (change)="clearSfe()">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label> Sub Functional Expertise </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select [(ngModel)]="job.sfe" placeholder="Select functional expertise"
                            bindLabel="description" (open)="filterSfe(job.fe)" [items]="filteredSfe"
                            (change)="editJob('sfe',job.sfe )" [disabled]="job.fe ? false:true">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label> Industry </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select (open)="getIndustry()" [items]="industries" [(ngModel)]="job.industry"
                            placeholder="Select industry" bindLabel="description"
                            (change)="editJob('industry',job.industry)">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label> Seniority </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select (open)="getSeniority()" [items]="seniorities" [(ngModel)]="job.seniority"
                            placeholder="Select seniority" bindLabel="description"
                            (change)="editJob('seniority',job.seniority)">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label> Project length (months) </label>
                        </div>
                        <div class="card-body-content">
                          <input placeholder="Insert project length" [(ngModel)]="job.projected_length" type="text"
                            class="form-control" (change)="editJob('projected_length',job.projected_length)">
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label> Headcount </label>
                        </div>
                        <div class="card-body-content">
                          <input placeholder="Insert headcount" [(ngModel)]="job.head_count" type="text"
                            class="form-control" (change)="editJob('head_count',job.head_count)">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12 col-lg-6">
                        <div class="card-body-title-basic">
                          <label> Search source</label>
                        </div>
                        <div class="card-body-content">
                          <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="description"
                            dropdownPosition="bottom" [items]="searchSources" [(ngModel)]="job.search_source"
                            [ngModelOptions]="{standalone: true}" (change)="editJob('search_source',job.search_source)"
                            (open)="getSearchSources()" placeholder="Select the search source">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-6">
                        <div class="card-body-title-basic">
                          <label> Affinity opportunity</label>
                        </div>
                        <div class="card-body-content">
                          <ng-select class="ng-append" appendTo=".ng-accordion-append" dropdownPosition="bottom"
                            [items]="opportunities" [(ngModel)]="selectedAffinityOpportunity" bindLabel="name"
                            [ngModelOptions]="{standalone: true}"
                            (change)="editJob('affinity_opportunity_id',selectedAffinityOpportunity)"
                            placeholder="Select an opportunity from affinity">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label> Description </label>
                    </div>
                    <div class="card-body-content">
                      <quill-editor id="description" name="description" [(ngModel)]="job.description"
                        (focusout)="editJob('description',job.description)">
                      </quill-editor>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent *ngIf="!readEditFlagDetails">
                <div class="card-block">
                  <div class="row">
                    <div *ngIf="job.job_title" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Job title
                        </label>
                        <div class="card-body-content">{{job.job_title}}</div>
                      </div>
                    </div>
                    <div *ngIf="!job.job_title" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Job title
                        </label>
                        <div class="card-body-content">No information available.</div>
                      </div>
                    </div>
                    <div *ngIf="job.company && job.company.name" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Company
                        </label>
                        <div class="card-body-content">
                          {{job.company.name}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.company && !job.company.name" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Company
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.contact && job.contact.name" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Contact
                        </label>
                        <div class="card-body-content">
                          {{job.contact.name}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.contact && !job.contact.name" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Contact
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.type && job.company" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Job type
                        </label>
                        <div class="card-body-content">
                          {{job.type.description}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.type && !job.company" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Job type
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.status" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Status
                        </label>
                        <div class="card-body-content">
                          {{job.status.description}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.status" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Status
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="job.priority">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Priority
                        </label>
                        <div class="card-body-content">
                          {{job.priority.description}}
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="!job.priority">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Priority
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Location
                        </label>
                        <div class="row" *ngIf="job.location.length">
                          <div class="col-12">
                            <span *ngFor="let location of job.location;let last = last">
                              {{location.description}}{{last?'':'; '}}
                            </span>
                          </div>
                        </div>
                        <div class="row" *ngIf="!job.location.length">
                          <div class="col-12">
                            No location available
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.fe" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Functional Expertise
                        </label>
                        <div class="card-body-content">
                          {{job.fe.description}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.fe" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Functional Expertise
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.sfe" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Sub Functional Expertise
                        </label>
                        <div class="card-body-content">
                          {{job.sfe.description}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.sfe" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Sub Functional Expertise
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.industry" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Industry
                        </label>
                        <div class="card-body-content">
                          {{job.industry.description}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.industry" class="col-6">
                      <label class="card-body-title-basic">
                        Industry
                      </label>
                      <div class="form-group">
                        No information available.
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.seniority" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Seniority
                        </label>
                        <div class="card-body-content">
                          {{job.seniority.description}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.seniority" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Seniority
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.projected_length" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Project length (months)
                        </label>
                        <div class="card-body-content">
                          {{job.projected_length}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.projected_length" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Project length (months)
                        </label>
                        No information available.
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.head_count" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Headcount
                        </label>
                        <div class="card-body-content">
                          {{job.head_count}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.head_count" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Headcount
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.description" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Description
                        </label>
                        <div class="card-body-content" [innerHTML]="job.description"></div>
                      </div>
                    </div>
                    <div *ngIf="!job.description" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Description
                        </label>
                        <div class="card-body-content">No information available.</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.search_source" class="form-group" class="col-6">
                      <label class="card-body-title-basic">
                        Search source
                      </label>
                      <div class="card-body-content">
                        {{job.search_source.description}}
                      </div>
                    </div>
                    <div *ngIf="!job.search_source" class="form-group" class="col-6">
                      <label class="card-body-title-basic">
                        Search source
                      </label>
                      <div class="card-body-content">No information available.</div>
                    </div>
                    <div *ngIf="selectedAffinityOpportunity && selectedAffinityOpportunity.id" class="form-group"
                      class="col-6">
                      <label class="card-body-title-basic">
                        Affinity opportunity
                      </label>
                      <div class="card-body-content">
                        {{selectedAffinityOpportunity.name}}
                      </div>
                    </div>
                    <div *ngIf="!selectedAffinityOpportunity || !selectedAffinityOpportunity.id" class="form-group"
                      class="col-6">
                      <label class="card-body-title-basic">
                        Affinity opportunity
                      </label>
                      <div class="card-body-content">No information available.</div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="team" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('team')">
                  <div class="form-section">
                    Team
                    <i (click)="changeEditTeamFlag()" class="icon icon-note" data-placement="bottom"></i>
                    <i (click)="changeAddTeamFlag()" class="icon icon-plus" data-placement="bottom"></i>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="card card-preview" *ngIf="addTeamFlag">
                  <div class="form form-section">
                    <label>Select Pod and Users with roles</label>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label> Pod </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select class="ng-append" appendTo=".ng-accordion-append" (open)="getPods()" [(ngModel)]="job.pod"
                        [items]="pods" bindLabel="name" placeholder="Select pod" (change)="editJob('pod',job.pod)" dropdownPosition="bottom">
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-6">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>User</label>
                          </div>
                          <div class="card-body-content">
                            <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="name"
                              dropdownPosition="bottom" [items]="users" [(ngModel)]="tmpUser.user"
                              (open)="getInternalUsers()"
                              [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.user">
                              <ng-template ng-option-tmp let-item="item" let-index="i">
                                {{item.name}} - {{item.email}}
                              </ng-template>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6 col-lg-6">
                        <div class="card-body-title-basic">
                          <label>Role</label>
                        </div>
                        <div class="card-body-content">
                          <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="name" [closeOnSelect]="false"
                            [multiple]="true" dropdownPosition="bottom" [items]="roles" [(ngModel)]="tmpUser.role"
                            (open)="getRoles()"
                            [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.role">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="pull-right">
                          <button class="btn bg-secondary-color " (click)="addUser()">
                            Add new user
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 mt-2" *ngIf="editTeamFlag">
                  <div class="form-group p-1" *ngIf="job.users && job.users.length">
                    <div class="card-preview row mb-2" *ngFor="let user of job.users;let i = index">
                      <div class="col-md-6 col-lg-6">
                        <div class="card-body-title-basic">
                          <label class="pt-2">User</label>
                        </div>
                        <div class="card-body-content">
                          <label>{{user.name}}</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="card-body-title-basic">
                          <label>Role</label>
                        </div>
                        <div class="card-body-content row">
                          <div class="col-10">
                            <div *ngIf="user.roles && user.roles.length">
                              <div *ngFor="let role of user.roles" class="mr-3">
                                <span class="badge ml-0"
                                  [ngClass]="{'badge-secondary' : role.value == 'recruit', 'badge-third' : role.value == 'administrator',
                                                           'badge-warning': role.value == 'research', 'badge-solve' : role.value == 'operations', 'badge-primary' : role.value == 'sales' || role.value == 'connect'}">
                                  <i class="fa fa-circle"></i>
                                </span>
                                <label>
                                  {{role.name}} <i (click)="deleteRole(role,user)"
                                    class="tt-icon-sm icon-ttdelete cursor-pointer" title="Delete role"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="card-block">
                    <div class="row">
                      <div class="col-6" *ngIf="job.users.length > 0">
                        <label class="card-body-title-basic">
                          <strong>Users</strong>
                        </label>
                        <div class="form-group card-body-content" *ngFor="let user of job.users">
                          {{user.name}}
                        </div>
                      </div>
                      <div *ngIf="job.pod" class="col-6">
                        <strong> Pod </strong>
                        <p>{{job.pod.name}}</p>
                      </div>
                      <div *ngIf="!job.pod && job.users.length == 0" class="col-6">
                        No information available.
                      </div>
                    </div>

                  </div>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="compensation" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('compensation')">
                  <div class="form-section">
                    Compensation
                    <i (click)="changeFlagCompensation()" class="icon icon-note"></i>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent *ngIf="readEditFlagCompensation">
                <div class="card-block">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label> Currency </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [(ngModel)]="job.jobCompensation.currency" bindLabel="description" [clearable]="false"
                        (open)="getCurrency()" [items]="currencies" placeholder="Select currency"
                        (change)="editJob('jobCompensation.currency',job.jobCompensation.currency)">
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label>* Salary from </label>
                        </div>
                        <div class="card-body-content">
                          <input placeholder="Insert salary from" [(ngModel)]="job.jobCompensation.salary_from"
                            type="text" class="form-control"
                            (change)="editJob('jobCompensation.salary_from',job.jobCompensation.salary_from)">
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label>* Salary to </label>
                        </div>
                        <div class="card-body-content">
                          <input placeholder="Insert salary to" [(ngModel)]="job.jobCompensation.salary_to" type="text"
                            class="form-control"
                            (change)="editJob('jobCompensation.salary_to',job.jobCompensation.salary_to)">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>* Annual salary </label>
                    </div>
                    <div class="card-body-content">
                      <input placeholder="Insert annual salary" [(ngModel)]="job.jobCompensation.annual_salary"
                        type="text" class="form-control"
                        (change)="editJob('jobCompensation.annual_salary',job.jobCompensation.annual_salary)">
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-8">
                        <div class="card-body-title-basic">
                          <label> Bonus </label>
                        </div>
                        <div class="card-body-content">
                          <input placeholder="Insert bonus" [(ngModel)]="job.jobCompensation.bonus" type="text"
                            class="form-control" (change)="editJob('jobCompensation.bonus',job.jobCompensation.bonus)">
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="card-body-title-basic">
                          <label> Bonus type </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select placeholder="Insert bonus type" bindLabel="description" (open)="getbonusTypes()"
                            [(ngModel)]="job.jobCompensation.bonus_type" [items]="bonusTypes"
                            placeholder="Select bonus type" bindLabel="description"
                            (change)="editJob('jobCompensation.bonus_type',job.jobCompensation.bonus_type)">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label> Equity </label>
                    </div>
                    <div class="card-body-content">
                      <textarea placeholder="Insert equity" [(ngModel)]="job.jobCompensation.equity" rows="3"
                        class="form-control" type="text"
                        (change)="editJob('jobCompensation.equity',job.jobCompensation.equity)"> </textarea>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-8">
                        <div class="card-body-title-basic">
                          <label>* Fee </label>
                        </div>
                        <div class="card-body-content">
                          <input [(ngModel)]="job.jobCompensation.fee" type="text" class="form-control"
                            (change)="editJob('jobCompensation.fee',job.jobCompensation.fee)"
                            (keydown)=preventNegativeNumbers($event)>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="card-body-title-basic">
                          <label>* Fee type </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="description"
                            (open)="getFeeType()" [(ngModel)]="job.jobCompensation.fee_type" [items]="feeType"
                            placeholder="Select bonus type" bindLabel="description"
                            (change)="editJob('jobCompensation.fee_type',job.jobCompensation.fee_type)"
                            [clearable]="false">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </ng-template>
              <ng-template ngbPanelContent *ngIf="!readEditFlagCompensation">
                <div *ngIf="job.jobCompensation" class="card-block">
                  <div *ngIf="job.jobCompensation.currency">
                    <div class="form-group">
                      <label class="card-body-title-basic">
                        Currency
                      </label>
                      <div class="card-body-content">
                        {{job.jobCompensation.currency.description}}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.salary_from">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          Salary from
                        </div>
                        {{job.jobCompensation.salary_from}}
                      </div>
                    </div>
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.salary_to">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Salary to
                        </label>
                        <div class="card-body-content">
                          {{job.jobCompensation.salary_to}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="job.jobCompensation && job.jobCompensation.annual_salary">
                    <div class="form-group">
                      <label class="card-body-title-basic">
                        Annual salary
                      </label>
                      <div class="card-body-content">
                        {{job.jobCompensation.annual_salary}}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.bonus">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Bonus
                        </label>
                        <div class="card-body-content">
                          {{job.jobCompensation.bonus}}
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.bonus_type">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Bonus type
                        </label>
                        <div class="card-body-content">
                          {{job.jobCompensation.bonus_type.description}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="job.jobCompensation && job.jobCompensation.equity">
                    <div class="form-group">
                      <label class="card-body-title-basic">
                        Equity
                      </label>
                      <div class="card-body-content">
                        {{job.jobCompensation.equity}}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.fee">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Fee
                        </label>
                        <div class="card-body-content">
                          {{job.jobCompensation.fee}}
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.fee_type">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Fee type
                        </label>
                        <div class="card-body-content">
                          {{job.jobCompensation.fee_type.description}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!job.jobCompensation.salary_from && !job.jobCompensation.bonus && !job.jobCompensation.bonus_type && !job.jobCompensation.currency
                    && !job.jobCompensation.equity && !job.jobCompensation.fee && !job.jobCompensation.fee_type && !job.jobCompensation.salary_from
                    && !job.jobCompensation.salary_to && !job.jobCompensation.updated_at" class="col-6">
                  <div class="form-group">
                    <div class="card-body-content">No information available.</div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="scorecards" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('scorecards')">
                  <div class="form-section">
                    Scorecards
                    <i (click)="changeFlagScorecards()" class="icon icon-note"></i>
                    <i (click)="changeAddScorecars()" class="icon icon-plus" data-placement="bottom"></i>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div *ngIf="addScoreCardFlag">
                  <div class="row">
                    <div class="col-11">
                      <div class="form-group">
                        <input class="form-control" placeholder="Insert scorecard description" type="text" [(ngModel)]="addScorecard" onFocusOut>
                      </div>
                    </div>
                    <div class="col-1">
                      <button class="btn bg-secondary-color pull-right mb-0 mt-1" (click)="addNewScorecard()">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body-title-basic card card-preview mb-2"
                  *ngIf="readEditFlagScorecards">
                  <div class="card-body-content">
                    <div class="form-group" *ngFor="let scorecard of job.scorecards; let i = index">
                      <div class="row">
                        <div class="col-11">
                          <input class="form-control" type="text" [(ngModel)]="scorecard.skill" appOnFocusOut
                            (change)="editScorecards('scorecards',job.scorecards)"
                            (keydown.backspace)="checkEmptyScorecard('scorecards',job.scorecards,scorecard.skill,i)">
                        </div>
                        <div class="col-1">
                          <a (click)="deleteScorecard('scorecards',job.scorecards, i)">
                            <i class="tt-icon-sm icon-ttdelete pull-right m-2" data-toggle="tooltip"
                              data-placement="bottom"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-block mt-2" *ngIf="!readEditFlagScorecards">
                  <div *ngIf="job.scorecards.length">
                    <div class="form-group" *ngFor="let scorecard of job.scorecards; let i = index">
                      <p>{{scorecard.skill}}</p>
                    </div>
                  </div>
                  <div *ngIf="!job.scorecards.length">
                    No information available.
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'ngb-tab-2'">
      <a ngbNavLink>
        <div (click)="getActivity()">Activity</div>
      </a>
      <ng-template ngbNavContent>
        <div class="card-body">
          <div class="form">
            <div class="form-section">
              Job activity
            </div>
          </div>
          <div *ngIf="activities.length>0; then showActivity else hideActivity;"></div>
          <ng-template #hideActivity>
            <div class="media pl-3 pt-2">
              No information available.
            </div>
          </ng-template>
          <div class="card-block">
            <ng-template #showActivity>
              <div class="scroll scroll-tabs">
                <div class="timeline-centered">
                  <article class="timeline-entry" *ngFor="let activity of activities">
                    <div class="timeline-entry-inner" *ngIf="activity">
                      <div class="timeline-icon">
                        <i class="fa fa-circle primary-color"></i>
                      </div>
                      <div class="timeline-label">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="timeline-body mr-4" [innerHTML]="activity.title" *ngIf="activity.title">
                            </div>
                            <span class="timeline-time-ago">
                              {{activity.created_date | dateDiffNav:activity.created_date }}</span>
                            <div *ngIf="activity.body" class="timeline-body mr-4">
                              <p class="timeline-body-activity p-2" [innerHTML]="activity.body">
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article class="timeline-entry begin">
                    <div class="timeline-entry-inner">
                      <div class="timeline-icon"
                        style="-webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg);">
                        <i class="fa fa-circle" style="color:#e5e5e5 !important;"></i>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'ngb-tab-3'">
      <a ngbNavLink>
        <div>Hunting ground</div>
      </a>
      <ng-template ngbNavContent>
        <div class="card-block">
          <div class="card-body">
            <div class="form">
              <div class="form-section">
                Hunting ground
              </div>
            </div>
            <div *ngIf="huntingGrounds.length" class="scroll scroll-tabs">
              <div *ngFor="let huntingGround of huntingGrounds; let i = index">
                <div class="card">
                  <div class="card-block">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-7">
                          <div><strong>{{huntingGround.name}}</strong></div>
                        </div>
                        <div class="col-5 text-right">
                          <div class="form-group">
                            <a><i class="icon-docs" (click)="copyToClipboard(huntingGround)"
                                title="Copy to clipboard"></i></a>
                            <a><i class="icon icon-note ml-2" (click)="editHountinrgGround(huntingGround.uuid)"
                                title="Edit hounting ground"></i></a>
                            <a><i class="tt-icon-sm icon-ttdelete ml-1" (click)="deleteHuntingGroun(huntingGround.uuid)"
                                title="Delete hunting ground"></i></a>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Created by:
                        </label>
                        <div class="card-body-content">
                          {{huntingGround.created_by}}
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Created:
                        </label>
                        <div class="card-body-content">
                          {{huntingGround.created_at | dateDiffTimestampFullNav}}
                        </div>
                      </div>
                      <ngb-accordion [(activeIds)]="huntingGroundsActiveId" id="huntingGroundAccordion"
                        #acc="ngbAccordion" *ngFor="let hGround of huntingGround.tags">
                        <ngb-panel [id]="hGround.key + '-' + i">
                          <ng-template ngbPanelHeader>
                            <div class="card-block" (click)="acc.toggle(hGround.key+ '-' + i)">
                              <div class="form">
                                <div class="form-section">
                                  {{hGround.key | removeUndersoreCapitalizeFirst}}<i
                                    class="fa fa-filter tt-icon-lg"></i>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="row ml-1">
                              <div class="col-12">
                                <div class="text-center hunting-ground-tags width-100 form-group"
                                  *ngFor="let val of hGround.values">
                                  {{val | removeUndersoreCapitalizeFirst}}
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!huntingGrounds.length">
              No information available.
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'ngb-tab-4'">
      <a ngbNavLink>Files</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll scroll-tabs">
          <ngb-accordion [(activeIds)]="filesActiveId" id="filter-accordion" #accFiles="ngbAccordion" class = "ng-accordion-append"
            (panelChange)="preventDefault ? beforeChange($event) : ''">
            <ngb-panel id="files">
              <ng-template ngbPanelHeader>
                <div class="form mt-3" (click)="accFiles.toggle('files')">
                  <div class="form-section">
                    Files
                    <i *ngIf="job.files.length > 0" title="Edit file" class="icon icon-note" data-placement="bottom"
                      (click)="toogleEditFileFlag()"></i>
                    <i title="Add file" class="icon icon-plus" (click)="toogleAddFileFlag()"></i>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div *ngIf="addFileFlag">
                  <div class="form-group">
                    <div class="card card-preview">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                {{globals.labels.placeholders.shared_page.add_job.file_type}}
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select class="ng-append" appendTo=".ng-accordion-append" [(ngModel)]="add.file.type"
                                [ngModelOptions]="{standalone: true}" bindLabel="description"
                                [items]="resources.fileType" dropdownPosition="bottom"
                                [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.file_type"
                                (open)="getFileType()" [clearable]="false">
                              </ng-select>
                            </div>
                          </div>
                          <div class="form-group" *ngIf="add.file.type.value">
                            <div class="card-body-content">
                              <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                                (fileOver)="fileOverBaseFile($event)" [uploader]="uploaderFile"
                                class="p-3 text-center grey my-drop-zone">
                                <span [innerHTML]="globals.labels.placeholders.global.drag_and_drop"></span>
                                <button class="btn bg-primary-color col-md-3 col-lg-3 col-xl-3 btn-position"
                                  (click)="fileInputFile.click()">
                                  Upload file
                                </button>
                              </div>
                              <input style="display: none;"
                                class="hiddenInput m-0 p-0 position-absolute overflow-hidden" type="file" ng2FileSelect
                                [uploader]="uploaderFile" #fileInputFile (change)="OnContentChangeFile()">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="job.files.length; then showFiles else showEmptyFiles"></div>
                <ng-template #showFiles>
                  <div class="form-group" *ngFor="let file of job.files;let i = index">
                    <div *ngIf="!editFileFlag">
                      <div class="card card-preview">
                        <div class="card-block">
                          <div class="col-12">
                            <a *ngIf="globals.checkIfExists('permission', 'edit_candidates')" class="pull-right"
                              (click)="deleteFile(file.uuid, i, job.files, job.uuid)">
                              <a><i class="tt-icon-lg icon-ttdelete secondary-color" data-toggle="tooltip"
                                  data-placement="bottom" [title]="globals.labels.tooltips.global.delete"></i></a>
                            </a>
                          </div>
                          <div class="col-10">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                File type
                              </div>
                              <div class="card-body-content">
                                <ng-select class="ng-append" appendTo=".ng-accordion-append" [(ngModel)]="file.type"
                                  [ngModelOptions]="{standalone: true}" bindLabel="description"
                                  [items]="resources.fileType" dropdownPosition="auto"
                                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.file_type"
                                  (open)="getFileType()"
                                  (change)="file.type ? editFile(file): toastr.warning('File type is mandatory')"
                                  [clearable]="false">
                                </ng-select>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                File name
                              </div>
                              <div class="card-body-content">
                                <div class="card-body-content full-width display-inline-flex">
                                  <input type="text" class="form-control col-10" placeholder="Insert file name"
                                    [ngModel]="file.original_name.substr(0, file.original_name.indexOf('.'))"
                                    [ngModelOptions]="{standalone: true}" appOnFocusOut
                                    (change)="file.original_name ? removeSpacesFileName(file):toastr.warning('File name is mandatory')"
                                    (ngModelChange)="onChange(file.name = $event)">
                                  <div class="col-2 align-self-center text-center">
                                    {{file.original_name.substr(file.original_name.lastIndexOf('.'))}}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group" *ngIf="'cv/resume' == file.type.value">
                              <div class="card-body-content">
                                <div class="custom-control custom-checkbox m-0">
                                  <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="file.is_primary" class="custom-control-input" id="primary-CV+{{i}}">
                                  <label class="custom-control-label" for="primary-CV+{{i}}">Primary CV
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="editFileFlag">
                      <div class="form-group">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-1">
                              <a [href]="file.url" target="_blank">
                                <div *ngIf="file.original_name">
                                  <div
                                    [ngSwitch]="file.original_name.substr(file.original_name.lastIndexOf('.')+1).toLowerCase()">
                                    <div *ngSwitchCase="'pdf'">
                                      <i class="fa fa-file-pdf-o tt-icon-xl primary-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'xlsx'">
                                      <i class="fa fa-file-excel-o tt-icon-xl text-success m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'doc'">
                                      <i class="fa fa-file-word-o tt-icon-xl big-search-primary-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'docx'">
                                      <i class="fa fa-file-word-o tt-icon-xl big-search-primary-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'pptx'">
                                      <i class="fa fa-file-powerpoint-o tt-icon-xl warning-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'png'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'jpg'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'jpeg'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchDefault>
                                      <i class="fa fa-file-o tt-icon-xl m-2" aria-hidden="true"></i>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div class="col-10">
                              <div class="form-group">
                                <div class="card-body-title-basic" *ngIf="file.type.description">
                                  {{file.type.description}}
                                  <span *ngIf="file.is_primary && 'cv/resume' == file.type.value"
                                    class="tag secondary-color ml-5">
                                    <em>Primary CV/Resume</em>
                                  </span>
                                </div>
                              </div>
                              <div class="form-group" *ngIf="file.original_name">
                                <div class="card-body-content">
                                  <a [href]="file.url" target="_blank" [ngClass]="{'secondary-color':file.url}">
                                    <u>{{file.original_name}}</u>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyFiles>
                  <div>
                    No information available.
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="acc" *ngIf="editJobFlag"></div>
  <div *ngIf="editJobFlag" class="mt-2">
    <label><strong>Important:</strong> Data is saved automatically when you navigate out of a field or
      select an
      item from the dropdown list</label>
  </div>
  <div *ngIf="reopenJobFlag" class="mt-2">
    <label><strong>Important:</strong> If no date is selected job will reopen in duration in one year</label>
  </div>
