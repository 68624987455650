import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService{
  //localToken: string = ;
  cachedRequests: Array<HttpRequest<any>> = [];

  constructor(private route: Router, private httpClient: HttpClient){ }

  getToken(){
    return localStorage.getItem('access-token');
  }

  logout(){
    this.httpClient.get(environment.platformBackendURL + '/logout').subscribe();
    localStorage.removeItem('access-token');
    this.route.navigate(['login']);
  }

  isAuthenticated(){
    // here you can check if user is authenticated or not through his token
    return Boolean(localStorage.getItem('access-token'));
  }

  public collectFailedRequest(request): void{
    this.cachedRequests.push(request);
  }

  public retryFailedRequests(): void{
    // retry the requests. this method can
    // be called after the token is refreshed

  }
}
