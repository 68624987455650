import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, Directive, ChangeDetectorRef } from '@angular/core';
import { ViewCandidateService } from './view-candidate.service';
import { Globals } from 'app/globals';
import { NgbDropdown, NgbNav, NgbAccordion, NgbPanelChangeEvent, NgbPopover, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationService } from 'app/shared/service/communication.service';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { Subject, of, concat, Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

import { UpdateApplicationModalComponent } from 'app/shared/platform-modals/update-application-modal/update-application-modal.component';
import { ChooseMergeCandidateModalComponent } from './choose-merge-candidate-modal/choose-merge-candidate-modal.component';
import { MergeCandidatesModalComponent } from './merge-candidates-modal/merge-candidates-modal.component';
import { Location } from '@angular/common';
import { DeleteCandidateModalComponent } from './delete-candidate-modal/delete-candidate-modal.component';
import { Angulartics2 } from 'angulartics2';
import * as moment from 'moment';
import { AddToJobModalComponent } from './add-to-job-modal/add-to-job-modal.component';
import { GeneralConfirmationModalComponent } from 'app/shared/platform-modals/general-confirmation-modal/general-confirmation-modal.component';
import { BlacklistCandidateModalComponent } from './blacklist-candidate-modal/blacklist-candidate-modal.component';
@Directive({
  selector: '[appRendered]'
})
export class RenderedDirective{

  @Output() rendered: EventEmitter<any> = new EventEmitter();

  OnInit(){
    this.rendered.emit();
  }
}

@Component({
  selector: 'app-view-candidate',
  templateUrl: './view-candidate.component.html',
  styleUrls: ['./view-candidate.component.scss']
})
export class ViewCandidateComponent implements OnInit, OnChanges{
  @Input() candidate_uuid: string;

  @Input() job_uuid: string;

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  subscription: any;

  data: any = [];

  candidate_info: any = {};

  candidate: any = {};

  newComment: any;

  taggedUser: any = [];

  indexOf: any = 0;

  filteredUsers: any = [];

  comments: any = [];

  activities: any = [];

  stage: any = [];

  validForm = true;

  disableSendBtn = false;

  showComments = false;

  not_applicable = false;

  loading = false;

  loadingFiles = false;

  loadingActivity = false;

  addActivity = false;

  innerWidth: number;

  users: any = [];

  @ViewChild('tagsDropDown') tagsDropDown: NgbDropdown;

  localCandidates = false;

  masterCandidates = false;

  activeIds: string[] = [];

  @ViewChild('subAcc') subAcc: NgbAccordion;

  preventDefault = false;

  addTojob: any = {
    job_uuid: null,
    candidate_uuid: null,
    substatus: null,
    stage: null,
    comment: null
  };

  candidates: any;

  accordion: any = {
    basic: true,
    compensation: true,
    consent: true,
    experience: true,
    education: true,
    language: true,
    miscellaneous: true,
    closedJobs: true,
    openJobs: true,
    openNavisJobs:true,
    closedNavisJobs:true,
    files: true,
    add_activity: true,
    filters: true
  };

  edit: any = {
    basic: false,
    compensation: false,
    consent: false,
    experience: false,
    education: false,
    language: false,
    miscellaneous: false,
    files: false
  };

  add: any = {
    experienceForm: false,
    experience: {
      job_title: null,
      company_name: null,
      current_employer: false,
      end_date: null,
      expertise: [],
      fe: [],
      sfe: [],
      industry: [],
      seniority: null,
      start_date: null,
      uuid: null,
      is_investment: false,
      not_applicable: false,
      summary: null,
      is_linkedin: false,
      investment_country_focus: [],
      investment_stage_focus: [],
      investment_sector_focus: []
    },
    educationForm: false,
    education: {
      university: null,
      degree_name: null,
      major: null,
      is_linkedin: false,
      start_date: null,
      end_date: null,
      uuid: null
    },
    languageForm: false,
    language: {
      language: null,
      level: null
    },
    fileForm: false,
    file: {
      type: [],
      uuid: null,
      url: null,
      id: null,
      is_primary: false
    },
    activityFilter: {
      filter_type: null,
      selected_filter: null,
      filters: {}
    }
  };

  resources: any = {
    gender: [],
    fe: [],
    sfe: [],
    industry: [],
    seniority: [],
    source: [],
    filteredSfe: [],
    language: [],
    level: [],
    fileType: [],
    subStatuses: [],
    initialSubStatuses: [],
    stage: [],
    currencies: [],
    bonus_type: [],
    jobs: {
      type: null,
      data: []
    },
    stageFocus: [],
    countryFocus: [],
    sectorFocus: [],
    nationality: [],
    internal: [],
    campaigns: [],
    client: [],
    activity: [],
    filters: [],
    activity_tags: [],
    obtained_type: [],
    consent_type: [],
    candidate_application_rating: []
  };

  candidateActivity: any = {
    type: null,
    data: null,
    candidates: [],
    jobs: []
  };

  readonly minLengthTerm = 3;

  locations$: Observable<any>;

  locationLoading = false;

  locationInput$ = new Subject<string>();

  languageLoading = false;

  languageinput = new Subject<string>();

  nationalityLoading = false;

  nationalityinput = new Subject<string>();

  sectorLoading = false;

  sectorinput = new Subject<string>();

  stageLoading = false;

  stageinput = new Subject<string>();

  countryLoading = false;

  countryinput = new Subject<string>();

  @ViewChild('acc') acc: NgbNav;

  public uploader: FileUploader;

  public uploaderFile: FileUploader;

  public hasBaseDropZoneOver;

  changed = false;

  filterBody: any = {};

  @ViewChild('popOver') public popContentFilters: NgbPopover;

  allJobs = true;

  objectKeys = Object.keys;

  oldJobsClients: string;

  oldJobsContacts: string;

  showFilterTag: any = {
    from: false,
    to: false
  };

  addActivityResponse: any;

  //parameter variables
  accessOrigin = null;

  parentComponent = null;

  filterType = null;

  constructor(private profileService: ViewCandidateService, public globals: Globals, private communicationService: CommunicationService, public toastr: ToastrService,
    private cdr: ChangeDetectorRef, private router: Router, private modalService: NgbModal, private activatedRoute: ActivatedRoute, private location: Location,
    public angulartics2: Angulartics2){
    this.innerWidth = window.innerWidth;
    this.accessOrigin = this.activatedRoute.snapshot.queryParamMap.get('access_origin');
    const URL = environment.platformBackendURL + '/assets';
    this.uploader = new FileUploader({
      url: URL,
      isHTML5: true,
      method: 'POST',
      itemAlias: 'file',
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.uploaderFile = new FileUploader({
      url: URL,
      isHTML5: true,
      method: 'POST',
      itemAlias: 'file',
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
      const obj = JSON.parse(response);
      if (status === 201){
        this.candidate.photo = obj.file_url;
        this.editCandidate('photo', this.candidate.photo, this.candidate.uuid);
      } else {
        this.toastr.error(obj.message);
      }
    };

    this.uploaderFile.onCompleteItem = (item: any, response: any, status: any) => {
      const obj = JSON.parse(response);
      if (status === 201){
        this.add.file.uuid = obj.uuid;
        this.add.file.url = obj.file_url;
        this.add.file.id = obj.id;
        this.addNewFile(this.add.file, this.candidate.uuid);
        this.initFile();
      } else {
        this.toastr.error(obj.message);
      }
      this.hasBaseDropZoneOver = false;
    };
  }

  initEdit(){
    this.edit = {
      experience: false,
      basic: false,
      education: false,
      language: false,
      miscellaneous: false,
      files: false
    };
  }

  initExperience(){
    this.add.experience = {
      job_title: null,
      company_name: null,
      current_employer: false,
      end_date: null,
      expertise: [],
      fe: [],
      sfe: [],
      industry: [],
      seniority: null,
      start_date: null,
      uuid: null,
      is_investment: false,
      not_applicable: false,
      is_linkedin: false,
      nationality: null,
      ranking: null,
      summary: null,
      investment_country_focus: [],
      investment_stage_focus: [],
      investment_sector_focus: []
    };
  }

  initEducation(){
    this.add.education = {
      university: null,
      degree_name: null,
      major: null,
      start_date: null,
      end_date: null,
      is_linkedin: false,
      uuid: null
    };
  }

  openStageDropdown(){
    if (!this.stage.length){

      this.profileService.getStageStatus()
        .subscribe((stage) => {
          if (stage){
            this.stage = stage;

          }
        });
    }
  }

  initLanguage(){
    this.add.language = {
      language: null,
      level: null
    };
  }

  preventNegativeNumbers(e: any){
    if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode == 8)){
      return false;
    }
    return true;
  }

  initFile(){
    this.add.file = {
      type: [],
      uuid: null,
      url: null,
      id: null,
      is_primary: false
    };
  }

  findValueFileType(id){
    const file = this.resources.fileType.find(type => type.id == id);
    return file.value;
  }

  findDescriptionFileType(id){
    const file = this.resources.fileType.find(type => type.id == id);
    return file.description;
  }

  public fileOverBase(e: any){
    this.hasBaseDropZoneOver = e;
    this.uploader.uploadAll();
  }

  public fileOverBaseFile(e: any){
    this.hasBaseDropZoneOver = e;
    this.uploaderFile.uploadAll();
  }

  ngOnInit(){

  }

  ngOnChanges(){
    this.initEdit();
    this.loadLanguage();
    this.loadLocations();
    this.loadCountry();
    this.loadSector();
    this.loadNationality();
    this.initializeProfile();
    this.cancelActivity();
    this.getCandidateView(this.candidate_uuid);
    this.parentComponent = this.activatedRoute.snapshot.queryParamMap.get('parent_component');
    this.filterType = this.activatedRoute.snapshot.queryParamMap.get('filter_type');
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    this.getSfe();
  }

  initializeProfile(){
    this.candidate = {};
    this.comments = [];
    this.activities = [];
    this.users = [];
    this.masterCandidates = false;
    this.activeIds = ['basic', 'compensation', 'consent', 'experience', 'education', 'language', 'miscellaneous', 'navis1Searches', 'navisSearches', 'closedJobs', 'openJobs', 'openNavisJobs', 'closedNavisJobs', 'files', 'filters'];
    this.initExperience();
  }

  openLinkedIn(linkedinURL){
    window.open(linkedinURL, '_blank');
  }

  numberOfDaysBetweenDateAndNow(date){
    const now = moment(new Date()); //todays date
    const end = moment(date); // another date
    const duration = moment.duration(now.diff(end));
    const days = duration.asDays();
    return Math.round(days);
  }

  getCandidateView(candidate_uuid){
    this.loading = true;
    this.profileService.getCandidateProfile(candidate_uuid)
      .subscribe((data) => {
        this.candidate = data;
        this.loading = false;
        this.masterCandidates = true;
        if (this.accessOrigin == 'recylce_email'){
          this.angulartics2.eventTrack.next({
            action: 'Recycled candidate was accessed from email',
            properties: {
              candidate: this.candidate,
              category: 'recycle candidate'
            }
          });
        }
        if (this.parentComponent == 'filter-candidate' && this.filterType == 'recommend'){
          this.insertMixPanelTracking({
            candidate_uuid: this.candidate.uuid,
            candidate_name: this.candidate.first_name + ' ' + this.candidate.last_name,
            recommended_job: this.activatedRoute.snapshot.queryParamMap.get('recommended_job'),
            candidate_index: this.activatedRoute.snapshot.queryParamMap.get('candidate_index')
          }, 'Recommended candidate drawer was open');
        }
        if (this.candidate.linkedin){
          this.getNavisJobs(this.candidate.linkedin);
        }
      }, (err) => {
        this.displayErrorMessage(err);
      });
  }

  getNavisJobs(linkedin){
    this.profileService.getNavisJobs(linkedin).subscribe(
      (data) => {
        this.candidate.navis_jobs = data;
      }, (err) => {
        this.displayErrorMessage(err);
      }
    );
  }

  detectHeightChanges(){
    this.cdr.detectChanges();
  }

  fetchActiveTab(event){
    if (event.nextId === 'ngb-tab-3'){
      this.getUsers('internal');
      this.profileService.getComments(this.candidate_uuid, this.job_uuid ? this.job_uuid : null)
        .subscribe((data) => {
          this.comments = data;
        }, (err) => {
          this.displayErrorMessage(err);
        });
    } else if (event.nextId === 'ngb-tab-4'){
      this.profileService.getActivityFilterResources()
        .subscribe(
          (data) => {
            this.resources.activity = data;
          },
          (err) => {
            this.displayErrorMessage(err);
          },
          () => {
            this.add.activityFilter.filter_type = this.resources.activity[0].value;
            this.getActivity(true, this.add.activityFilter.filter_type);
          }
        );
    }
  }

  addToJobModal(){
    const modalRef = this.modalService.open(AddToJobModalComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });

    modalRef.result.then((data) => {

      this.addTojob = data;
      this.addCandidateToJob();

    }, () => {
    });
  }

  getActivity(filter, type){
    this.loadingActivity = true;
    if (this.subscription){
      this.subscription.unsubscribe();
    }
    this.activities = [];
    if (filter){
      this.add.activityFilter.filters = {};
      this.add.activityFilter.selected_filter = null;
      this.oldJobsClients = '';
      this.oldJobsContacts = '';
      this.resources.clients = [];
      this.resources.contacts = [];
      this.resources.jobs.data = [];
    }
    this.subscription = this.profileService.getActivity(this.candidate.uuid, this.add.activityFilter.filters, type, this.candidate.primary_email)
      .subscribe((data) => {
        this.activities = data;
        this.loadingActivity = false;
      },
        (err) => {
          this.activities = [];
          this.loadingActivity = false;
          this.displayErrorMessage(err);
        });

  }

  prepareFilterBody(key, value, type){
    if (!Object.keys(this.add.activityFilter.filters).includes(key)){
      key == 'users' ? this.add.activityFilter.filters[key] = {} : this.add.activityFilter.filters[key] = value;
    }
    if (key == 'users' && (!this.add.activityFilter.filters.users.internal && !this.add.activityFilter.filters.users.client)){
      this.add.activityFilter.filters.users.clientBoolean = true;
      this.add.activityFilter.filters.users.internalBoolean = true;
      this.add.activityFilter.filters.users.internal = [];
      this.add.activityFilter.filters.users.client = [];
    }
    if (key == 'from' || key == 'to'){
      this.changeTags(key);
    }
    this.getActivity(false, type);
  }

  changeTags(key){
    this.add.activityFilter.filters[key].length ? this.showFilterTag[key] = false : this.showFilterTag[key] = true;
  }

  changeUsersState(state, type){
    if (state){
      this.add.activityFilter.filters.users[type] = [];
    } else {
      this.add.activityFilter.filters.users[type] = [];
      delete this.add.activityFilter.filters.users[type];
    }

    this.getActivity(false, this.add.activityFilter.filter_type);
  }

  getFilterColor(type, filter){
    const resourceIndex = this.resources.activity.findIndex(resource => resource.value === type);
    if (resourceIndex !== -1){
      const childrenIndex = this.resources.activity[resourceIndex].children.findIndex(children => children.value === filter);
      if (childrenIndex !== -1){
        return this.resources.activity[resourceIndex].children[childrenIndex].color_code;
      }
    }
    return null;
  }

  isObject(object){
    return (object && typeof object === 'object');
  }

  isArray(array){
    return (Array.isArray(array));
  }

  checkObjectValues(obj){
    let hasValue = false;
    for (const key in obj){
      if (obj[key] !== null && obj[key] != "")
        hasValue = true;
    }
    return hasValue;
  }

  checkArrayObjectValues(array){
    let hasValue = false;
    array.forEach(element => {
      if (element.skill){
        hasValue = true;
      }
    });
    return hasValue;
  }

  deleteFilter(filter, deleteKey, i?){
    if (deleteKey){
      this.add.activityFilter.filters[filter] = [];
      delete this.add.activityFilter.filters[filter];
    } else {
      this.add.activityFilter.filters[filter].splice(i, 1);
    }
    this.add.activityFilter.selected_filter = null;
    this.getActivity(false, this.add.activityFilter.filter_type);
  }

  showFilters(type, filter){
    const resourceIndex = this.resources.activity.findIndex(resource => resource.value === type);
    if (resourceIndex !== -1){
      const childrenIndex = this.resources.activity[resourceIndex].children.findIndex(children => children.value === filter);
      if (childrenIndex !== -1){
        this.add.activityFilter.selected_filter = this.resources.activity[resourceIndex].children[childrenIndex];
      }
    }
  }

  getFilteredFilters(filterValue){
    this.resources.filters = [];
    if (this.add.activityFilter.filter_type){
      this.resources.activity.forEach(element => {
        if (element.value == filterValue){
          this.resources.filters = element.children;
          this.cdr.detectChanges();
        }
      });
    }
  }

  getCampaigns(){
    if (!this.resources.campaigns.length){
      this.profileService.getCampaignsResources()
        .subscribe((data) => {
          this.resources.campaigns = data;
        }
          ,
          (err) => {
            this.displayErrorMessage(err);
          });
    }
  }

  closePopContentFilters(){
    this.popContentFilters.close();
  }

  getUsers(group){

    if (!this.resources[group].length){

      this.profileService.getUsers("?group=" + group)
        .subscribe((data) => {
          this.resources[group] = data;
        });
    }
  }

  getTagName(character){
    if (character.length > 2 && character.includes('@')){
      this.indexOf = character.lastIndexOf('@');
      this.filteredUsers = [];
      this.resources.internal.forEach(el => {
        if (el.name.toLowerCase().startsWith(character.substr(this.indexOf + 1).toLowerCase())){
          this.filteredUsers.push(el);

        }
      });
      if (this.filteredUsers.length > 0){
        this.tagsDropDown.open();
      }
    } else {
      this.tagsDropDown.close();
      this.filteredUsers = [];
    }
  }

  chooseUser(user){
    this.filteredUsers = [];
    this.newComment = this.newComment.substring(0, this.indexOf);
    this.newComment += user.name + ' ';
    this.taggedUser.push({ 'uuid': user.uuid, 'name': user.name });
    this.tagsDropDown.close();
  }

  emitComment(comment, candidate_id){
    this.communicationService.emitComment(comment, candidate_id);
  }

  onCommentSave(description){
    this.disableSendBtn = true;
    if (this.taggedUser){
      this.taggedUser.forEach((el) => {
        if (el.name){
          const element = `<strong>@` + el.name + `</strong>`;
          if (!description.includes(element)){
            description = description.split(el.name).join(element);
          }
        }
      });
    }
    if (description){
      const users = [];
      if (this.taggedUser){
        this.taggedUser.forEach((el) => {
          if (el.uuid){
            users.push({ 'uuid': el.uuid });
          }
        });
      }
      const data = {
        candidate_uuid: this.candidate_uuid,
        description: description,
        job_uuid: this.job_uuid,
        users: users ? users : []
      };
      this.profileService.saveComment(data)
        .subscribe(
          (dataComment) => {
            this.emitComment(dataComment, data.candidate_uuid);
            this.comments.push(dataComment);
            this.newComment = null;
          }, (err) => {
            this.displayErrorMessage(err);
        });
      this.disableSendBtn = false;
      this.taggedUser = [];
    } else {
      this.disableSendBtn = false;
      this.taggedUser = [];
    }
  }

  editFields(type){
    this.edit[type] = !this.edit[type];
    this.preventDefault = true;
  }

  public beforeChange($event: NgbPanelChangeEvent){
    $event.preventDefault();
    this.preventDefault = false;
  }

  OnContentChange(){
    this.uploader.uploadAll();
  }

  OnContentChangeFile(){
    this.uploaderFile.uploadAll();
  }

  getGender(){
    if (!this.resources.gender.length){
      this.profileService.getResources('gender_type')
        .subscribe((data) => {
          this.resources.gender = data;
        });
    }
  }

  getSeniority(){
    if (!this.resources.seniority.length){
      this.profileService.getResources('role_seniority')
        .subscribe((data) => {
          this.resources.seniority = data;
        });
    }
  }

  getResourcesByType(value){
    if (!this.resources[value].length){
      this.profileService.getResources(value).subscribe((data) => {
        if (data.length){
          this.resources[value] = data;
        }
      });
    }
  }

  getIndustry(){
    if (!this.resources.industry.length){
      this.profileService.getIndustryResources()
        .subscribe((data) => {
          this.resources.industry = data;
        });
    }
  }

  getFe(){
    if (!this.resources.fe.length){
      this.profileService.getFeResources()
        .subscribe((data) => {
          this.resources.fe = data;
        });
    }
  }

  getSfe(){
    this.profileService.getSfeResources()
      .subscribe((data) => {
        this.resources.sfe = data;
      });
  }

  getFileType(){
    if (!this.resources.fileType.length){
      this.profileService.getResources('candidate_assets_type')
        .subscribe((data) => {
          this.resources.fileType = data;
        });
    }
  }

  getStageFocus(){
    if (!this.resources.stageFocus.length){
      this.profileService.getStageFocusResources()
        .subscribe((data) => {
          this.resources.stageFocus = data;
        });
    }
  }

  loadLocations(){
    this.locations$ = concat(
      of([]), // default items
      this.locationInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.locationLoading = true),
        switchMap(term => this.profileService.getLocations(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.locationLoading = false)
          ))
      )
    );
  }

  loadLanguage(){
    this.resources.language = concat(
      of([]),
      this.languageinput.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.languageLoading = true),
        switchMap(language =>
          this.profileService.getLanguageResources(language).pipe(
            catchError(() => of([])),
            tap(() => {
              this.languageLoading = false;
            })
          ))
      )
    );
  }

  loadNationality(){
    this.resources.nationality = concat(
      of([]),
      this.nationalityinput.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.nationalityLoading = true),
        switchMap(data =>
          this.profileService.getNationalityResources(data).pipe(
            catchError(() => of([])),
            tap(() => {
              this.nationalityLoading = false;
            })
          ))
      )
    );
  }

  loadSector(){
    this.resources.sectorFocus = concat(
      of([]),
      this.sectorinput.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.sectorLoading = true),
        switchMap(data =>
          this.profileService.getSectorFocusResources(data).pipe(
            catchError(() => of([])),
            tap(() => {
              this.sectorLoading = false;
            })
          ))
      )
    );
  }

  loadCountry(){
    this.resources.countryFocus = concat(
      of([]),
      this.countryinput.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.countryLoading = true),
        switchMap(data =>
          this.profileService.getCountryFocusResources(data).pipe(
            catchError(() => of([])),
            tap(() => {
              this.countryLoading = false;
            })
          ))
      )
    );
  }

  getLanguageLevel(){
    if (!this.resources.level.length){
      this.profileService.getLanguageLevelResources()
        .subscribe((data) => {
          this.resources.level = data;
        });
    }
  }

  editCurrentEmployer(item, experience, i, visible){
    this.editExperience('current_employer', experience.current_employer, experience.uuid, visible);
    if (item.target.checked){
      visible ? this.candidate.experiences.visible.splice(i, 1) : this.candidate.experiences.hidden.splice(i, 1);
      this.candidate.experiences.visible.unshift(experience);
      this.candidate.experiences.visible.forEach((element, index) => {
        if (element.current_employer && element.uuid != experience.uuid){
          element.current_employer = false;
          if (element.end_date){
            this.candidate.experiences.hidden.unshift(element);
            this.candidate.experiences.visible.splice(index, 1);
          }
        }
      });
      this.changeCandidateCurrentStatus(experience);
    } else {
      if (experience.end_date){
        this.candidate.experiences.hidden.unshift(experience);
        const itemIndex = this.candidate.experiences.visible.findIndex(exp => exp.uuid === experience.uuid);
        if (itemIndex !== -1){
          this.candidate.experiences.visible.splice(itemIndex, 1);
        }
      }
      this.changeCandidateCurrentStatus();
    }
  }

  editPrimaryCV(item, file){
    this.editFile('is_primary', file.is_primary, file.candidate_asset_uuid);
    if (item.target.checked){
      this.candidate.files.find(item => {
        if (item.uuid != file.uuid && item.is_primary == true){
          item.is_primary = false;
        }
      });
    }
  }

  changeCandidateCurrentStatus(experience?){
    this.candidate.current_job_title = experience ? experience.job_title : 'Currently unemployed';
    //this.editCandidate('current_job_title', experience ? experience.job_title : 'Currently unemployed', this.candidate.uuid);
    this.candidate.current_employer = experience ? experience.company_name : null;
    //this.editCandidate('current_employer', experience ? experience.company_name : null, this.candidate.uuid);
  }

  onUnselectFunctional(experience, edit, visible?){
    if (experience.fe.length){
      this.getFilteredSfe(experience.fe);
      experience.fe.forEach(fe => {
        experience.sfe = experience.sfe.filter((element) => fe.value == element.fe_value);
      });
    } else {
      experience.sfe = [];
    }
    if (edit){
      this.editExperience('sfe', experience.sfe, experience.uuid, visible);
    }

  }

  getFilteredSfe(fe){
    this.resources.filteredSfe = [];
    if (fe.length){
      fe.forEach((element) => {
        this.resources.sfe.forEach(obj => {
          if (obj.fe_value === element.value || obj.fe_value === null){
            this.resources.filteredSfe.push(obj);
          }
        });
      });
    }
    this.resources.filteredSfe = this.resources.filteredSfe.filter(
      (item, i, arr) => arr.findIndex(distinctItem => distinctItem.value && distinctItem.value === item.value) === i
    );
  }

  getSource(){
    if (!this.resources.source.length){
      this.profileService.getResources('source_type')
        .subscribe((data) => {
          this.resources.source = data;
        });
    }
  }

  getInitialSubStatuses(){
    if (!this.resources.subStatuses.length){
      this.profileService.getInitialSubStatuses().subscribe(
        (data) => {
          this.resources.initialSubStatuses = data;
        });
    }
  }

  getSubstatuses(){
    if (!this.resources.subStatuses.length){
      this.profileService.getSubStatuses().subscribe(
        (data) => {
          this.resources.subStatuses = data;
        });
    }
  }

  getJobs(type, candidate_uuid){
    if ((this.resources.jobs.type != type) || !this.resources.jobs.data.length){
      this.profileService.getCandidateJobsResources(type, candidate_uuid).subscribe(
        (data) => {
          this.resources.jobs.data = data;
        });
    }
    this.resources.jobs.type = type;
  }

  onChange(){
    this.changed = true;
  }

  editCandidate(key, value, uuid){
    const body = this.recreateJobObject(key, value);
    this.updateCandidate(body, uuid);
  }

  editVCExperience(experience, visible){
    const body = {
      is_investment: false,
      investment_country_focus: [],
      investment_sector_focus: [],
      investment_stage_focus: []
    };
    this.updateExperience(body, experience.uuid, visible);
  }

  editExperience(key, value, uuid, visible, i?, array?){
    if (key == 'start_date' || key == 'end_date'){
      if (key == 'start_date' && !value){
        if (array[i].end_date){
          array[i].end_date = null;
          //this.updateExperience({ "end_date": array[i].end_date }, uuid, visible)
        }
        if (array[i].current_employer){
          array[i].current_employer = false;
          //this.updateExperience({ "current_employer": false }, uuid, visible);
          this.changeCandidateCurrentStatus();
        }
      }
      if (key == 'end_date' && value && array[i].current_employer){
        //this.updateExperience({ "current_employer": false }, uuid, visible)
        array[i].current_employer = false;
        if (visible){
          this.changeCandidateCurrentStatus();
        }
      }
      value ? array[i][key] = new Date(value).getTime() / 1000 : array[i][key] = null;
    }
    const body = this.recreateJobObject(key, value);
    this.updateExperience(body, uuid, visible);
  }

  editEducation(key, value, uuid, visible, i?, array?){
    if (key == 'start_date' || key == 'end_date'){
      value ? array[i][key] = new Date(value).getTime() / 1000 : array[i][key] = null;
    }
    const body = this.recreateJobObject(key, value);
    this.updateEducation(body, uuid, visible);
  }

  editLanguage(key, value, uuid, visible){
    const body = this.recreateJobObject(key, value);
    this.updateLanguage(body, uuid, visible);
  }

  removeSpacesFileName(file){
    file.original_name = (file.name + file.original_name.substr(file.original_name.lastIndexOf('.'))).replace(/ /g, '');
    this.editFileName('original_name', file.original_name, file.uuid);
  }

  editFileName(key, value, uuid){
    const body = this.recreateJobObject(key, value);
    this.profileService.updateFileName(uuid, body)
      .subscribe(() => {
        this.toastr.success('Successfully updated file');
      },
        (err) => {
          this.displayErrorMessage(err);
        });
    this.changed = false;
  }

  editFile(key, value, asset_uuid){
    const body = this.recreateJobObject(key, value);
    this.updateFile(this.candidate_uuid, asset_uuid, body);
  }

  recreateJobObject(key, value){
    if (key.indexOf('.') > -1){
      const keyValue = key.substr(0, key.indexOf('.'));
      const obj = {};
      obj[keyValue] = {
        [key.split('.').pop()]: value
      };
      return obj;
    } else {
      return {
        [key]: value
      };
    }
  }

  editSubstatus(key, value, uuid){
    const body = this.recreateJobObject(key, value);
    this.updateSubstatus(body, uuid);
  }

  updateCandidate(body, uuid){
    this.profileService.updateCandidate(uuid, body)
      .subscribe(() => {
        this.toastr.success('Successfully updated field');
      },
        (err) => {
          this.displayErrorMessage(err);
        });
    this.changed = false;
  }

  updateExperience(body: any, uuid, visible){
    this.profileService.updateExperience(uuid, body)
      .subscribe((response: any) => {
        if (response.visible != visible){
          if (response.visible){
            response.current_employer ? this.candidate.experiences.visible.unshift(response.data) : this.candidate.experiences.visible.push(response.data);
            this.findDeleteItem(this.candidate.experiences.hidden, response.data.uuid);
          } else {
            this.candidate.experiences.hidden.unshift(response.data);
            this.findDeleteItem(this.candidate.experiences.visible, response.data.uuid);
          }
          if (body.end_date){
            response.visible ?
              this.candidate.experiences.visible.sort(function (a, b){
                return b.end_date - a.end_date;
              }) :
              this.candidate.experiences.hidden.sort(function (a, b){
                return b.end_date - a.end_date;
              });
          }
        }
        this.toastr.success('Successfully updated experience');
      },
        (err) => {
          this.displayErrorMessage(err);
        });
    this.changed = false;
  }

  findDeleteItem(array, uuid){
    const itemIndex = array.findIndex(exp => exp.uuid === uuid);
    if (itemIndex !== -1){
      array.splice(itemIndex, 1);
    }
  }

  updateEducation(body, uuid, visible){
    this.profileService.updateEducation(uuid, body)
      .subscribe((response: any) => {
        if (response.visible != visible){
          if (response.visible){
            this.candidate.educations.visible.unshift(response.data);
            this.findDeleteItem(this.candidate.educations.hidden, response.data.uuid);
          } else {
            this.candidate.educations.hidden.unshift(response.data);
            this.findDeleteItem(this.candidate.educations.visible, response.data.uuid);
            if (!this.candidate.educations.visible.length && this.candidate.educations.hidden.length > 0){
              this.candidate.educations.visible.push(this.candidate.educations.hidden[0]);
              this.candidate.educations.hidden.splice(0, 1);
            }
          }
          if (body.end_date){
            response.visible ?
              this.candidate.educations.visible.sort(function (a, b){
                return b.end_date - a.end_date;
              }) :
              this.candidate.educations.hidden.sort(function (a, b){
                return b.end_date - a.end_date;
              });
          }
        }
        this.toastr.success('Successfully updated education');
      },
        (err) => {
          this.displayErrorMessage(err);
        });
    this.changed = false;
  }

  updateLanguage(body, uuid, visible){
    this.profileService.updateLanguage(uuid, body)
      .subscribe((response: any) => {
        if (response.visible != visible){
          if (response.visible){
            this.candidate.languages.visible.unshift(response.data);
            this.findDeleteItem(this.candidate.languages.hidden, response.data.uuid);
          } else {
            this.candidate.languages.hidden.unshift(response.data);
            this.findDeleteItem(this.candidate.languages.visible, response.data.uuid);
            if (!this.candidate.languages.visible.length && this.candidate.languages.hidden.length > 0){
              this.candidate.languages.visible.push(this.candidate.languages.hidden[0]);
              this.candidate.languages.hidden.splice(0, 1);
            }
          }
          if (body.level){
            response.visible ?
              this.candidate.languages.visible.sort(function (a, b){
                return b.level.value_search - a.level.value_search;
              }) :
              this.candidate.languages.hidden.sort(function (a, b){
                return b.level.value_search - a.level.value_search;
              });

          }
        }
        this.toastr.success('Successfully updated education');
      },
        (err) => {
          this.displayErrorMessage(err);
        });
    this.changed = false;
  }

  updateFile(candidate_uuid, asset_uuid, body){
    this.profileService.updateFile(candidate_uuid, asset_uuid, body)
      .subscribe(() => {
        this.toastr.success('Successfully updated file');
      },
        (err) => {
          this.displayErrorMessage(err);
        });
    this.changed = false;
  }

  updateSubstatus(body, uuid){
    body.candidate_uuid = this.candidate.uuid;
    body.job_uuid = uuid;
    this.profileService.updateSubstatus(body)
      .subscribe((data: any) => {
        data.forEach(element => {
          this.toastr[element.tag](element.value + (element.action ? '\n <a target="_blank" href="' + element.action + '">' + element.action + '<a/>' : ''));
        });
      },
        (err) => {
          this.displayErrorMessage(err);
        });
    this.changed = false;
  }

  addForm(type){
    this.add[type] = !this.add[type];
    this.preventDefault = true;
  }

  addNewExperience(experience, uuid){
    experience.candidate_uuid = uuid;
    this.profileService.addExperience(experience).subscribe(
      (response) => {
        if (response.visible){
          if (response.data.current_employer){
            this.candidate.experiences.visible.unshift(response.data);
          } else {
            this.candidate.experiences.visible.push(response.data);
          }
        } else {
          this.candidate.experiences.hidden.push(response.data);
        }
        if (response.data.current_employer){
          this.changeCandidateCurrentStatus(response.data);
          this.candidate.experiences.visible.forEach((element) => {
            if (element.current_employer && element.uuid != response.data.uuid){
              element.current_employer = false;
            }
          });
        }
        this.toastr.success('Successfully added new experience');
        this.add.experienceForm = false;
        this.initExperience();
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  addNewEducation(education, uuid){
    education.candidate_uuid = uuid;
    this.profileService.addEducation(education).subscribe(
      (response) => {
        response.visible ? this.candidate.educations.visible.push(response.data) : this.candidate.educations.hidden.push(response.data);
        this.toastr.success('Successfully added new education');
        this.add.educationForm = false;
        this.initEducation();
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  addNewLanguage(language, uuid){
    language.candidate_uuid = uuid;
    this.profileService.addLanguage(language).subscribe(
      (response) => {
        response.visible ? this.candidate.languages.visible.push(response.data) : this.candidate.languages.hidden.push(response.data);
        this.toastr.success('Successfully added new language');
        this.initLanguage();
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  addNewFile(file, uuid){
    this.profileService.addFile(uuid, file).subscribe(
      (response) => {
        if (response.is_primary){
          this.candidate.files.find(item => {
            if (item.uuid != response.uuid && item.is_primary == true){
              item.is_primary = false;
            }
          });
        }
        this.candidate.files.push(response);
        this.toastr.success('Successfully added new file');
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  deleteExperience(uuid, i, array){
    this.profileService.deleteExperience(uuid).subscribe(
      () => {
        if (array[i].current_employer){
          this.changeCandidateCurrentStatus();
        }
        array.splice(i, 1);
        this.toastr.success('Successfully deleted experience');
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  deleteEducation(uuid, i, array){
    this.profileService.deleteEducation(uuid).subscribe(
      () => {
        array.splice(i, 1);
        if (!this.candidate.educations.visible.length && this.candidate.educations.hidden.length > 0){
          this.candidate.educations.visible.push(this.candidate.educations.hidden[0]);
          this.candidate.educations.hidden.splice(0, 1);
        }
        this.toastr.success('Successfully deleted experience');
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  deleteLanguage(uuid, i, array){
    this.profileService.deleteLanguage(uuid).subscribe(
      () => {
        array.splice(i, 1);
        if (!this.candidate.languages.visible.length && this.candidate.languages.hidden.length > 0){
          this.candidate.languages.visible.push(this.candidate.languages.hidden[0]);
          this.candidate.languages.hidden.splice(0, 1);
        }
        this.toastr.success('Successfully deleted experience');
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  deleteFile(candidate_asset_uuid, i){

    this.profileService.deleteFile(this.candidate_uuid, candidate_asset_uuid).subscribe(
      () => {
        this.candidate.files.splice(i, 1);
        this.toastr.success('Successfully deleted file');
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  addCandidateToJob(){
    this.addTojob.candidate_uuid = this.candidate_uuid;

    if (!'longlist' == this.addTojob.stage.value){
      this.sendApplicationDataToServer();
      return;
    }

    const confirmationMessage = this.generateConfirmationMessageForAddingToJob();
    if (confirmationMessage === ""){
      this.sendApplicationDataToServer();
      return;
    }

    const modalRef = this.modalService.open(GeneralConfirmationModalComponent, { size: 'sm', centered: true, backdrop: 'static' });
    modalRef.componentInstance.confirmationMessage = confirmationMessage;
    modalRef.result.then(() => {
      this.sendApplicationDataToServer();

    }, () => {
    });

  }

  generateConfirmationMessageForAddingToJob(){
    let message = '';

    if (this.checkIfCandidateTagExists('In Process')){
      message += this.candidate.first_name + " " + this.candidate.last_name + " is already in process for another job. <br>";
    }

    const blacklistedCompanyData = this.candidate.tags.find(t => t.description === "Blacklisted company");

    if (blacklistedCompanyData){
      message += this.candidate.first_name + " " + this.candidate.last_name + " current company " + blacklistedCompanyData.hover_data + "<br>";
    }

    const blacklistedCandidateData = this.candidate.tags.find(t => t.description === "Blacklisted candidate");

    if (blacklistedCandidateData){
      message += this.candidate.first_name + " " + this.candidate.last_name + " is " + blacklistedCandidateData.hover_data + "<br>";
    }

    if (message !== ""){
      message += "<br><b>Do you want to continue with your action?</b>";
    }

    return message;
  }

  sendApplicationDataToServer(){
    this.profileService.addToJob(this.addTojob).subscribe(
      (data) => {
        if (data.success && data.success.length > 0){
          data.success.forEach(element => {
            if (element.message){
              this.toastr.success(element.message);
            }
            if (element.body){
              this.candidate.job_history.visible.unshift(element.body);
            }
          });
          if (this.accessOrigin == 'recylce_email'){
            this.angulartics2.eventTrack.next({
              action: 'Candidate was succesfuly added to job',
              properties: {
                data: this.addTojob,
                access_origin: this.accessOrigin,
                category: 'recycle candidate'
              }
            });
          }
          this.createAddToJobMixPanelProperties();

        }
        if (data.error && data.error.length > 0){
          data.error.forEach(element => {
            this.toastr.error(element.message);
          });
        }
        if (data.warning && data.warning.length > 0){
          data.warning.forEach(element => {
            if (element.message){
              this.toastr.warning(element.message);
            }
            if (element.body){
              this.candidate.job_history.visible.unshift(element.body);
            }
          });
        }
        this.addTojob = {
          job: null,
          subStatus: null,
          stage: null
        };
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  createAddToJobMixPanelProperties(){
    //TODO: add mixpanel tracking for adding candidates to job that are not part of recommendation
    if (this.parentComponent == 'filter-candidate' && this.filterType == 'recommend'){
      this.insertMixPanelTracking({
        job_title: this.addTojob.job.job_title,
        total_number_of_candidates: 1,
        candidate_uuids: [this.addTojob.candidate_uuid],
        candidate_indexes: [this.activatedRoute.snapshot.queryParamMap.get('candidate_index')],
        stage: this.addTojob.stage ? this.addTojob.stage.description : null,
        substatus: this.addTojob.substatus ? this.addTojob.substatus.description : null,
        comment: this.addTojob.comment,
        candidate_rating: this.addTojob.candidate_rating ? this.addTojob.candidate_rating.description : null,
        parent: 'filter_candidates',
        origin: 'candidate_drawer'
      }, 'Recommended candidates added to position');
    }
  }

  insertMixPanelTracking(properties: any, action_title: string){
    this.angulartics2.eventTrack.next({
      action: action_title,
      properties: properties
    });
  }

  updateApplication(data){

    const modalRef = this.modalService.open(UpdateApplicationModalComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.applicationData = { ...data };
    modalRef.componentInstance.jobsInProcess = this.checkIfCandidateTagExists('In Process');
    modalRef.componentInstance.origin = 'view candidate';
    modalRef.result.then((response) => {
      this.candidate.job_history.visible = this.candidate.job_history.visible.map(item => ((item.uuid === response.uuid) ? response : item));

    }, () => {

    });
  }

  navigateToCIT(citUUID, jobUUID, jobTitle){
    if (citUUID && jobUUID){
      this.router.navigate(['/job/' + jobUUID + '/cit/' + citUUID]);
      this.communicationService.emitJobSelected({
        job_title: jobTitle
      });
    }
  }

  navidateToOverview(jobUUID, jobTitle){
    if (jobUUID){
      this.router.navigate(['/job/' + jobUUID + '/overview/']);
      this.communicationService.emitJobSelected({
        job_title: jobTitle
      });
    }
  }

  getContrastYIQ(hexcolor){
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  getCompensation(value){
    if (!this.resources[value].length){
      this.profileService.getCompensation().subscribe((data) => {
        this.resources[value] = data.filter(item => item.type === value);
      });
    }
  }

  chooseMergingCandidate(){
    const data = {
      primary_candidate_uuid: this.candidate.uuid
    };
    const modalRef = this.modalService.open(ChooseMergeCandidateModalComponent, { size: 'sm', centered: true, backdrop: 'static' });
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data.secondary_candidate_uuid){
        this.mergeCandidates(data);
      }
    }, () => {
    });
  }

  mergeCandidates(data){
    const modalRef = this.modalService.open(MergeCandidatesModalComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data){
        this.candidate = data;
        //replace url param without navigation
        this.location.replaceState(this.router.url.replace(this.activatedRoute.snapshot.queryParams.candidate_uuid, this.candidate.uuid));
      }
    }, () => {
    });
  }

  deleteCandidate(){
    const data = {
      uuid: this.candidate.uuid,
      reason: null,
      is_blacklisted: false
    };
    const modalRef = this.modalService.open(DeleteCandidateModalComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data){
        this.router.navigate([],
          {
            relativeTo: this.activatedRoute,
            queryParams: {}
          });
        const element = document.getElementById('candidate-drawer');
        element.style.visibility = 'hidden';
      }
    }, () => {
    });
  }

  blacklistCandidate(){
    const data = {
      uuid: this.candidate.uuid
    };
    const modalRef = this.modalService.open(BlacklistCandidateModalComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data){
        this.router.navigate([],
          {
            relativeTo: this.activatedRoute,
            queryParams: {}
          });
        const element = document.getElementById('candidate-drawer');
        element.style.visibility = 'hidden';
      }
    }, () => {
    });
  }

  showAddActivity(){
    this.candidateActivity.candidates = [];
    this.candidateActivity.candidates.push(this.candidate.uuid);
    this.addActivity = true;
  }

  addActivityToDB(){
    if (this.validateCandidateActivity()){
      if (!Array.isArray(this.candidateActivity.jobs)){
        const tmpJob = this.candidateActivity.jobs;
        this.candidateActivity.jobs = [];
        this.candidateActivity.jobs.push(tmpJob);
      }
      if (this.candidateActivity.data){
        const tmpData = this.candidateActivity.data;
        this.candidateActivity.data = {};
        this.candidateActivity.data.Description = tmpData;
      }
      this.profileService.addActivityToDB(this.candidateActivity).subscribe(
        () => {
          this.emptyForm();
          this.toastr.success('Successfully added activity');
        },
        (err) => {
          this.displayErrorMessage(err);
        });
      this.validForm = true;
      this.addActivity = false;
    } else {
      this.toastr.error('Please fill all mandatory fields');
    }
  }

  validateCandidateActivity(){
    if (!this.candidateActivity.type || !this.candidateActivity.data){
      this.validForm = false;
      return false;
    }
    this.validForm = true;
    return true;
  }

  cancelActivity(){
    this.emptyForm();
    this.validForm = true;
    this.addActivity = false;
  }

  getActivityTags(){

    this.profileService.getActivityTags().subscribe(
      (data) => {
        this.resources.activity_tags = data;
      });
  }

  emptyForm(){
    this.candidateActivity.type = null;
    this.candidateActivity.data = null;
    this.candidateActivity.candidates = [];
    this.candidateActivity.jobs = [];
  }

  getConsentType(){
    if (!this.resources.consent_type.length){
      this.profileService.getConsentType().subscribe((data) => {
        this.resources.consent_type = data.filter(item => item.type === "candidate_consent_type");
      });
    }
  }

  getObtainedType(){
    if (!this.resources.obtained_type.length){
      this.profileService.getObtainedType().subscribe((data) => {
        this.resources.obtained_type = data.filter(item => item);
      });
    }
  }

  removeOpenToRoles(){
    const body = {
      recycling_candidate: false
    };
    this.updateCandidate(body, this.candidate.uuid);
  }

  checkIfCandidateTagExists(description){
    return this.candidate.tags.some(t => t.description === description);
  }

  ratingCSS(job){
    return job.stage && job.stage.value == 'longlist' ? "form-group col-6" : "form-group col-12";
  }

  displayErrorMessage(error){
    if (error.error){
      for (const errProp in error.error){
        if (error.error.hasOwnProperty(errProp)){
          if (error.error[errProp][0]){
            this.toastr.error(error.error[errProp]);
          }
        }
      }
    } else {
      this.toastr.error('Something went wrong');
    }
  }

}
