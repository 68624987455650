import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ViewJobService } from './view-job.service';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { Subject, Observable, of, concat } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';
import { ClipboardService } from 'ngx-clipboard';
import { FileUploader } from 'ng2-file-upload';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { CampaignsMetricsModalComponent } from 'app/shared/platform-modals/campaigns-metrics-modal/campaigns-metrics-modal.component';

@Component({
  selector: 'app-view-job',
  templateUrl: './view-job.component.html',
  styleUrls: ['./view-job.component.scss']
})
export class ViewJobComponent implements OnInit, OnChanges{
  readonly minLengthTerm = 3;

  @Input() uuid: string;

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  subscription: any;

  job: any = this.initJobObject();

  readEditFlagScorecards = false;

  readEditFlagDetails = false;

  readEditFlagTeam = false;

  readEditFlagCompensation = false;

  jobDataIsDownloaded = false;

  companies$: Observable<any>;

  companyLoading = false;

  companyInput$ = new Subject<string>();

  locations$: Observable<any>;

  locationLoading = false;

  locationInput$ = new Subject<string>();

  jobUUID = '';

  filteredContact: any = [];

  jobType: any = [];

  statuses: any = [];

  industries: any = [];

  seniorities: any = [];

  currencies: any = [];

  bonusTypes: any = [];

  pods: any = [];

  feeType: any = [];

  priorities: any = [];

  activeId: string[] = ['details', 'team', 'compensation', 'scorecards'];

  filesActiveId: string[] = ['files'];

  huntingGroundsActiveId: string[] = [];

  companyAvatarImg: any = this.globals.companyAvatar;

  preventDefault = false;

  functionalExpertise: any = [];

  subFunctionalExpertise: any = [];

  searchSources: any = [];

  opportunities: any = [];

  users: any = [];

  roles: any = [];

  stageRate = 2.5;

  items: any = [];

  tmpUser: any = {
    user: null,
    role: null
  };

  activities: any = [];

  huntingGrounds: any = [];

  resources: any = {
    gender: [],
    fe: [],
    sfe: [],
    industry: [],
    seniority: [],
    source: [],
    filteredSfe: [],
    language: [],
    level: [],
    fileType: [],
    subStatuses: [],
    jobs: []
  };

  add: any = {
    file: {
      uuid: null,
      url: null,
      original_name: '',
      type: []
    }
  };

  edit: any = {
    basic: false,
    experience: false,
    education: false,
    language: false,
    miscellaneous: false,
    files: false,
    jobs: false
  };

  addFileFlag = false;

  editFileFlag = true;

  email: string = null;

  filteredSfe: any = [];

  validForm = false;

  tempVars = {
    tempFrom: 0,
    tempTo: 0,
    tempAnnual: 0,
    tempFee: 0,
    tempJobTitle: '',
    tempJobCompany: '',
    tempJobContact: '',
    tempJobType: ''
  };

  closingData: any = {
    status: null,
    reason: null,
    median_salary: null,
    hunting_grounds: null,
    talent_pool: null,
    top_candidates: null,
    candidate_management: null,
    client_management: null,
    avoid_client: null,
    bd_opportunities: null,
    bd_opportunities_reason: null,
    other_learning: null,
    unsuccessful_reasons: null
  };

  closeJobPlaceholders: any = {
    hunting_grounds: "What were the hunting grounds you used? For each, outline the reasons for using the HG and how successful was sourcing from that HG. From which HG did the candidate that was hired came from?",
    talent_pool: "How big is the talent pool? Which of the client requirements were the biggest constrain on the size on the talent pool? What was the biggest bottleneck when sourcing candidates?",
    top_candidates: "Which of the candidates that were not placed are really strong and we should try to lob them elsewhere? Which of the candidates are actively looking for a new role? Add links to the candidate profiles in Navis",
    candidate_management: "What was the reach out strategy to Candidates? What worked, and what did not? From what companies were people most responsive/interested?",
    client_management: "How was the experience working with the client? Are there any companies they prefer to hire from? What was the biggest bottleneck when dealing with this client? If we should avoid working with this client in the future, why? ",
    other_learning: "Was there any other learning from this search? Any internal process that you would want improved?",
    unsuccessful_reasons: "Why was the search not succesfull?",
    bd_opportunities_reason: "What leads are there and with whom?"
  };

  closeJobTitles: any = {
    median_salary: "Median Annual salary of Introduced Candidates in Euros (including bonus)",
    hunting_grounds: "Hunting Grounds",
    talent_pool: "Talent Pool",
    top_candidates: "Top Candidates",
    candidate_management: "Candidate Management",
    client_management: "Client Management",
    avoid_client: "Should we avoid working with this client in the future?",
    bd_opportunities: "Are there any further BD opportunities out of this search?",
    bd_opportunities_reason: "If yes, what opportunities are there?",
    other_learning: "Other Learnings/Information",
    unsuccessful_reasons: "Reasons behind unsuccessful search"
  };

  selectedAffinityOpportunity = null;

  status: any = [];

  reason: any = [];

  job_uuid = '';

  closeJobStatusFlag = false;

  closeJobReasonFlag = false;

  reopenJobData = {
    reason: null,
    reopen_until_date: null
  };

  openJobLengthFlag = true;

  reOpenJobLengthFlag = true;

  closeJobFlag = false;

  reopenJobFlag = false;

  editJobFlag = true;

  currentStageRating: any;

  jobTitles: any = [];

  addTeamFlag = false;

  editTeamFlag = false;

  addScoreCardFlag = false;

  addScorecard: string = null;

  public reopenJobDate: Date;

  public hasBaseDropZoneOver;

  public uploaderFile: FileUploader;

  public addTagCompany: (name) => void;

  public addTagContact: (name) => void;

  constructor(private router: Router, private viewJobService: ViewJobService,
    private route: ActivatedRoute, public toastr: ToastrService, public globals: Globals, private modalService: NgbModal,
    private clipboardService: ClipboardService, private cdr: ChangeDetectorRef){
    const queryParams = this.route.snapshot.queryParams;
    this.jobUUID = queryParams.job_uuid;
    this.addTagCompany = (name) => this.addCompany(name);
    this.addTagContact = (name) => this.addContact(name);
    const URL = environment.platformBackendURL + '/assets';
    this.uploaderFile = new FileUploader({
      url: URL,
      isHTML5: true,
      method: 'POST',
      itemAlias: 'file',
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });
    this.uploaderFile.onAfterAddingFile = (file) => {
      file.file.name = this.generateFileName(this.job.company.name, this.job.job_title, file, this.add.file.type.description);
    };
    this.uploaderFile.onCompleteItem = (item: any, response: any, status: any) => {
      const obj = JSON.parse(response);
      if (status === 201){
        this.add.file.uuid = obj.uuid;
        this.add.file.url = obj.file_url;
        this.addNewFile(this.add.file, this.jobUUID);
      } else {
        this.toastr.error(obj.message);

      }
      this.hasBaseDropZoneOver = false;
    };
    this.uploaderFile.uploadAll();
  }

  addNewFile(file, uuid){
    const body = {
      "asset_uuid": file.uuid,
      "type": {
        "value": file.type.value,
        "description": file.type.description
      }
    };
    this.viewJobService.addFile(uuid, body).subscribe(
      (response) => {
        this.job.files.push(response);
        this.toastr.success('Successfully added new file');
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp][0]){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });
  }

  deleteFile(uuid, i, array){
    this.viewJobService.deleteFile(uuid, this.jobUUID).subscribe(
      () => {
        array.splice(i, 1);
        this.toastr.success('Successfully deleted file');
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp][0]){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });
  }

  getFileType(){
    if (!this.resources.fileType.length){
      this.viewJobService.getResoursesData('job_files_type')
        .subscribe((data) => {
          this.resources.fileType = data;
        });
    }
  }

  OnContentChangeFile(){
    this.uploaderFile.uploadAll();
  }

  ngOnInit(){
    this.takeDataForJob(this.jobUUID);
    this.loadCompanies();
    // this.loadLocations()
    this.initReopenJobdata();
    this.huntingGrounds = [];
    this.getHountingGroundData();
    this.loadOpportunities();
  }

  ngOnChanges(){
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (this.uuid){
        if (this.jobUUID !== this.uuid){
          this.jobUUID = this.uuid;
          this.huntingGrounds = [];
          this.activities = [];
          this.takeDataForJob(this.jobUUID);
          this.getActivity();
          this.huntingGrounds = [];
          this.getHountingGroundData();
        }
      }
    });
  }

  getJobCampaignsMetrics(uuid){
    this.viewJobService.getJobMetrics(uuid)
      .subscribe((data) => {
        this.job.metrics = data;

      },
        (err) => {
          this.toastr.error(err);
        });
  }

  openMetricsModal(){
    const modalRef = this.modalService.open(CampaignsMetricsModalComponent, { size: 'md' as 'lg', centered: true });
    modalRef.componentInstance.metricsData = this.job.metrics;
    modalRef.componentInstance.uuid = null;
    modalRef.result.then(() => {
    }, () => {
    });
  }

  public beforeChange($event: NgbPanelChangeEvent){
    $event.preventDefault();
    this.preventDefault = false;
  }

  prepopulateDataForDropDowns(){
    if (this.job.fe.length){
      this.job.fe.forEach(element => {
        this.job.fe = element;
      });
    } else {
      this.job.fe = null;
    }
    if (this.job.sfe.length){
      this.job.sfe.forEach(element => {
        this.job.sfe = element;
      });
    } else {
      this.job.sfe = null;
    }
    if (this.job.industry.length){
      this.job.industry.forEach(element => {
        this.job.industry = element;
      });
    } else {
      this.job.industry = null;
    }
  }

  mapAffinityOpportunityDisplay(){
    if (!this.selectedAffinityOpportunity || !this.selectedAffinityOpportunity.id){
      this.selectedAffinityOpportunity = null;
      return;
    }
    this.opportunities.forEach(element => {
      if (element.id == this.selectedAffinityOpportunity.id){
        this.selectedAffinityOpportunity.name = element.name;
        return;
      }
    });
  }

  takeDataForJob(jobUUID){
    this.viewJobService.getResources(jobUUID)
      .subscribe((data) => {
        this.initReopenJobdata();
        this.job = data;
        this.getJobCampaignsMetrics(jobUUID);
        this.editJobFlag = true;
        this.closeJobFlag = false;
        this.reopenJobFlag = false;
        if (this.job.fe){
          this.filterSfe(this.job.fe);
        }
        this.jobDataIsDownloaded = true;
        this.prepopulateDataForDropDowns();
        this.prepopulateStageRate(this.job.current_stage);
        this.selectedAffinityOpportunity = { 'id': this.job.affinity_opportunity_id };
        this.mapAffinityOpportunityDisplay();
        this.items = this.job.current_stage;
        if (this.job){
          this.tempVars.tempJobTitle = this.job.job_title;
          this.tempVars.tempJobCompany = this.job.company;
          this.tempVars.tempJobContact = this.job.contact;
          this.tempVars.tempJobType = this.job.type;
          this.tempVars.tempFrom = this.job.jobCompensation.salary_from ? this.job.jobCompensation.salary_from : 0;
          this.tempVars.tempTo = this.job.jobCompensation.salary_to ? this.job.jobCompensation.salary_to : 0;
          this.tempVars.tempAnnual = this.job.jobCompensation.annual_salary ? this.job.jobCompensation.annual_salary : 0;
          this.tempVars.tempFee = this.job.jobCompensation.fee ? this.job.jobCompensation.fee : 0;
        }
      });
    this.loadCompanies();
    this.loadLocations();
  }

  prepopulateStageRate(currentStage){
    this.jobTitles = [];
    let tempRate = 0;
    currentStage.reverse();
    currentStage.forEach(element => {
      if (element.status == true){
        tempRate++;
      }
      this.jobTitles.push(element.description);
    });
    this.stageRate = tempRate;
  }

  OnDestroy(){
    this.subscription.unsubscribe();
  }

  initJobObject(){
    return {
      job_title: null,
      company: null,
      contact: null,
      description: null,
      type: null,
      status: null,
      priority: null,
      location: null,
      search_source: null,
      affinity_opportunity_id: null,
      projected_length: null,
      head_count: null,
      fe: null,
      sfe: null,
      industry: null,
      seniority: null,
      jobCompensation: {
        currency: null,
        salary_from: null,
        salary_to: null,
        annual_salary: null,
        bonus: null,
        bonus_type: null,
        equity: null,
        fee: null,
        fee_type: null
      },
      users: [],
      files: [],
      scorecards: [],
      pod: null
    };
  }

  loadOpportunities(){
    this.viewJobService.getAffinityOpportunities().subscribe((data) => {
      this.opportunities = data.opportunities;
    });
  }

  loadCompanies(){
    this.companies$ = concat(
      of([]), // default items
      this.companyInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.companyLoading = true),
        switchMap(term => this.viewJobService.getCompany(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.companyLoading = false)
          ))
      )
    );
  }

  loadLocations(){
    this.locations$ = concat(
      of([]), // default items
      this.locationInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.locationLoading = true),
        switchMap(term => this.viewJobService.getLocations(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.locationLoading = false)
          ))
      )
    );
  }

  // loadLocations()
  // {
  //   let minLength = 3;
  //   this.locations = concat(
  //     of([]), // default items
  //     this.locationInput.pipe(
  //       debounceTime(200),
  //       distinctUntilChanged(),
  //       tap(input => input && input.length >= minLength ? this.locationLoading = true : this.locationLoading = false),
  //       filter(input => input && input.length >= minLength)),
  //       switchMapTo(candidate => this.viewJobService.getLocations(candidate).pipe(
  //         catchError(() => of([])), // empty list on error
  //         tap(() => this.locationLoading = false)
  //       ))
  //   );
  // }

  filterContacts(company){
    this.viewJobService.getContacts(company)
      .subscribe((data) => {
        data ? this.filteredContact = data : this.filteredContact = [];
      });
  }

  getJobType(){
    if (this.jobType.length === 0){
      this.viewJobService.getResoursesData('job_type')
        .subscribe((data) => {
          this.jobType = data;
        });
    }
  }

  getSearchSources(){
    if (!this.searchSources.length){
      this.viewJobService.getResoursesData('search_sources')
        .subscribe((data) => {
          this.searchSources = data;
        });
    }
  }

  getStatus(){
    if (this.statuses.length === 0){
      this.viewJobService.getResoursesData('job_statuses')
        .subscribe((val) => {
          this.statuses = val;
        });
    }
  }

  getIndustry(){
    if (this.industries.length === 0){
      this.viewJobService.getIndustry()
        .subscribe((val) => {
          if (val){
            this.industries = val;
          }
        });
    }
  }

  getSeniority(){
    if (this.seniorities.length === 0){
      this.viewJobService.getResoursesData('role_seniority')
        .subscribe((val) => {
          this.seniorities = val;
        });
    }
  }

  getCurrency(){
    if (this.currencies.length === 0){
      this.viewJobService.getResoursesData('currencies')
        .subscribe((val) => {
          this.currencies = val;
        });
    }
  }

  getbonusTypes(){
    if (this.bonusTypes.length === 0){
      this.viewJobService.getResoursesData('bonus_type')
        .subscribe((val) => {
          this.bonusTypes = val;
        });
    }
  }

  getFeeType(){
    if (this.feeType.length === 0){
      this.viewJobService.getResoursesData('fee_type')
        .subscribe((val) => {
          this.feeType = val;
        });
    }

  }

  clearSfe(){
    this.job.sfe = null;
  }

  filterSfe(value){
    this.getSfe();
    const tempFilteredSfe = [];
    this.subFunctionalExpertise.forEach(element => {
      if (element.fe_value == value.value){
        tempFilteredSfe.push(element);
      }
    });
    this.filteredSfe = tempFilteredSfe;
  }

  getPriority(){
    if (this.priorities.length === 0){
      this.viewJobService.getResoursesData('job_priorities')
        .subscribe((val) => {
          this.priorities = val;
        });
    }
  }

  getFee(){
    if (this.functionalExpertise.length === 0){
      this.viewJobService.getFunctionalExpertise()
        .subscribe((val) => {
          this.functionalExpertise = val;
        });
    }
  }

  getSfe(){
    if (this.subFunctionalExpertise.length === 0){
      this.viewJobService.getSubFunctionalExpertise()
        .subscribe((data) => {
          this.subFunctionalExpertise = data;
        });
    }
  }

  getPods(){
    if (this.pods.length === 0){
      this.viewJobService.getPods()
        .subscribe((data) => {
          this.pods = data;
        });
    }
  }

  getInternalUsers(){
    if (this.users.length === 0){
      this.viewJobService.getInternalUsers()
        .subscribe((data) => {
          this.users = data;
        });
    }
  }

  getRoles(){
    if (this.roles.length === 0){
      this.viewJobService.getRole()
        .subscribe((data) => {
          this.roles = data;
        });
    }

  }

  changeFlagScorecards(){
    this.readEditFlagScorecards = !this.readEditFlagScorecards;
    this.preventDefault = true;
  }

  changeFlagCompensation(){
    this.readEditFlagCompensation = !this.readEditFlagCompensation;
    this.preventDefault = true;
  }

  changeTeam(){
    this.readEditFlagTeam = !this.readEditFlagTeam;
    this.preventDefault = true;
  }

  changeDetails(){
    this.readEditFlagDetails = !this.readEditFlagDetails;
    this.preventDefault = true;
  }

  recreateJobObject(key, value){
    if (key.indexOf('.') > -1){
      const keyValue = key.substr(0, key.indexOf('.'));
      const obj = {};
      obj[keyValue] = {
        [key.split('.').pop()]: value
      };
      return obj;
    } else {
      return {
        [key]: value
      };
    }
  }

  editJob(key, value){
    const body = this.recreateJobObject(key, value);
    let validateMandatoryFields = true;
    switch (key){
      case 'jobCompensation.fee_type': {
        if (!value){
          validateMandatoryFields = false;
          this.toastr.warning("Fee type is mandatory.");
        }
        break;
      }
      case 'jobCompensation.fee': {
        if (!value){
          validateMandatoryFields = false;
          this.toastr.warning("Fee is mandatory.");
          this.job.jobCompensation.fee = this.tempVars.tempFee;
        } else {
          this.tempVars.tempFee = value;
        }
        break;
      }
      case 'job_title': {
        if (!value){
          validateMandatoryFields = false;
          this.toastr.warning("Job title is mandatory.");
          this.job.job_title = this.tempVars.tempJobTitle;
        } else {
          this.tempVars.tempJobTitle = value;
        }
        break;
      }
      case 'contact': {
        if (!value){
          validateMandatoryFields = false;
          this.toastr.warning("Contact is mandatory.");
          this.job.contact = this.tempVars.tempJobContact;
        } else {
          this.tempVars.tempJobContact = value;
        }
        break;
      }
      case 'company': {
        if (!value){
          validateMandatoryFields = false;
          this.toastr.warning("Company is mandatory.");
          this.job.company = this.tempVars.tempJobCompany;
        } else {
          this.tempVars.tempJobCompany = value;
        }
        break;
      }
      case 'jobCompensation.currency': {
        if (!value){
          validateMandatoryFields = false;
          this.toastr.warning("Currency is mandatory.");
        }
        break;
      }
      case 'type': {
        if (!value){
          validateMandatoryFields = false;
          this.toastr.warning("Type is mandatory.");
          this.job.type = this.tempVars.tempJobType;
        } else {
          this.job.type = value;
        }
        break;
      }
      case 'jobCompensation.salary_from': {
        if (!value){
          if (!this.job.jobCompensation.annual_salary && !this.job.jobCompensation.salary_to){
            validateMandatoryFields = false;
            this.job.jobCompensation.salary_from = this.tempVars.tempFrom;
            this.toastr.warning("Salary from is mandatory.");
          }
        } else {
          this.tempVars.tempFrom = value;
        }
        break;
      }
      case 'jobCompensation.salary_to': {
        if (!value){
          if (!this.job.jobCompensation.annual_salary && !this.job.jobCompensation.salary_from){
            validateMandatoryFields = false;
            this.job.jobCompensation.salary_to = this.tempVars.tempTo;
            this.toastr.warning("Salary to is mandatory.");
          }
        } else {
          this.tempVars.tempTo = value;
        }
        break;
      }
      case 'jobCompensation.annual_salary': {
        if (!value){
          if (!this.job.jobCompensation.salary_to && !this.job.jobCompensation.salary_from){
            validateMandatoryFields = false;
            this.job.jobCompensation.annual_salary = this.tempVars.tempAnnual;
            this.toastr.warning("Annual salary is mandatory.");
          }
        } else {
          this.tempVars.tempAnnual = value;
        }
        break;
      }
      case 'affinity_opportunity_id': {
        if (body['affinity_opportunity_id']){
          body['affinity_opportunity_id'] = body['affinity_opportunity_id'].id.toString();
        }
        this.mapAffinityOpportunityDisplay();
        break;
      }
      default: {}
    }

    if (validateMandatoryFields){
      this.updateJob(body);
    }
  }

  editScorecards(key, value){
    const body = this.recreateJobObject(key, value);
    this.updateJob(body);
  }

  showCloseJobView(){
    this.closeJobFlag = !this.closeJobFlag;
    this.editJobFlag = !this.editJobFlag;
    this.getMedianSalary();
  }

  getMedianSalary(){
    if (this.closingData.median_salary === null){
      this.viewJobService.getMedianSalary(this.uuid)
        .subscribe((data) => {
          if ("median_salary" in data){
            this.closingData.median_salary = data.median_salary;
          }
        },
          (err) => {
            if (err.error){
              for (const errProp in err.error){
                if (err.error.hasOwnProperty(errProp)){
                  if (err.error[errProp][0]){
                    this.toastr.error(err.error[errProp]);
                  }
                }
              }
            } else {
              this.toastr.error('Something went wrong');
            }
          });
    }
  }

  updateJob(body){
    this.viewJobService.updateJob(this.uuid, body)
      .subscribe(() => {
        this.toastr.success('Successfully updated field');
      },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        });
  }

  addNewScorecard(){
    if (!this.addScorecard){
      this.toastr.warning('Can not add empty scorecard');
    } else {
      this.job.scorecards.push({
        "skill": this.addScorecard
      });
      this.editScorecards("scorecards", this.job.scorecards);
      this.addScorecard = null;

    }
  }

  deleteScorecard(key, value, i){
    this.job.scorecards.splice(i, 1);
    if (key && value){
      this.editScorecards(key, value);
    }
  }

  addUser(){
    if (this.tmpUser.user){
      if (this.tmpUser.role){
        this.tmpUser.user.roles = this.tmpUser.role;
        this.job.users.push(JSON.parse(JSON.stringify(this.tmpUser.user)));
        this.editJob('users', this.tmpUser.user);
        this.tmpUser = {
          role: null,
          user: null
        };
      } else {
        this.toastr.warning('Please select role');
      }
    } else {
      this.toastr.warning('Please select user');
    }
  }

  deleteUser(i, uuid){
    this.viewJobService.deleteUser(this.jobUUID, uuid)
      .subscribe(() => {
        this.toastr.success('Successfully deleted user');
        this.job.users.splice(i, 1);
      },
        (err) => {
          this.toastr.error(err.message);
        });
  }

  deleteRole(role, user){
    this.viewJobService.deleteRole(this.jobUUID, user.id, role.uuid)
      .subscribe(() => {
        this.toastr.success('Successfully deleted role');
        this.job.users.forEach((tempUser, index) => {
          if (tempUser.roles.length > 0){
            for (let i = 0; i < tempUser.roles.length; i++){
              const obj = tempUser.roles[i];
              if (obj.uuid == role.uuid && tempUser.name == user.name){
                user.roles.splice(i, 1);

              }
            }
          }
          if (tempUser.roles.length == 0){
            this.job.users.splice(index, 1);
          }
        });
      }),
      (err) => {
        this.toastr.error(err.message);
      };
  }

  getHountingGroundData(){
      this.viewJobService.getHuntingGroundData(this.jobUUID)
        .subscribe((data) => {
          this.huntingGrounds = data;
          if (this.huntingGrounds.length <= 1){
            this.huntingGrounds.forEach((element, i) => {
              element.tags.forEach(tag => {
                this.huntingGroundsActiveId.push(tag.key + '-' + i);
              });

            });
          }
          this.cdr.detectChanges();
        });
  }

  getActivity(){
    if (this.activities.length == 0){
      this.viewJobService.getActivity(this.jobUUID)
        .subscribe((data) => {
          this.activities = data;
        });
    }
  }

  editHountinrgGround(uuid){
    this.router.navigate(['/companies'], { queryParams: { search_uuid: uuid, job_uuid: this.jobUUID } });
  }

  deleteHuntingGroun(hguuid){
    this.viewJobService.deletehuntingGround(hguuid, this.jobUUID)
      .subscribe(() => {
        this.toastr.success('Successfully deleted hunting ground');
        for (let i = 0; i < this.huntingGrounds.length; i++){
          if (this.huntingGrounds[i].uuid == hguuid){
            this.huntingGrounds.splice(i, 1);
          }
        }
      },
        (err) => {
          this.toastr.error(err.message);
        });
  }

  copyToClipboard(huntingGround){
    this.clipboardService.copyFromContent(huntingGround.companies_boolean);
    this.toastr.success('Copied to clipboard');
  }

  toogleAddFileFlag(){
    this.addFileFlag = !this.addFileFlag;
    this.preventDefault = true;
  }

  toogleEditFileFlag(){
    this.editFileFlag = !this.editFileFlag;
    this.preventDefault = true;
  }

  editFile(file){
    const body =
    {
      "type": {
        "value": file.type.value,
        "description": file.type.description
      }
    };
    this.viewJobService.editFile(this.jobUUID, file.uuid, body)
      .subscribe(() => {
        this.toastr.success('Successfully updated file');
      }),
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp][0]){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      };
  }

  updateFile(body, uuid){
    this.viewJobService.updateFile(uuid, body)
      .subscribe(() => {
        this.toastr.success('Successfully updated file');
      },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        });
  }

  onChange(){
  }

  removeSpacesFileName(file){
    file.original_name = (file.name + file.original_name.substr(file.original_name.lastIndexOf('.'))).replace(/ /g, '');
    if (file.original_name == '.pptx' || file.original_name == '.doc' || file.original_name == '.pdf'){
      this.toastr.error('Name of file can not be empty');
    } else {
      const body = {
        'original_name': file.original_name
      };
      this.viewJobService.updateFile(file.uuid, body)
        .subscribe(() => {
          this.toastr.warning('Successfully updated file name');
        });
    }
  }

  addCompany(company){
    return new Promise((resolve) => {
      this.viewJobService.addCompany(company)
        .subscribe((response) => {
          resolve({ uuid: response.uuid, name: company, valid: true });
          this.companyLoading = false;
          this.toastr.success('Successfully added company');
        },
          (err) => {
            this.toastr.error(err.message);
          });
    });
  }

  addContact(name){
    return new Promise((resolve) => {
      this.viewJobService.addContact(this.job.company.uuid, name, this.email)
        .subscribe((response) => {
          resolve({ uuid: response.uuid, name: name, valid: true });
          this.toastr.success('Successfully added contact');
          this.email = null;
        },
          (err) => {
            if (err.error){
              for (const errProp in err.error){
                if (err.error.hasOwnProperty(errProp)){
                  if (err.error[errProp][0]){
                    this.toastr.error(err.error[errProp]);
                  }
                }
              }
            } else {
              this.toastr.error('Something went wrong');
            }
          }
        );
    });
  }

  prevent($event){
    $event.stopPropagation();
  }

  checkEmptyScorecard(key, value, skill, i){
    if (skill.length === 0){
      this.deleteScorecard(key, value, i);
    }
  }

  clearContact(){
    this.job.contact = null;
  }

  takeUserRoles(roles){
    let tempRoles = [];
    if (roles.length > 1){
      roles.forEach(element => {
        tempRoles.push(element.name);
      });
    } else {
      tempRoles = roles[0].name;
    }
    return tempRoles;
  }

  getResources(){
    if (!this.currencies.length || !this.status.length){
      this.viewJobService.getResoursesData('closing_role_type,currencies')
        .subscribe((data) => {
          this.status = data.filter((el) => el.type == 'closing_role_type');
          this.currencies = data.filter((el) => el.type == 'currencies');
        },
          (err) => {
            this.toastr.error(err.message);
          });
    }
  }

  getReason(){
    this.reason = this.closingData.status.children;
  }

  checkRequiredFields(){
    if (this.closingData.reason && this.closingData.status){
      this.validForm = true;
    } else if (!this.closingData.reason){
      this.validForm = false;
      this.toastr.warning("Please fill all required fields");
    } else if (this.closingData.status){
      this.validForm = false;
      this.toastr.warning("Please fill all required fields");
    }
  }

  closeJob(){
    this.checkRequiredFields();
    if (this.validForm){
      if (this.closingData.bd_opportunities === false){
        this.closingData.bd_opportunities_reason = null;
      }
      this.viewJobService.closeJob(this.jobUUID, this.closingData)
        .subscribe(() => {
          this.toastr.success('Successfully closed job');
          this.job.state = "Closed";
          this.editJobFlag = true;
          this.closeJobFlag = false;
        }, (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        }
        );
    }
  }

  initReopenJobdata(){
    this.reopenJobData = {
      reason: null,
      reopen_until_date: null
    };
    this.reason = null;
    this.status = null;
  }

  reopenJob(){
    const reopenJobDate = this.reopenJobData.reopen_until_date ? this.reopenJobData.reopen_until_date.year + '-' + this.reopenJobData.reopen_until_date.month + '-' + this.reopenJobData.reopen_until_date.day : null;
    this.reopenJobData.reopen_until_date = reopenJobDate;
    this.viewJobService.reopenJob(this.reopenJobData, this.jobUUID)
      .subscribe(() => {
        this.toastr.success("Successfully reopen job");
        this.job.state = "Open";
        this.reopenJobFlag = false;
        this.editJobFlag = true;

      }, (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp][0]){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });
  }

  preventMaxLegthAdditionalInfoRopenJob(){
    if (this.reopenJobData.reason.length >= 191){
      this.toastr.warning("Maximum length for additional info is 191");
      this.reOpenJobLengthFlag = false;
    }
  }

  preventMaxLengthAdditionalInfoCloseJob(){
    if (this.closingData.additional_info.length >= 191){
      this.toastr.warning("Maximum length for additional info is 191");
      this.openJobLengthFlag = false;
    }
  }

  changeEditTeamFlag(){
    this.editTeamFlag = !this.editTeamFlag;
    this.preventDefault = true;
  }

  changeAddTeamFlag(){
    this.addTeamFlag = !this.addTeamFlag;
    this.preventDefault = true;
  }

  changeAddScorecars(){
    this.addScoreCardFlag = !this.addScoreCardFlag;
    this.preventDefault = true;
  }

  generateFileName(client: String, role: String, file, documentType: String){
    let name: string;

    const currentDay = moment(new Date()).format("YYMMDD");
    name = currentDay;
    if (!documentType.includes('Pitch')){
      name += "_" + client + "_" + role + "_" + documentType;
    } else {
      const presentationTitle = file.file.name.substr(0, file.file.name.lastIndexOf('.'));
      name += "_TBS_" + client + "_" + presentationTitle;
    }
    if (file && file.file && file.file.name){
      name += file.file.name.substr(file.file.name.lastIndexOf('.'));
    }
    name = name.replace(/ /g, '');
    return encodeURIComponent(name);
  }

  preventNegativeNumbers(e: any){
    if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode == 8)){
      return false;
    }
    return true;
  }

  public fileOverBaseFile(e: any){
    this.hasBaseDropZoneOver = e;
    this.uploaderFile.uploadAll();
  }

}
