<ng-select
  bindLabel="description"
  (open)="getCandidateRating()"
  [(ngModel)]="candidateRating"
  [ngModelOptions]="{standalone: true}"
  placeholder="candidate rating"
  [items]="candidateRatingOptions"
  [clearable]="true"
  (change)="changeRating()">
</ng-select>
