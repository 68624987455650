<div class="card m-0 scroll height-250">
    <div class="card-block">
      <div class="card-body">
        <div *ngIf="consent">
            <div class="card-body-title-basic">
                <div class="form-group">
                Consent {{consent.obtained_at ? '(' + consent.obtained_at + ')': ''}}
                </div>
            </div>
            <div class="card-body-content">
              <div class="row card-block">
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Obtained through type
                      </label>
                    </div>
                    <div class="card-body-content">
                      {{consent.obtained_through_type ? consent.obtained_through_type.description: 'No information available' }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Type
                      </label>
                    </div>
                    <div class="card-body-content">
                      {{consent.type ? consent.type.description : 'No information available'}}
                    </div>
                  </div>
                </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Note
                        </label>
                      </div>
                      <div class="card-body-content" [innerHTML]="consent.note ? consent.note : 'No information available'">
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>