<div class="row mobile-scroll scroll " *ngIf="masterCandidates">
  <div class="col-lg-3 col-12 candidate-sidebar  border-sidebar-right"
    [ngClass]="innerWidth<=990 ? '' : 'full-height-vh'">
    <div class="row">
      <div class="col-12">
        <div class="card" [ngClass]="{'scroll scroll-details':innerWidth > 1200}">
          <div class="card-block">
            <div class="card-content">

              <div class="card-body">
                <div class="row">
                  <div class="col-3">
                    <div class="hvrbox" *ngIf="edit.basic">
                      <a (click)="fileInput.click()" data-toggle="tooltip" data-placement="top"
                        [title]="globals.labels.tooltips.global.choose_image">
                        <img class="media-object d-flex height-70 width-70 rounded-circle"
                          [src]="candidate.photo ? candidate.photo : (candidate.gender == 'FEMALE' ? globals.femaleAvatar : globals.avatar)"
                          (error)="candidate.gender == 'FEMALE' ? candidate.photo = globals.femaleAvatar : candidate.photo = globals.avatar">
                        <div class="hvrbox-layer_top">
                          <div class="hvrbox-text">
                            {{globals.labels.placeholders.full_layout_page.solve_component.image}}</div>
                        </div>
                      </a>
                      <span
                        style="visibility: hidden; position: absolute; overflow: hidden; width: 0; height:0;border:none; margin:0; padding:0">
                        <input type="file" #fileInput ng2FileSelect [uploader]="uploader"
                          (change)="OnContentChange($event)" />
                      </span>
                    </div>
                    <img class="media-object d-flex height-70 width-70 rounded-circle" *ngIf="!edit.basic"
                      [src]="candidate.photo ? candidate.photo : (candidate.gender == 'FEMALE' ? globals.femaleAvatar : globals.avatar)"
                      (error)="candidate.gender == 'FEMALE' ? candidate.photo = globals.femaleAvatar : candidate.photo = globals.avatar">
                  </div>

                </div>
                <div class="media pb-0">
                  <div class="media-body align-self-center">
                    <p class="text-bold text-lg mb-0 line-height-custom">
                      <strong> {{candidate.first_name}} {{candidate.middle_name}}
                        {{candidate.last_name}}</strong>
                    </p>
                    <p class="mb-0"> {{candidate.current_job_title}}
                      <span *ngIf="candidate.current_employer">
                        @ <app-dealroom-widget [companyName]="candidate.current_employer"></app-dealroom-widget>
                      </span>
                    </p>
                    <p class="text-color-700 mb-0" *ngIf="candidate.location">
                      {{candidate.location.description}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="candidate.tags">
              <div class="row">
                <div class="col-12">
                  <span *ngFor="let tag of candidate.tags;let first=first"
                    [ngStyle]="{'background-color':tag.background_color, 'color':tag.text_color}"
                    class="badge-tags-inverse nowrap align-self-center  mt-2 mr-1 display-inline-flex"
                    title="{{tag.hover_data}}">
                    {{tag.description}}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 ml-3 mr-2" *ngIf="globals.checkIfExists('permission', 'edit_candidates')">
                <button type="button" class="btn pull-right bg-secondary-color col-12 m-3" (click)="addToJobModal()">
                  Add to Job</button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 ml-3 mr-2">
                <li style="list-style-type: none;" class="nav-item" ngbDropdown placement="bottom-right"
                  id="candidate_actions"
                  *ngIf="globals.checkIfExists('permission', 'delete_candidates') || globals.checkIfExists('permission', 'merge_candidates')">
                  <button type="button" class="btn pull-right bg-secondary-color m-3 col-12" id="dropdownBasic3"
                    ngbDropdownToggle>
                    Actions</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="card-shadow">
                    <a class="dropdown-item py-1 dropdown-color" (click)="chooseMergingCandidate()"
                      *ngIf="globals.checkIfExists('permission', 'merge_candidates')">
                      <i class="tt-icon-sm fa fa-compress mr-2"></i>
                      <span>Merge candidate</span>
                    </a>
                    <a class="dropdown-item py-1 dropdown-color" (click)="deleteCandidate()"
                      *ngIf="globals.checkIfExists('permission', 'delete_candidates')">
                      <i class="tt-icon-sm fa fa-trash mr-2"></i>
                      <span>Delete candidate</span>
                    </a>
                    <a class="dropdown-item py-1 dropdown-color" (click)="blacklistCandidate()"
                      *ngIf="globals.checkIfExists('permission', 'delete_candidates') && !checkIfCandidateTagExists('Blacklisted candidate')">
                      <i class="tt-icon-sm fa fa-trash mr-2"></i>
                      <span>Blacklist candidate</span>
                    </a>
                    <a class="dropdown-item py-1 dropdown-color" (click)="showAddActivity()"
                      *ngIf="globals.checkIfExists('permission', 'delete_candidates')">
                      <i class="ft-edit-3 mr-2"></i>
                      <span>Add activity</span>
                    </a>
                    <a class="dropdown-item py-1 dropdown-color" (click)="removeOpenToRoles()"
                      *ngIf="globals.checkIfExists('permission', 'edit_candidates') && checkIfCandidateTagExists('Open for roles')">
                      <i class="ft-edit-3 mr-2"></i>
                      <span>Remove open to roles</span>
                    </a>
                  </div>
                </li>
              </div>
            </div>
            <div class="row candidate-info">
              <div class="col-12 pr-0" *ngIf="candidate.phone">
                <p class="text-color-700 mb-0"> <strong>Phone</strong> </p>
                <p> {{candidate.phone ? candidate.phone : 'No information available'}}</p>
              </div>
              <div class="col-12 pr-0" *ngIf="candidate.mobile">
                <p class="text-color-700 mb-0"> <strong>Mobile</strong> </p>
                <p> {{candidate.mobile }}</p>
              </div>
              <div class="col-12 pr-0" *ngIf="candidate.linkedin">
                <p class="text-color-700 mb-0 mt-3"> <strong> Linkedin </strong> </p>
                <a href="{{candidate.linkedin}}" target="_blank" aria-hidden="true"> {{candidate.linkedin}}</a>
              </div>
              <div class="col-12 pr-0" *ngIf="candidate.xing">
                <p class="text-color-700 mb-0 mt-3"> <strong> Xing </strong> </p>
                <a href="{{candidate.xing}}" target="_blank" aria-hidden="true"> {{candidate.xing}}</a>
              </div>
              <div class="col-12 pr-0" *ngIf="candidate.primary_email">
                <p class="text-color-700 mb-0 mt-3"> <strong>Email</strong> </p>
                <p> {{candidate.primary_email}}</p>
              </div>
              <div class="col-12 pr-0" *ngIf="candidate.owner.name || candidate.created_at">
                <p class="text-color-700 mb-0 mt-3"> <strong>Created :</strong> </p>
                <p> {{candidate.owner.name}}
                  {{candidate.created_at| dateDiffTimestampFullNav:candidate.created_at}}</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
  <div class="col-lg col-12 candidate-details mt-4" [ngClass]="innerWidth<=990 ? 'ml-2 pr-3' : 'full-height-vh'">
    <nav ngbNav #acc="ngbNav" class="full-width nav-tabs" id="tab-shadow" (navChange)="fetchActiveTab($event)"
      [hidden]="addActivity">
      <ng-container [ngbNavItem]="'ngb-tab-0'">
        <a ngbNavLink>Details</a>
        <ng-template ngbNavContent>
          <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" class="ng-accordion-append"
              (panelChange)="preventDefault ? beforeChange($event) : ''">
              <ngb-panel id="basic">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordion.basic = !accordion.basic">
                    <div class="form-section">
                      Basic details
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates')" (click)="editFields('basic')">
                        edit
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="edit.basic">
                    <div class="row card-block">
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              First name *
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert first name"
                              [(ngModel)]="candidate.first_name" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed && candidate.first_name ? editCandidate('first_name',candidate.first_name , candidate.uuid): candidate.first_name ? '' : toastr.warning('First name is mandatory')"
                              (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Middle name
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert middle name"
                              [(ngModel)]="candidate.middle_name" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed ? editCandidate('middle_name',candidate.middle_name ? candidate.middle_name : null, candidate.uuid):''"
                              (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Last name *
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert last name"
                              [(ngModel)]="candidate.last_name" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed && candidate.last_name ? editCandidate('last_name',candidate.last_name , candidate.uuid):candidate.last_name ? '' :toastr.warning('Last name is mandatory')"
                              (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Linkedin *
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert Linkedin"
                              [(ngModel)]="candidate.linkedin" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed && candidate.linkedin ? editCandidate('linkedin',candidate.linkedin , candidate.uuid):candidate.linkedin ? '' :toastr.warning('Linkedin is mandatory')"
                              (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Xing
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert xing"
                              [(ngModel)]="candidate.xing" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed ? editCandidate('xing', candidate.xing ? candidate.xing : null, candidate.uuid):''"
                              (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Email *
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="email" #email="ngModel" class="form-control" id="email" name="email"
                              placeholder="Insert email" email [(ngModel)]="candidate.primary_email"
                              [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed && email.valid ? editCandidate('primary_email',candidate.primary_email, candidate.uuid): candidate.primary_email ? (email.valid ? '': toastr.warning('Invalid mail')) : toastr.warning('Email is mandatory')"
                              (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Gender *
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select bindLabel="description" dropdownPosition="bottom"
                              [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.contact.gender"
                              [items]="resources.gender" (open)="getGender()" [(ngModel)]="candidate.gender"
                              [ngModelOptions]="{standalone: true}"
                              (change)="changed && candidate.gender ? editCandidate('gender',candidate.gender, candidate.uuid):candidate.gender ? '' : toastr.warning('Gender is mandatory') "
                              (ngModelChange)="edit.basic ? onChange($event) : ''"></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12" *ngIf="globals.checkIfExists('permission', 'edit_candidates')">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Source
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select bindLabel="description" dropdownPosition="bottom" [(ngModel)]="candidate.source"
                              placeholder="Select source" [items]="resources.source" (open)="getSource()"
                              (change)="changed ? editCandidate('source',candidate.source, candidate.uuid):''"
                              (ngModelChange)="edit.basic ? onChange($event) : ''"></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Current location
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select [items]="locations$ | async" bindLabel="description"
                              placeholder="Select location" dropdownPosition="bottom" [minTermLength]="minLengthTerm"
                              [loading]="locationLoading"
                              typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                              [typeahead]="locationInput$" [(ngModel)]="candidate.location"
                              [ngModelOptions]="{standalone: true}"
                              (change)="editCandidate('location',candidate.location ? candidate.location : null , candidate.uuid)">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Nationality
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="demonym"
                              dropdownPosition="bottom" [items]="resources.nationality | async"
                              [loading]="nationalityLoading" [typeahead]="nationalityinput"
                              placeholder="Select nationality"
                              typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                              [(ngModel)]="candidate.nationality"
                              (change)="changed ? editCandidate('nationality',candidate.nationality, candidate.uuid):''"
                              (ngModelChange)="edit.basic ? onChange($event) : ''"></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Ranking
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert ranking"
                              [(ngModel)]="candidate.ranking" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed ? editCandidate('ranking',candidate.ranking ? candidate.ranking : null, candidate.uuid):''"
                              (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Skype
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert skype"
                              [(ngModel)]="candidate.skype" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed ? editCandidate('skype',candidate.skype ? candidate.skype : null, candidate.uuid):''"
                              (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Phone
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert phone"
                              [(ngModel)]="candidate.phone" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed ? editCandidate('phone',candidate.phone ? candidate.phone : null, candidate.uuid):''"
                              (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Mobile
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert mobile"
                              [(ngModel)]="candidate.mobile" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed ? editCandidate('mobile',candidate.mobile ? candidate.mobile : null, candidate.uuid):''"
                              (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!edit.basic">
                    <div class="row card-block">
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Gender
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.gender ? candidate.gender.description : 'No information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12" *ngIf="globals.checkIfExists('permission', 'edit_candidates')">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Source
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.source ? candidate.source.description : 'No information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Nationality
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.nationality ? candidate.nationality.demonym : 'No information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Ranking
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.ranking ? candidate.ranking : 'No information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Skype
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.skype ? candidate.skype : 'No information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Phone
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.phone ? candidate.phone : 'No information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Mobile
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.mobile ? candidate.mobile : 'No information available'}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="compensation" *ngIf="globals.checkIfExists('permission', 'edit_candidates')">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordion.compensation = !accordion.compensation">
                    <div class="form-section">
                      Compensation
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates')"
                        (click)="editFields('compensation')">
                        edit
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="edit.compensation">
                    <div class="row card-block">
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Base
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" pattern="[0-9]*" (keydown)=preventNegativeNumbers($event)
                              class="form-control" placeholder="Insert base salary"
                              [(ngModel)]="candidate.compensation.base_salary" [ngModelOptions]="{standalone: true}"
                              appOnFocusOut
                              (focusout)="changed ? editCandidate('compensation.base_salary',candidate.compensation.base_salary, candidate.uuid): ''"
                              (ngModelChange)="edit.compensation ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Currency
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select bindLabel="description" dropdownPosition="bottom"
                              [placeholder]="globals.labels.placeholders.inputs.full_layout_page.cit_component.next_steps.final_steps.currency"
                              [items]="resources.currencies" (open)="getCompensation('currencies')"
                              [(ngModel)]="candidate.compensation.currency"
                              (change)="changed ? editCandidate('compensation.currency',candidate.compensation.currency , candidate.uuid):''"
                              (ngModelChange)="edit.compensation ? onChange($event) : ''"></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Bonus
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" pattern="[0-9]*" (keydown)=preventNegativeNumbers($event)
                              class="form-control" placeholder="Insert bonus"
                              [(ngModel)]="candidate.compensation.bonus_salary" [ngModelOptions]="{standalone: true}"
                              appOnFocusOut
                              (focusout)="changed ? editCandidate('compensation.bonus_salary',candidate.compensation.bonus_salary, candidate.uuid): ''"
                              (ngModelChange)="edit.compensation ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Type
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select bindLabel="description" dropdownPosition="bottom"
                              [placeholder]="globals.labels.placeholders.inputs.full_layout_page.cit_component.next_steps.final_steps.currency"
                              [items]="resources.bonus_type" (open)="getCompensation('bonus_type')"
                              [(ngModel)]="candidate.compensation.bonus_type"
                              (change)="changed ? editCandidate('compensation.bonus_type',candidate.compensation.bonus_type , candidate.uuid):''"
                              (ngModelChange)="edit.compensation ? onChange($event) : ''"></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Equity
                            </label>
                          </div>
                          <div class="card-body-content">
                            <textarea appAutosize [(ngModel)]="candidate.compensation.equity"
                              [ngModelOptions]="{standalone: true}" class="form-control" type="text" appOnFocusOut
                              (focusout)="changed ? editCandidate('compensation.equity',candidate.compensation.equity , candidate.uuid): ''"
                              (ngModelChange)="edit.compensation ? onChange($event) : ''"
                              [placeholder]="globals.labels.placeholders.inputs.full_layout_page.cit_component.next_steps.final_steps.equity"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!edit.compensation">
                    <div class="row card-block">
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Base
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.compensation && candidate.compensation.base_salary ?
                            candidate.compensation.base_salary : 'No information available' }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Currency
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.compensation && candidate.compensation.currency ?
                            candidate.compensation.currency.description : 'No information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Bonus
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.compensation && candidate.compensation.bonus_salary ?
                            candidate.compensation.bonus_salary: 'No information available' }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Type
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.compensation && candidate.compensation.bonus_type ?
                            candidate.compensation.bonus_type.description : 'No information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Equity
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.compensation && candidate.compensation.equity ? candidate.compensation.equity :
                            'No information available'}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="experience">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordion.experience = !accordion.experience">
                    <div class="form-section">
                      Experience
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates') && (candidate.experiences.visible.length || candidate.experiences.hidden.length)"
                        (click)="editFields('experience'); edit.experience ? candidate.showExperience = false : candidate.showExperience = true">
                        edit
                      </div>
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates')"
                        (click)="addForm('experienceForm')">
                        add
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="add.experienceForm">
                    <div class="form-group">
                      <div class="card card-preview">
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="card-body-content">
                                <div class="custom-control custom-checkbox m-0">
                                  <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="add.experience.is_investment" class="custom-control-input"
                                    id="investment">
                                  <label class="custom-control-label" for="investment">Is investment
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="card-body-content">
                                <div class="custom-control custom-checkbox m-0">
                                  <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="add.experience.not_applicable" class="custom-control-input"
                                    id="not_applicable">
                                  <label class="custom-control-label" for="not_applicable">Not Applicable
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Title *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="text" class="form-control" placeholder="Insert job title"
                                  [(ngModel)]="add.experience.job_title" [ngModelOptions]="{standalone: true}">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Company *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="text" class="form-control" placeholder="Insert company name"
                                  [(ngModel)]="add.experience.company_name" [ngModelOptions]="{standalone: true}">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Start date *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="date" [(ngModel)]="add.experience.start_date"
                                  [ngModelOptions]="{standalone: true}" class="form-control">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  End date
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="date" [(ngModel)]="add.experience.end_date"
                                  [ngModelOptions]="{standalone: true}" class="form-control">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Description
                                </label>
                              </div>
                              <div class="card-body-content">
                                <div class="row">
                                  <div class="col-12 form-group">
                                    <textarea rows="5" class="form-control" type="text"
                                      [(ngModel)]="add.experience.summary" [ngModelOptions]="{standalone: true}"
                                      placeholder="Add Description"></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                </label>
                              </div>
                              <div class="card-body-content">
                                <div class="custom-control custom-checkbox m-0">
                                  <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="add.experience.current_employer" class="custom-control-input"
                                    id="current_employer">
                                  <label class="custom-control-label"
                                    *ngIf="!add.experience.end_date && add.experience.start_date"
                                    for="current_employer">{{globals.labels.placeholders.full_layout_page.omni_component.experience_education.primary_job}}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="add.experience.is_investment">
                              <div class="form">
                                <div class="form-section">
                                  <label class="secondary-color">VC fields</label>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="card-body-title-basic">
                                  <label>
                                    Country focus
                                  </label>
                                </div>
                                <div class="card-body-content">
                                  <ng-select bindLabel="name" dropdownPosition="bottom"
                                    [items]="resources.countryFocus | async" [loading]="countryLoading"
                                    [typeahead]="countryinput" placeholder="Select country" [multiple]="true"
                                    [(ngModel)]="add.experience.investment_country_focus"></ng-select>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="card-body-title-basic">
                                  <label>
                                    Stage focus
                                  </label>
                                </div>
                                <div class="card-body-content">
                                  <ng-select bindLabel="description" dropdownPosition="bottom"
                                    [items]="resources.stageFocus" (open)="getStageFocus()" placeholder="Select stage"
                                    [multiple]="true" [(ngModel)]="add.experience.investment_stage_focus"></ng-select>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="card-body-title-basic">
                                  <label>
                                    Sector focus
                                  </label>
                                </div>
                                <div class="card-body-content">
                                  <ng-select bindLabel="value" bindValue="value" dropdownPosition="bottom"
                                    [items]="resources.sectorFocus | async" [loading]="sectorLoading" [addTag]="true"
                                    [typeahead]="sectorinput" placeholder="Select sector" [multiple]="true"
                                    [(ngModel)]="add.experience.investment_sector_focus"></ng-select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Seniority
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select
                                  [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.seniority"
                                  [items]="resources.seniority" bindLabel="description" dropdownPosition="bottom"
                                  [(ngModel)]="add.experience.seniority" [ngModelOptions]="{standalone: true}"
                                  (open)="getSeniority()">
                                </ng-select>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Industry
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select [items]="resources.industry" (open)="getIndustry()" [multiple]="true"
                                  [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.industry"
                                  bindLabel="description" dropdownPosition="bottom"
                                  [(ngModel)]="add.experience.industry">
                                </ng-select>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Functional expertise
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select [items]="resources.fe" (open)="getFe()" [multiple]="true"
                                  [(ngModel)]="add.experience.fe" [ngModelOptions]="{standalone: true}"
                                  [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.functional"
                                  bindLabel="description" (remove)="onUnselectFunctional(add.experience, false)"
                                  (clear)="add.experience.sfe = []">
                                </ng-select>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Sub Functional expertise
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select [items]="resources.filteredSfe" (open)="getFilteredSfe(add.experience.fe)"
                                  [disabled]="!add.experience.fe.length"
                                  [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.sub_functional"
                                  bindLabel="description" [multiple]="true" [(ngModel)]="add.experience.sfe"
                                  [ngModelOptions]="{standalone: true}">
                                </ng-select>
                              </div>
                            </div>

                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Expertise
                                </label>
                              </div>
                              <div class="card-body-content">
                                <div class="row">
                                  <div class="col-12 form-group">
                                    <input class="form-control" type="text" [(ngModel)]="add.experience.exp"
                                      [ngModelOptions]="{standalone: true}"
                                      [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.expertise"
                                      (keyup.enter)="add.experience.expertise.push({ 'value': add.experience.exp.toLowerCase(), 'description': add.experience.exp });
                                          add.experience.exp = ''" />
                                  </div>
                                  <div class="col-12 form-group">
                                    <tag-input id="expertise-input" [(ngModel)]="add.experience.expertise"
                                      [ngModelOptions]="{standalone: true}" [identifyBy]="'value'"
                                      [displayBy]="'description'">
                                    </tag-input>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="pull-right">
                              <button type="button" class="btn pull-right bg-secondary-color"
                                (click)="add.experience.job_title ? (add.experience.company_name ? (add.experience.start_date ? addNewExperience(add.experience, candidate.uuid) : toastr.warning('Start date is mandatory')) : toastr.warning('Company name is mandatory') ): toastr.warning('Job title is mandatory')">
                                Add new experience
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="candidate.experiences.visible.length || candidate.experiences.hidden.length; then showExperience else showEmptyExperience">
                  </div>
                  <ng-template #showExperience>
                    <div class="row">
                      <div class="col-12">
                        <div *ngIf="candidate.experiences.visible.length; then showCurrentExp else showCurrentExpMsg">
                        </div>
                        <ng-template #showCurrentExp>
                          <div class="form-group" *ngFor="let experience of candidate.experiences.visible;let i=index">
                            <div *ngIf="!edit.experience">
                              <div class="row card-block">
                                <div class="col-md-6 col-12">

                                  <div class="card-body-title-basic" *ngIf="experience.job_title">
                                    <div *ngIf="experience.current_employer" class="tag secondary-color pull-right">
                                      <em>{{globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.primary_job}}</em>
                                    </div>
                                    <div *ngIf="experience.is_investment" class="tag primary-color pull-right mr-3">
                                      <em>Investment</em>
                                    </div>
                                    {{experience.job_title}}
                                    <span *ngIf="experience.company_name">
                                      @ <app-dealroom-widget [companyName]="experience.company_name"></app-dealroom-widget>
                                    </span>
                                  </div>

                                  <div class="card-body-content" *ngIf="(experience.start_date || experience.end_date)">
                                    {{(experience.start_date) ? (experience.start_date |
                                    dateDiffTimestampNav:experience.start_date) + ' – ' : ''}}
                                    {{(experience.end_date) ? (experience.end_date |
                                    dateDiffTimestampNav:experience.end_date) :
                                    globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.present}}
                                  </div>
                                  <div class="form-group" *ngIf="experience.summary">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Description
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      {{experience.summary}}
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="experience.not_applicable">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Not applicable
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 col-12">
                                  <div class="form-group" *ngIf="experience.seniority && experience.seniority">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Seniority
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      {{experience.seniority.description}}
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="experience.industry.length">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Industry
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <span *ngFor="let i of experience.industry;let last = last">
                                        {{i.description}}{{last? '':', '}}</span>
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="experience.fe.length">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Functional expertise
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <span *ngFor="let i of experience.fe;let last = last">
                                        {{i.description}}{{last? '':', '}}</span>
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="experience.sfe.length">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Sub Functional expertise
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <span *ngFor="let i of experience.sfe;let last = last">
                                        {{i.description}}{{last? '':', '}}</span>
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="experience.expertise.length">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Expertise
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <span *ngFor=" let i of experience.expertise;let last=last">
                                        {{i.description}}{{last? '':', '}}</span>
                                    </div>
                                  </div>
                                  <div class="form-group"
                                    *ngIf="experience.is_investment && (experience.investment_country_focus.length || experience.investment_stage_focus.length || experience.investment_sector_focus.length)">
                                    <div class="form">
                                      <div class="form-section">
                                        <label>VC fields</label>
                                      </div>
                                    </div>
                                    <div class="form-group" *ngIf="experience.investment_country_focus.length">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Country focus
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <span *ngFor="let i of experience.investment_country_focus;let last = last">
                                          {{i.name}}{{last? '':', '}}</span>
                                      </div>
                                    </div>
                                    <div class="form-group" *ngIf="experience.investment_stage_focus.length">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Stage focus
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <span *ngFor="let i of experience.investment_stage_focus;let last = last">
                                          {{i.description}}{{last? '':', '}}</span>
                                      </div>
                                    </div>
                                    <div class="form-group" *ngIf="experience.investment_sector_focus.length">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Sector focus
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <span *ngFor="let i of experience.investment_sector_focus;let last = last">
                                          {{i}}{{last? '':', '}}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="edit.experience">
                              <div class="card card-preview">
                                <div class="row">
                                  <div class="col-12" *ngIf="globals.checkIfExists('permission', 'edit_candidates')">
                                    <div class="mb-5">
                                      <div class="form-group">
                                        <div class="row">
                                          <div class="col-6">
                                            <div class="custom-control custom-checkbox">
                                              <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="experience.is_investment" class="custom-control-input"
                                                id="investment-visible-{{i}}"
                                                (change)="experience.is_investment ? editExperience('is_investment', experience.is_investment, experience.uuid, true): editVCExperience(experience, true)">
                                              <label class="custom-control-label" for="investment-visible-{{i}}">Is
                                                investment
                                              </label>
                                            </div>
                                          </div>
                                          <div class="col-5">
                                            <div class="custom-control custom-checkbox">
                                              <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="experience.not_applicable" class="custom-control-input"
                                                id="not_applicable-visible-{{i}}"
                                                (change)="editExperience('not_applicable', experience.not_applicable, experience.uuid, true)">
                                              <label class="custom-control-label" for="not_applicable-visible-{{i}}">Not
                                                applicable
                                              </label>
                                            </div>
                                          </div>
                                          <div class="col-1">
                                            <a
                                              (click)="deleteExperience(experience.uuid, i, candidate.experiences.visible, true)">
                                              <i class="tt-icon-lg icon-ttdelete secondary-color" data-toggle="tooltip"
                                                data-placement="bottom"
                                                [title]="globals.labels.tooltips.global.delete"></i>
                                            </a>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Title *
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="text" class="form-control" placeholder="Insert job title"
                                          [(ngModel)]="experience.job_title" [ngModelOptions]="{standalone: true}"
                                          appOnFocusOut
                                          (focusout)="changed && experience.job_title ? editExperience('job_title', experience.job_title, experience.uuid, true): experience.job_title ? '' :toastr.warning('Job title is mandatory')"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Company *
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="text" class="form-control" placeholder="Insert company name"
                                          [(ngModel)]="experience.company_name" [ngModelOptions]="{standalone: true}"
                                          appOnFocusOut
                                          (focusout)="changed && experience.company_name ? editExperience('company_name', experience.company_name, experience.uuid, true): experience.company_name ? '':toastr.warning('Company name is mandatory')"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Start date *
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="date"
                                          [ngModel]="experience.start_date ? (experience.start_date | timestampToDatepicker:experience.start_date) : null"
                                          [ngModelOptions]="{standalone: true}" appOnFocusOut
                                          (focusout)="changed && experience.startDateTimestamp ? editExperience('start_date', experience.startDateTimestamp ? experience.startDateTimestamp : null,
                                           experience.uuid, true, i, candidate.experiences.visible):toastr.warning('Start date is mandatory')"
                                          (ngModelChange)="edit.experience ? onChange(experience.startDateTimestamp = $event) : ''"
                                          class="form-control">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          End date
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="date"
                                          [ngModel]="experience.end_date ? (experience.end_date | timestampToDatepicker:experience.end_date) : null"
                                          [ngModelOptions]="{standalone: true}" appOnFocusOut (focusout)="changed ? editExperience('end_date', experience.endDateTimestamp ? experience.endDateTimestamp : null,
                                             experience.uuid, true, i, candidate.experiences.visible):''"
                                          (ngModelChange)="edit.experience ? onChange(experience.endDateTimestamp = $event) : ''"
                                          class="form-control">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Description
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <div class="row">
                                          <div class="col-12 form-group">
                                            <textarea rows="5" class="form-control" type="text"
                                              [(ngModel)]="experience.summary" [ngModelOptions]="{standalone: true}"
                                              placeholder="Add Description" appOnFocusOut
                                              (focusout)="changed ? editExperience('summary', experience.summary, experience.uuid, true): ''"
                                              (ngModelChange)="edit.experience ? onChange($event) : ''">
                                          </textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-content">
                                        <div class="custom-control custom-checkbox m-0">
                                          <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="experience.current_employer" class="custom-control-input"
                                            id="current_employer_visible+{{i}}"
                                            (change)="editCurrentEmployer($event, experience,i, true)">
                                          <label class="custom-control-label"
                                            *ngIf="!experience.end_date && experience.start_date"
                                            for="current_employer_visible+{{i}}">{{globals.labels.placeholders.full_layout_page.omni_component.experience_education.primary_job}}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="experience.is_investment">
                                      <div class="form">
                                        <div class="form-section">
                                          <label class="secondary-color">VC fields</label>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <div class="card-body-title-basic">
                                          <label>
                                            Country focus
                                          </label>
                                        </div>
                                        <div class="card-body-content">
                                          <ng-select bindLabel="name" dropdownPosition="bottom"
                                            [items]="resources.countryFocus | async" [loading]="countryLoading"
                                            [typeahead]="countryinput" placeholder="Select country" [multiple]="true"
                                            [(ngModel)]="experience.investment_country_focus"
                                            (change)="changed ? editExperience('investment_country_focus',experience.investment_country_focus, experience.uuid, true):''"
                                            (ngModelChange)="edit.experience ? onChange($event) : ''"></ng-select>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <div class="card-body-title-basic">
                                          <label>
                                            Stage focus
                                          </label>
                                        </div>
                                        <div class="card-body-content">
                                          <ng-select bindLabel="description" dropdownPosition="bottom"
                                            [items]="resources.stageFocus" (open)="getStageFocus()" [multiple]="true"
                                            placeholder="Select stage" [(ngModel)]="experience.investment_stage_focus"
                                            (change)="changed ? editExperience('investment_stage_focus',experience.investment_stage_focus, experience.uuid, true):''"
                                            (ngModelChange)="edit.experience ? onChange($event) : ''"></ng-select>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <div class="card-body-title-basic">
                                          <label>
                                            Sector focus
                                          </label>
                                        </div>
                                        <div class="card-body-content">
                                          <ng-select bindLabel="value" bindValue="value" dropdownPosition="bottom"
                                            [items]="resources.sectorFocus | async" [loading]="sectorLoading"
                                            [addTag]="true" [typeahead]="sectorinput" placeholder="Select sector"
                                            [multiple]="true" [(ngModel)]="experience.investment_sector_focus"
                                            (change)="changed ? editExperience('investment_sector_focus',experience.investment_sector_focus, experience.uuid, true):''"
                                            (ngModelChange)="edit.experience ? onChange($event) : ''"></ng-select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Seniority
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <ng-select
                                          [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.seniority"
                                          [items]="resources.seniority" bindLabel="description"
                                          dropdownPosition="bottom" [(ngModel)]="experience.seniority"
                                          [ngModelOptions]="{standalone: true}" (open)="getSeniority()"
                                          (change)="changed ? editExperience('seniority',experience.seniority, experience.uuid, true):''"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                        </ng-select>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Industry
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <ng-select [items]="resources.industry" (open)="getIndustry()" [multiple]="true"
                                          [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.industry"
                                          bindLabel="description" dropdownPosition="bottom"
                                          [(ngModel)]="experience.industry"
                                          (change)="changed ? editExperience('industry',experience.industry, experience.uuid, true):''"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                        </ng-select>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Functional expertise
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <ng-select [items]="resources.fe" (open)="getFe()" [multiple]="true"
                                          [(ngModel)]="experience.fe" [ngModelOptions]="{standalone: true}"
                                          [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.functional"
                                          bindLabel="description"
                                          (remove)="onUnselectFunctional(experience, true, true)"
                                          (clear)="editExperience('sfe', [], experience.uuid, true);experience.sfe = []"
                                          (change)="changed ? editExperience('fe',experience.fe, experience.uuid, true):''"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                        </ng-select>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Sub Functional expertise
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <ng-select [items]="resources.filteredSfe"
                                          (open)="getFilteredSfe(experience.fe)" [disabled]="!experience.fe.length"
                                          [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.sub_functional"
                                          bindLabel="description" [multiple]="true" [(ngModel)]="experience.sfe"
                                          [ngModelOptions]="{standalone: true}"
                                          (change)="changed ? editExperience('sfe',experience.sfe, experience.uuid, true):''"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                        </ng-select>
                                      </div>
                                    </div>

                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Expertise
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <div class="row">
                                          <div class="col-12 form-group">
                                            <input class="form-control" type="text" [(ngModel)]="experience.exp"
                                              [ngModelOptions]="{standalone: true}"
                                              [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.expertise"
                                              (keyup.enter)="experience.expertise.push({ 'value': experience.exp.toLowerCase(), 'description': experience.exp });
                                              experience.exp = '';
                                              editExperience('expertise',experience.expertise, experience.uuid, true)" />
                                          </div>
                                          <div class="col-12 form-group">
                                            <tag-input id="expertise-input" [(ngModel)]="experience.expertise"
                                              [editable]="true" [ngModelOptions]="{standalone: true}"
                                              [identifyBy]="'value'" [displayBy]="'description'"
                                              (ngModelChange)="editExperience('expertise',experience.expertise, experience.uuid, true)">
                                            </tag-input>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template #showCurrentExpMsg>
                          <div class="form-group card-block" *ngIf="!edit.experience">
                            <div class="card-body-content">
                              Currently unemployed
                            </div>
                          </div>
                        </ng-template>
                        <div *ngIf="!candidate.showExperience">
                          <div class="form-group" *ngFor="let experience of candidate.experiences.hidden;let i = index">
                            <div *ngIf="!edit.experience">
                              <div class="row card-block">
                                <div class="col-md-6 col-12">
                                  <div class="card-body-title-basic" *ngIf="experience.job_title">
                                    <div *ngIf="experience.current_employer" class="tag secondary-color pull-right">
                                      <em>{{globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.primary_job}}</em>
                                    </div>
                                    <div *ngIf="experience.is_investment" class="tag primary-color pull-right mr-3">
                                      <em>Investment</em>
                                    </div>
                                    {{experience.job_title}}
                                    <span *ngIf="experience.company_name">
                                      @ <app-dealroom-widget [companyName]="experience.company_name"></app-dealroom-widget>
                                    </span>
                                  </div>
                                  <div class="card-body-content"
                                    *ngIf="(experience.start_date || experience.end_date) && !edit.experience">
                                    {{(experience.start_date) ? (experience.start_date |
                                    dateDiffTimestampNav:experience.start_date) + ' – ' : ''}}
                                    {{(experience.end_date) ? (experience.end_date |
                                    dateDiffTimestampNav:experience.end_date) :
                                    globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.present}}
                                  </div>
                                  <div class="form-group" *ngIf="experience.summary">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Description
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      {{experience.summary}}
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="experience.not_applicable">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Not applicable
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 col-12">
                                  <div class="form-group" *ngIf="experience.seniority && experience.seniority">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Seniority
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      {{experience.seniority.description}}
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="experience.industry.length">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Industry
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <span *ngFor="let i of experience.industry;let last = last">
                                        {{i.description}}{{last? '':', '}}</span>
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="experience.fe.length">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Functional expertise
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <span *ngFor="let i of experience.fe;let last = last">
                                        {{i.description}}{{last? '':', '}}</span>
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="experience.sfe.length">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Sub Functional expertise
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <span *ngFor="let i of experience.sfe;let last = last">
                                        {{i.description}}{{last? '':', '}}</span>
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="experience.expertise.length">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Expertise
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <span *ngFor=" let i of experience.expertise;let last=last">
                                        {{i.description}}{{last? '':', '}}</span>
                                    </div>
                                  </div>
                                  <div class="form-group"
                                    *ngIf="experience.is_investment && (experience.investment_country_focus.length || experience.investment_stage_focus.length || experience.investment_sector_focus.length)">
                                    <div class="form">
                                      <div class="form-section">
                                        <label>VC fields</label>
                                      </div>
                                    </div>
                                    <div class="form-group" *ngIf="experience.investment_country_focus.length">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Country focus
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <span *ngFor="let i of experience.investment_country_focus;let last = last">
                                          {{i.name}}{{last? '':', '}}</span>
                                      </div>
                                    </div>
                                    <div class="form-group" *ngIf="experience.investment_stage_focus.length">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Stage focus
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <span *ngFor="let i of experience.investment_stage_focus;let last = last">
                                          {{i.description}}{{last? '':', '}}</span>
                                      </div>
                                    </div>
                                    <div class="form-group" *ngIf="experience.investment_sector_focus.length">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Sector focus
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <span *ngFor="let i of experience.investment_sector_focus;let last = last">
                                          {{i}}{{last? '':', '}}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="edit.experience">
                              <div class="card card-preview">
                                <div class="row">
                                  <div class="col-12" *ngIf="globals.checkIfExists('permission', 'edit_candidates')">
                                    <div class="mb-5">
                                      <div class="row">
                                        <div class="col-6">
                                          <div class="custom-control custom-checkbox">
                                            <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                              [(ngModel)]="experience.is_investment" class="custom-control-input"
                                              id="investment-hidden-{{i}}"
                                              (change)="experience.is_investment ? editExperience('is_investment', experience.is_investment, experience.uuid, false): editVCExperience(experience, false)">
                                            <label class="custom-control-label" for="investment-hidden-{{i}}">Is
                                              investment
                                            </label>
                                          </div>
                                        </div>
                                        <div class="col-5">
                                          <div class="custom-control custom-checkbox">
                                            <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                              [(ngModel)]="experience.not_applicable" class="custom-control-input"
                                              id="not_applicable-hidden-{{i}}"
                                              (change)="editExperience('not_applicable', experience.not_applicable, experience.uuid, false)">
                                            <label class="custom-control-label" for="not_applicable-hidden-{{i}}">Not
                                              applicable
                                            </label>
                                          </div>
                                        </div>
                                        <div class="col-1">

                                          <a
                                            (click)="deleteExperience(experience.uuid, i, candidate.experiences.hidden, true)">
                                            <i class="tt-icon-lg icon-ttdelete secondary-color" data-toggle="tooltip"
                                              data-placement="bottom"
                                              [title]="globals.labels.tooltips.global.delete"></i>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Title *
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="text" class="form-control" placeholder="Insert job title"
                                          [(ngModel)]="experience.job_title" [ngModelOptions]="{standalone: true}"
                                          appOnFocusOut
                                          (focusout)="changed && experience.job_title ? editExperience('job_title', experience.job_title, experience.uuid, false):experience.job_title ? '' :toastr.warning('Job title is mandatory')"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Company *
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="text" class="form-control" placeholder="Insert company name"
                                          [(ngModel)]="experience.company_name" [ngModelOptions]="{standalone: true}"
                                          appOnFocusOut
                                          (focusout)="changed && experience.company_name ? editExperience('company_name', experience.company_name, experience.uuid, false): experience.company_name ? '':toastr.warning('Company name is mandatory')"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Start date *
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="date"
                                          [ngModel]="experience.start_date ? (experience.start_date | timestampToDatepicker:experience.start_date) : null"
                                          [ngModelOptions]="{standalone: true}" onFocappOnFocusOutusOut
                                          (focusout)="changed && experience.startDateTimestamp ? editExperience('start_date', experience.startDateTimestamp ? experience.startDateTimestamp : null,
                                           experience.uuid, false, i, candidate.experiences.hidden):toastr.warning('Start date is mandatory')"
                                          (ngModelChange)="edit.experience ? onChange(experience.startDateTimestamp = $event) : ''"
                                          class="form-control">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          End date
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="date"
                                          [ngModel]="experience.end_date ? (experience.end_date | timestampToDatepicker:experience.end_date) : null"
                                          [ngModelOptions]="{standalone: true}" appOnFocusOut (focusout)="changed ? editExperience('end_date', experience.endDateTimestamp ? experience.endDateTimestamp : null,
                                                 experience.uuid, false, i, candidate.experiences.hidden):''"
                                          (ngModelChange)="edit.experience ? onChange(experience.endDateTimestamp = $event) : ''"
                                          class="form-control">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Description
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <div class="row">
                                          <div class="col-12 form-group">
                                            <textarea rows="5" class="form-control" type="text"
                                              [(ngModel)]="experience.summary" [ngModelOptions]="{standalone: true}"
                                              placeholder="Add Description" appOnFocusOut
                                              (focusout)="changed ? editExperience('summary', experience.summary, experience.uuid, false): ''"
                                              (ngModelChange)="edit.experience ? onChange($event) : ''">
                                          </textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-content">
                                        <div class="custom-control custom-checkbox m-0">
                                          <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="experience.current_employer" class="custom-control-input"
                                            id="current_employer_hidden+{{i}}"
                                            (change)="editCurrentEmployer($event, experience,i, false)">
                                          <label class="custom-control-label"
                                            *ngIf="!experience.end_date && experience.start_date"
                                            for="current_employer_hidden+{{i}}">{{globals.labels.placeholders.full_layout_page.omni_component.experience_education.primary_job}}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="experience.is_investment">
                                      <div class="form">
                                        <div class="form-section">
                                          <label class="secondary-color">VC fields</label>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <div class="card-body-title-basic">
                                          <label>
                                            Country focus
                                          </label>
                                        </div>
                                        <div class="card-body-content">
                                          <ng-select bindLabel="name" dropdownPosition="bottom"
                                            [items]="resources.countryFocus | async" [loading]="countryLoading"
                                            [typeahead]="countryinput" placeholder="Select country" [multiple]="true"
                                            [(ngModel)]="experience.investment_country_focus"
                                            (change)="changed ? editExperience('investment_country_focus',experience.investment_country_focus, experience.uuid, false):''"
                                            (ngModelChange)="edit.experience ? onChange($event) : ''"></ng-select>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <div class="card-body-title-basic">
                                          <label>
                                            Stage focus
                                          </label>
                                        </div>
                                        <div class="card-body-content">
                                          <ng-select bindLabel="description" dropdownPosition="bottom"
                                            [items]="resources.stageFocus" (open)="getStageFocus()" [multiple]="true"
                                            placeholder="Select stage" [(ngModel)]="experience.investment_stage_focus"
                                            (change)="changed ? editExperience('investment_stage_focus',experience.investment_stage_focus, experience.uuid, false):''"
                                            (ngModelChange)="edit.experience ? onChange($event) : ''"></ng-select>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <div class="card-body-title-basic">
                                          <label>
                                            Sector focus
                                          </label>
                                        </div>
                                        <div class="card-body-content">
                                          <ng-select bindLabel="value" bindValue="value" dropdownPosition="bottom"
                                            [items]="resources.sectorFocus | async" [loading]="sectorLoading"
                                            [addTag]="true" [typeahead]="sectorinput" placeholder="Select sector"
                                            [multiple]="true" [(ngModel)]="experience.investment_sector_focus"
                                            (change)="changed ? editExperience('investment_sector_focus',experience.investment_sector_focus, experience.uuid, false):''"
                                            (ngModelChange)="edit.experience ? onChange($event) : ''"></ng-select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Seniority
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <ng-select
                                          [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.seniority"
                                          [items]="resources.seniority" bindLabel="description"
                                          dropdownPosition="bottom" [(ngModel)]="experience.seniority"
                                          [ngModelOptions]="{standalone: true}" (open)="getSeniority()"
                                          (change)="changed ? editExperience('seniority',experience.seniority, experience.uuid, false):''"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                        </ng-select>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Industry
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <ng-select [items]="resources.industry" (open)="getIndustry()" [multiple]="true"
                                          [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.industry"
                                          bindLabel="description" dropdownPosition="bottom"
                                          [(ngModel)]="experience.industry"
                                          (change)="changed ? editExperience('industry',experience.industry, experience.uuid, false):''"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                        </ng-select>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Functional expertise
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <ng-select [items]="resources.fe" (open)="getFe()" [multiple]="true"
                                          [(ngModel)]="experience.fe" [ngModelOptions]="{standalone: true}"
                                          [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.functional"
                                          bindLabel="description"
                                          (remove)="onUnselectFunctional(experience, true, false)"
                                          (clear)="editExperience('sfe', [], experience.uuid, false);experience.sfe = []"
                                          (change)="changed ? editExperience('fe',experience.fe, experience.uuid, false):''"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                        </ng-select>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Sub Functional expertise
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <ng-select [items]="resources.filteredSfe"
                                          (open)="getFilteredSfe(experience.fe)" [disabled]="!experience.fe.length"
                                          [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.sub_functional"
                                          bindLabel="description" [multiple]="true" [(ngModel)]="experience.sfe"
                                          [ngModelOptions]="{standalone: true}"
                                          (change)="changed ? editExperience('sfe',experience.sfe, experience.uuid, false):''"
                                          (ngModelChange)="edit.experience ? onChange($event) : ''">
                                        </ng-select>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Expertise
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <div class="row">
                                          <div class="col-12 form-group">
                                            <input class="form-control" type="text" [(ngModel)]="experience.exp"
                                              [ngModelOptions]="{standalone: true}"
                                              [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.expertise"
                                              (keyup.enter)="experience.expertise.push({ 'value': experience.exp.toLowerCase(), 'description': experience.exp });
                                                  experience.exp = '';
                                                  editExperience('expertise',experience.expertise, experience.uuid, false)" />
                                          </div>
                                          <div class="col-12 form-group">
                                            <tag-input id="expertise-input" [(ngModel)]="experience.expertise"
                                              [ngModelOptions]="{standalone: true}" [identifyBy]="'value'"
                                              [displayBy]="'description'"
                                              (ngModelChange)="editExperience('expertise',experience.expertise, experience.uuid, false)">
                                            </tag-input>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group secondary-color sm-font card-block"
                          *ngIf="candidate.experiences.hidden.length && !edit.experience">
                          <a (click)="this.candidate.showExperience = !this.candidate.showExperience">
                            {{candidate.showExperience ? 'show' : 'hide' }} past experience
                            <i class="icon ft-chevron-down"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #showEmptyExperience>
                    <div class="row card-block">
                      <div class="col-12">
                        No information available
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="education">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordion.education = !accordion.education">
                    <div class="form-section">
                      Educations
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates') && (candidate.educations.visible.length || candidate.educations.hidden.length)"
                        (click)="editFields('education'); edit.education ? candidate.showEducation = false : candidate.showEducation = true">
                        edit
                      </div>
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates')"
                        (click)="addForm('educationForm')">
                        add
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="add.educationForm">
                    <div class="form-group">
                      <div class="card card-preview">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  University *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="text" class="form-control" placeholder="Insert university"
                                  [(ngModel)]="add.education.university" [ngModelOptions]="{standalone: true}">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Degree *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="text" class="form-control" placeholder="Insert degree"
                                  [(ngModel)]="add.education.degree_name" [ngModelOptions]="{standalone: true}">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Major
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="text" class="form-control" placeholder="Insert major"
                                  [(ngModel)]="add.education.major" [ngModelOptions]="{standalone: true}">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Start date
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="date" [(ngModel)]="add.education.start_date"
                                  [ngModelOptions]="{standalone: true}" class="form-control">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  End date
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="date" [(ngModel)]="add.education.end_date"
                                  [ngModelOptions]="{standalone: true}" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="pull-right">
                              <button type="button" class="btn pull-right bg-secondary-color"
                                (click)="add.education.university ? (add.education.degree_name ? addNewEducation(add.education, candidate.uuid) : toastr.warning('Degree is mandatory')) : toastr.warning('University is mandatory')">
                                Add new education
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="candidate.educations.visible.length || candidate.educations.hidden.length; then showEducations else showEmptyEducations">
                  </div>
                  <ng-template #showEducations>

                    <div class="row">
                      <div class="col-12">
                        <div class="form-group" *ngFor="let education of candidate.educations.visible; let i = index">
                          <div *ngIf="!edit.education">
                            <div class="row card-block">
                              <div class="col-12">
                                <div class="card-body-title-basic">
                                  {{education.degree_name}}
                                </div>
                                <div class="card-body-content">
                                  {{education.major}} {{education.university ? '@ '+ education.university : ''}}
                                </div>
                                <div class="card-body-content">
                                  <div *ngIf="education.start_date || education.end_date">
                                    {{(education.start_date) ? (education.start_date |
                                    dateDiffTimestampNav:education.start_date) + ' – ' : ''}}
                                    {{(education.end_date) ? (education.end_date |
                                    dateDiffTimestampNav:education.end_date) :
                                    globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.present}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="edit.education">
                            <div class="card card-preview">
                              <div class="row">
                                <div class="col-12">
                                  <a *ngIf="globals.checkIfExists('permission', 'edit_candidates')" class="pull-right"
                                    (click)="deleteEducation(education.uuid, i, candidate.educations.visible)">
                                    <i class="tt-icon-lg icon-ttdelete secondary-color" data-toggle="tooltip"
                                      data-placement="bottom" [title]="globals.labels.tooltips.global.delete"></i>
                                  </a>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      University *
                                    </div>
                                    <div class="card-body-content">
                                      <input type="text" class="form-control" placeholder="Insert university"
                                        [(ngModel)]="education.university" [ngModelOptions]="{standalone: true}"
                                        appOnFocusOut
                                        (focusout)="changed && education.university ? editEducation('university', education.university ? education.university : null, education.uuid, true): education.university ? '': toastr.warning('University is mandatory')"
                                        (ngModelChange)="edit.education ? onChange($event) : ''">
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      Degree *
                                    </div>
                                    <div class="card-body-content">
                                      <input type="text" class="form-control" placeholder="Insert degree"
                                        [(ngModel)]="education.degree_name" [ngModelOptions]="{standalone: true}"
                                        appOnFocusOut
                                        (focusout)="changed && education.degree_name ? editEducation('degree_name', education.degree_name ? education.degree_name : null, education.uuid, true):education.degree_name ? '' : toastr.warning('Degree is mandatory')"
                                        (ngModelChange)="edit.education ? onChange($event) : ''">
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      Major
                                    </div>
                                    <div class="card-body-content">
                                      <input type="text" class="form-control" placeholder="Insert major"
                                        [(ngModel)]="education.major" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                        (focusout)="changed ? editEducation('major', education.major ? education.major : null, education.uuid, true):''"
                                        (ngModelChange)="edit.education ? onChange($event) : ''">
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Start date
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <input type="date"
                                        [ngModel]="education.start_date ? (education.start_date | timestampToDatepicker:education.start_date) : null"
                                        [ngModelOptions]="{standalone: true}" appOnFocusOut (focusout)="changed ? editEducation('start_date', education.startDateTimestamp ? education.startDateTimestamp : null,
                                  education.uuid, true, i, candidate.educations.visible):''"
                                        (ngModelChange)="edit.education ? onChange(education.startDateTimestamp = $event) : ''"
                                        class="form-control">
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        End date
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <input type="date"
                                        [ngModel]="education.end_date ? (education.end_date | timestampToDatepicker:education.end_date) : null"
                                        [ngModelOptions]="{standalone: true}"  appOnFocusOut (focusout)="changed ? editEducation('end_date', education.endDateTimestamp ? education.endDateTimestamp : null,
                                  education.uuid, true, i, candidate.educations.visible):''"
                                        (ngModelChange)="edit.education ? onChange(education.endDateTimestamp = $event) : ''"
                                        class="form-control">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="!candidate.showEducation">
                          <div class="form-group" *ngFor="let education of candidate.educations.hidden; let i = index">
                            <div *ngIf="!edit.education">
                              <div class="row card-block">
                                <div class="col-12">
                                  <div class="card-body-title-basic">
                                    {{education.degree_name}}
                                  </div>
                                  <div class="card-body-content">
                                    {{education.major}} {{education.university ? '@ '+ education.university : ''}}
                                  </div>
                                  <div class="card-body-content">
                                    <div *ngIf="education.start_date || education.end_date">
                                      {{(education.start_date) ? (education.start_date |
                                      dateDiffTimestampNav:education.start_date) + ' – ' : ''}}
                                      {{(education.end_date) ? (education.end_date |
                                      dateDiffTimestampNav:education.end_date) :
                                      globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.present}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="edit.education">
                              <div class="card card-preview">
                                <div class="row">
                                  <div class="col-12">
                                    <a *ngIf="globals.checkIfExists('permission', 'edit_candidates')" class="pull-right"
                                      (click)="deleteEducation(education.uuid, i, candidate.educations.hidden)">
                                      <i class="tt-icon-lg icon-ttdelete secondary-color" data-toggle="tooltip"
                                        data-placement="bottom" [title]="globals.labels.tooltips.global.delete"></i>
                                    </a>
                                  </div>
                                  <div class="col-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        University *
                                      </div>
                                      <div class="card-body-content">
                                        <input type="text" class="form-control" placeholder="Insert university"
                                          [(ngModel)]="education.university" [ngModelOptions]="{standalone: true}"
                                          appOnFocusOut
                                          (focusout)="changed && education.university ? editEducation('university', education.university ? education.university : null, education.uuid, false):education.university ? '': toastr.warning('University is mandatory')"
                                          (ngModelChange)="edit.education ? onChange($event) : ''">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        Degree *
                                      </div>
                                      <div class="card-body-content">
                                        <input type="text" class="form-control" placeholder="Insert degree"
                                          [(ngModel)]="education.degree_name" [ngModelOptions]="{standalone: true}"
                                          appOnFocusOut
                                          (focusout)="changed && education.degree_name ? editEducation('degree_name', education.degree_name ? education.degree_name : null, education.uuid, false):education.degree_name ? '' : toastr.warning('Degree is mandatory')"
                                          (ngModelChange)="edit.education ? onChange($event) : ''">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        Major
                                      </div>
                                      <div class="card-body-content">
                                        <input type="text" class="form-control" placeholder="Insert major"
                                          [(ngModel)]="education.major" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                          (focusout)="changed ? editEducation('major', education.major ? education.major : null, education.uuid, false):''"
                                          (ngModelChange)="edit.education ? onChange($event) : ''">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Start date
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="date"
                                          [ngModel]="education.start_date ? (education.start_date | timestampToDatepicker:education.start_date) : null"
                                          [ngModelOptions]="{standalone: true}" appOnFocusOut (focusout)="changed ? editEducation('start_date', education.startDateTimestamp ? education.startDateTimestamp : null,
                                        education.uuid, false, i, candidate.educations.hidden):''"
                                          (ngModelChange)="edit.education ? onChange(education.startDateTimestamp = $event) : ''"
                                          class="form-control">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          End date
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="date"
                                          [ngModel]="education.end_date ? (education.end_date | timestampToDatepicker:education.end_date) : null"
                                          [ngModelOptions]="{standalone: true}" appOnFocusOut (focusout)="changed ? editEducation('end_date', education.endDateTimestamp ? education.endDateTimestamp : null,
                                        education.uuid, false, i, candidate.educations.hidden):''"
                                          (ngModelChange)="edit.education ? onChange(education.endDateTimestamp = $event) : ''"
                                          class="form-control">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group secondary-color sm-font card-block"
                          *ngIf="candidate.educations.hidden.length && !edit.education">
                          <a (click)="this.candidate.showEducation = !this.candidate.showEducation">
                            {{candidate.showEducation ? 'show' : 'hide' }} more
                            <i class="icon ft-chevron-down"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #showEmptyEducations>
                    <div class="row card-block">
                      <div class="col-12">
                        No information available
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="language">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordion.language = !accordion.language">
                    <div class="form-section">
                      Languages
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates') && (candidate.languages.visible.length || candidate.languages.hidden.length)"
                        (click)="editFields('language'); edit.language ? candidate.showLanguage = false : candidate.showLanguage = true">
                        edit
                      </div>
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates')"
                        (click)="addForm('languageForm')">
                        add
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="add.languageForm">
                    <div class="form-group">
                      <div class="card card-preview">
                        <div class="row">
                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Language *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select class="ng-append" appendTo=".accordion" [items]="resources.language | async"
                                  bindLabel="description" [hideSelected]="true" [loading]="languageLoading"
                                  [typeahead]="languageinput"
                                  typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                                  [(ngModel)]="add.language.language" placeholder="Select language">
                                </ng-select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Level *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select class="ng-append" appendTo=".accordion" bindLabel="description"
                                  dropdownPosition="bottom" (open)="getLanguageLevel()" placeholder="Select level"
                                  [items]="resources.level" [(ngModel)]="add.language.level"
                                  [ngModelOptions]="{standalone: true}">
                                </ng-select>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="pull-right">
                              <button type="button" class="btn pull-right bg-secondary-color"
                                (click)="add.language.language ? (add.language.level ? addNewLanguage(add.language, candidate.uuid) : toastr.warning('Level is mandatory')) : toastr.warning('Language is mandatory')">
                                Add new language
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="candidate.languages.visible.length || candidate.languages.hidden.length; then showLanuages else showEmptyLaguages">
                  </div>
                  <ng-template #showLanuages>

                    <div class="row">
                      <div class="col-12">
                        <div class="form-group" *ngFor="let language of candidate.languages.visible;let i = index">
                          <div *ngIf="!edit.language">
                            <div class="row card-block">
                              <div class="col-12">
                                <strong> {{language.language.description}} </strong>
                                {{language.level && 'NO_VALUE' != language.level.value ? '- ' +
                                language.level.description
                                : ''}}
                              </div>
                            </div>
                          </div>
                          <div *ngIf="edit.language">
                            <div class="card card-preview">
                              <div class="row">
                                <div class="col-12">
                                  <a *ngIf="globals.checkIfExists('permission', 'edit_candidates')" class="pull-right"
                                    (click)="deleteLanguage(language.uuid, i, candidate.languages.visible)">
                                    <i class="tt-icon-lg icon-ttdelete secondary-color" data-toggle="tooltip"
                                      data-placement="bottom" [title]="globals.labels.tooltips.global.delete"></i>
                                  </a>
                                </div>
                                <div class="col-md-6 col-12">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Language *
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <ng-select class="ng-append" appendTo=".accordion"
                                        [items]="resources.language | async" bindLabel="description"
                                        [hideSelected]="true" [loading]="languageLoading" [typeahead]="languageinput"
                                        [(ngModel)]="language.language" placeholder="Select language"
                                        typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                                        (change)="changed && language.language ? editLanguage('language',language.language, language.uuid, true):language.language ? '' : toastr.warning('Language is mandatory')"
                                        (ngModelChange)="edit.language ? onChange($event) : ''">
                                      </ng-select>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 col-12">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Level *
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <ng-select class="ng-append" appendTo=".accordion" bindLabel="description"
                                        dropdownPosition="bottom" (open)="getLanguageLevel()" placeholder="Select level"
                                        [items]="resources.level" [(ngModel)]="language.level"
                                        [ngModelOptions]="{standalone: true}"
                                        (change)="changed && language.level ? editLanguage('level',language.level, language.uuid, true):''"
                                        (ngModelChange)="edit.language ? onChange($event) : ''">
                                      </ng-select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="!candidate.showLanguage">
                          <div class="form-group" *ngFor="let language of candidate.languages.hidden;let i = index">
                            <div *ngIf="!edit.language">
                              <div class="row card-block">
                                <div class="col-12">
                                  <strong> {{language.language.description}} </strong>
                                  {{language.level && 'NO_VALUE' != language.level.value ? '- ' +
                                  language.level.description : ''}}
                                </div>
                              </div>
                            </div>
                            <div *ngIf="edit.language">
                              <div class="card card-preview">
                                <div class="row">
                                  <div class="col-12">
                                    <a *ngIf="globals.checkIfExists('permission', 'edit_candidates')" class="pull-right"
                                      (click)="deleteLanguage(language.uuid, i, candidate.languages.hidden)">
                                      <i class="tt-icon-lg icon-ttdelete secondary-color" data-toggle="tooltip"
                                        data-placement="bottom" [title]="globals.labels.tooltips.global.delete"></i>
                                    </a>
                                  </div>
                                  <div class="col-md-6 col-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Language *
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <ng-select class="ng-append" appendTo=".accordion"
                                          [items]="resources.language | async" bindLabel="description"
                                          [hideSelected]="true" [loading]="languageLoading" [typeahead]="languageinput"
                                          [(ngModel)]="language.language" placeholder="Select language"
                                          typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                                          (change)="changed && language.language ? editLanguage('language',language.language, language.uuid, false):language.language ? '': toastr.warning('Language is mandatory')"
                                          (ngModelChange)="edit.language ? onChange($event) : ''">
                                        </ng-select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Level *
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <ng-select class="ng-append" appendTo=".accordion" bindLabel="description"
                                          dropdownPosition="bottom" (open)="getLanguageLevel()"
                                          placeholder="Select level" [items]="resources.level"
                                          [(ngModel)]="language.level" [ngModelOptions]="{standalone: true}"
                                          (change)="changed && language.level? editLanguage('level',language.level, language.uuid, false):''"
                                          (ngModelChange)="edit.language ? onChange($event) : ''">
                                        </ng-select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group secondary-color sm-font card-block"
                          *ngIf="candidate.languages.hidden.length && !edit.language">
                          <a (click)="this.candidate.showLanguage = !this.candidate.showLanguage">
                            {{candidate.showLanguage ? 'show' : 'hide' }} more
                            <i class="icon ft-chevron-down"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #showEmptyLaguages>
                    <div class="row card-block">
                      <div class="col-12">
                        No information available
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="miscellaneous">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordion.miscellaneous = !accordion.miscellaneous">
                    <div class="form-section">
                      Miscellaneous
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates')"
                        (click)="editFields('miscellaneous')">
                        edit
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="candidate.miscellaneous.skills.length || candidate.miscellaneous.publications.length || candidate.miscellaneous.patents.length
                 || candidate.miscellaneous.certifications.length || candidate.miscellaneous.honors_awards.length || edit.miscellaneous
                ;then ShowMiscellaneous else ShowEmptyMiscellaneous"></div>
                  <ng-template #ShowMiscellaneous>
                    <div class="row card-block">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic"
                            *ngIf="(candidate.miscellaneous.skills.length && !edit.miscellaneous) || edit.miscellaneous">
                            <label>
                              Skills
                            </label>
                          </div>
                          <div class="card-body-content"
                            *ngIf="candidate.miscellaneous.skills.length && !edit.miscellaneous">
                            <span *ngFor="let i of candidate.miscellaneous.skills;let last = last">
                              {{i}}{{last? '':', '}}
                            </span>
                          </div>
                          <div class="card-body-content" *ngIf="edit.miscellaneous">
                            <div class="row">
                              <div class="col-12 form-group">
                                <input class="form-control" type="text" [(ngModel)]="candidate.miscellaneous.skill"
                                  [ngModelOptions]="{standalone: true}" placeholder="+ skill"
                                  (keyup.enter)="candidate.miscellaneous.skills.push( candidate.miscellaneous.skill);
                                    candidate.miscellaneous.skill = '';editCandidate('miscellaneous.skills',candidate.miscellaneous.skills, candidate.uuid)" />
                              </div>
                              <div class="col-12 form-group">
                                <tag-input id="expertise-input" [(ngModel)]="candidate.miscellaneous.skills"
                                  [ngModelOptions]="{standalone: true}"
                                  (ngModelChange)="editCandidate('miscellaneous.skills',candidate.miscellaneous.skills, candidate.uuid)">
                                </tag-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="card-body-title-basic"
                            *ngIf="(candidate.miscellaneous.publications.length && !edit.miscellaneous) || edit.miscellaneous">
                            <label>
                              Publications
                            </label>
                          </div>
                          <div class="card-body-content"
                            *ngIf="candidate.miscellaneous.publications.length && !edit.miscellaneous">
                            <span *ngFor="let i of candidate.miscellaneous.publications;let last = last">
                              {{i}}{{last? '':', '}}
                            </span>
                          </div>
                          <div class="card-body-content" *ngIf="edit.miscellaneous">
                            <div class="row">
                              <div class="col-12 form-group">
                                <input class="form-control" type="text"
                                  [(ngModel)]="candidate.miscellaneous.publication"
                                  [ngModelOptions]="{standalone: true}" placeholder="+ publication"
                                  (keyup.enter)="candidate.miscellaneous.publications.push(candidate.miscellaneous.publication);
                                      candidate.miscellaneous.publication = '';editCandidate('miscellaneous.publications',candidate.miscellaneous.publications, candidate.uuid)" />
                              </div>
                              <div class="col-12 form-group">
                                <tag-input id="expertise-input" [(ngModel)]="candidate.miscellaneous.publications"
                                  [ngModelOptions]="{standalone: true}"
                                  (ngModelChange)="editCandidate('miscellaneous.publications',candidate.miscellaneous.publications, candidate.uuid)">
                                </tag-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="card-body-title-basic"
                            *ngIf="(candidate.miscellaneous.patents.length && !edit.miscellaneous) || edit.miscellaneous">
                            <label>
                              Patents
                            </label>
                          </div>
                          <div class="card-body-content"
                            *ngIf="candidate.miscellaneous.patents.length && !edit.miscellaneous">
                            <span *ngFor="let i of candidate.miscellaneous.patents;let last = last">
                              {{i}}{{last? '':', '}}
                            </span>
                          </div>
                          <div class="card-body-content" *ngIf="edit.miscellaneous">
                            <div class="row">
                              <div class="col-12 form-group">
                                <input class="form-control" type="text" [(ngModel)]="candidate.miscellaneous.patent"
                                  [ngModelOptions]="{standalone: true}" placeholder="+ patent"
                                  (keyup.enter)="candidate.miscellaneous.patents.push(candidate.miscellaneous.patent);
                                        candidate.miscellaneous.patent = '';editCandidate('miscellaneous.patents',candidate.miscellaneous.patents, candidate.uuid)" />
                              </div>
                              <div class="col-12 form-group">
                                <tag-input id="expertise-input" [(ngModel)]="candidate.miscellaneous.patents"
                                  [ngModelOptions]="{standalone: true}"
                                  (ngModelChange)="editCandidate('miscellaneous.patents',candidate.miscellaneous.patents, candidate.uuid)">
                                </tag-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="card-body-title-basic"
                            *ngIf="(candidate.miscellaneous.certifications.length && !edit.miscellaneous) || edit.miscellaneous">
                            <label>
                              Certifications
                            </label>
                          </div>
                          <div class="card-body-content"
                            *ngIf="candidate.miscellaneous.certifications.length && !edit.miscellaneous">
                            <span *ngFor="let i of candidate.miscellaneous.certifications;let last = last">
                              {{i}}{{last? '':', '}}
                            </span>
                          </div>
                          <div class="card-body-content" *ngIf="edit.miscellaneous">
                            <div class="row">
                              <div class="col-12 form-group">
                                <input class="form-control" type="text"
                                  [(ngModel)]="candidate.miscellaneous.certification"
                                  [ngModelOptions]="{standalone: true}" placeholder="+ certification"
                                  (keyup.enter)="candidate.miscellaneous.certifications.push(candidate.miscellaneous.certification);
                                          candidate.miscellaneous.certification = '';editCandidate('miscellaneous.certifications',candidate.miscellaneous.certifications, candidate.uuid)" />
                              </div>
                              <div class="col-12 form-group">
                                <tag-input id="expertise-input" [(ngModel)]="candidate.miscellaneous.certifications"
                                  [ngModelOptions]="{standalone: true}"
                                  (ngModelChange)="editCandidate('miscellaneous.certifications',candidate.miscellaneous.certifications, candidate.uuid)">
                                </tag-input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="card-body-title-basic"
                            *ngIf="(candidate.miscellaneous.honors_awards.length && !edit.miscellaneous) || edit.miscellaneous">
                            <label>
                              Honors Awards
                            </label>
                          </div>
                          <div class="card-body-content"
                            *ngIf="candidate.miscellaneous.honors_awards.length && !edit.miscellaneous">
                            <span *ngFor="let i of candidate.miscellaneous.honors_awards;let last = last">
                              {{i}}{{last? '':', '}}
                            </span>
                          </div>
                          <div class="card-body-content" *ngIf="edit.miscellaneous">
                            <div class="row">
                              <div class="col-12 form-group">
                                <input class="form-control" type="text"
                                  [(ngModel)]="candidate.miscellaneous.honors_award"
                                  [ngModelOptions]="{standalone: true}" placeholder="+ honors award"
                                  (keyup.enter)="candidate.miscellaneous.honors_awards.push(candidate.miscellaneous.honors_award);
                                            candidate.miscellaneous.honors_award = '';editCandidate('miscellaneous.honors_awards',candidate.miscellaneous.honors_awards, candidate.uuid)" />
                              </div>
                              <div class="col-12 form-group">
                                <tag-input id="expertise-input" [(ngModel)]="candidate.miscellaneous.honors_awards"
                                  [ngModelOptions]="{standalone: true}"
                                  (ngModelChange)="editCandidate('miscellaneous.honors_awards',candidate.miscellaneous.honors_awards, candidate.uuid)">
                                </tag-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #ShowEmptyMiscellaneous>
                    <div class="row card-block">
                      <div class="col-12">
                        No information available
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="consent" *ngIf="globals.checkIfExists('permission', 'edit_candidates')">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordion.consent = !accordion.consent">
                    <div class="form-section">
                      Consent
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-1"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates')" (click)="editFields('consent')">
                        edit
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="edit.consent">
                    <div class="row card-block">
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Obtained at
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="date" [(ngModel)]="candidate.consent.obtained_at"
                              [ngModelOptions]="{standalone: true}" class="form-control" appOnFocusOut
                              (focusout)="changed ? editCandidate('consent.obtained_at',candidate.consent.obtained_at, candidate.uuid): ''"
                              (ngModelChange)="edit.consent ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Obtained through type
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select bindLabel="description" dropdownPosition="bottom"
                              placeholder="Select obtained through type" (open)="getObtainedType()"
                              [items]="resources.obtained_type" [(ngModel)]="candidate.consent.obtained_through_type"
                              (change)="changed ? editCandidate('consent.obtained_through_type',candidate.consent.obtained_through_type , candidate.uuid):''"
                              (ngModelChange)="edit.consent ? onChange($event) : ''"></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Type
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select type"
                              (open)="getConsentType()" [items]="resources.consent_type"
                              [(ngModel)]="candidate.consent.type"
                              (change)="changed ? editCandidate('consent.type',candidate.consent.type , candidate.uuid):''"
                              (ngModelChange)="edit.consent ? onChange($event) : ''"></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Note
                            </label>
                          </div>
                          <div class="card-body-content">
                            <quill-editor placeholder="Insert note" class="form-control"
                              [(ngModel)]="candidate.consent.note" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed ? editCandidate('consent.note',candidate.consent.note, candidate.uuid): ''"
                              (ngModelChange)="edit.consent ? onChange($event) : ''"></quill-editor>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div *ngIf="!edit.consent">
                    <div class="row card-block">
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Obtained at
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.consent && candidate.consent.obtained_at ? candidate.consent.obtained_at : 'No
                            information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Obtained through type
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.consent && candidate.consent.obtained_through_type ?
                            candidate.consent.obtained_through_type.description: 'No information available' }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Type
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.consent && candidate.consent.type ? candidate.consent.type.description : 'No
                            information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Note
                            </label>
                          </div>
                          <div class="card-body-content"
                            [innerHTML]="candidate.consent && candidate.consent.note ? candidate.consent.note : 'No information available' ">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-1'" *ngIf="globals.checkIfExists('group', 'internal')">
        <a ngbNavLink>Jobs</a>
        <ng-template ngbNavContent>
          <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" #acc="ngbAccordion"
              class="ng-accordion-append" (panelChange)="preventDefault ? beforeChange($event) : ''">
              <ngb-panel id="addTojob" *ngIf="globals.checkIfExists('permission', 'action_candidates')">
                <ng-template ngbPanelHeader>
                  <div class="form">
                    <div class="form-section">
                      Add to job
                    </div>
                  </div>
                  <div class="row card-block card-body">
                    <div class="col-6">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                            Job
                          </label>
                        </div>
                        <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="job_title"
                          dropdownPosition="bottom" (open)="getJobs('open', null)" placeholder="Select open job"
                          [items]="resources.jobs.data" [(ngModel)]="addTojob.job"
                          [ngModelOptions]="{standalone: true}">
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="addTojob.job">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                            Job Stage
                          </label>
                        </div>
                        <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="description"
                          dropdownPosition="bottom" placeholder="Select job stage" [items]="stage"
                          [(ngModel)]="addTojob.stage" [ngModelOptions]="{standalone: true}"
                          (open)="openStageDropdown()">
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="addTojob.stage && addTojob.stage.value == 'longlist'">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                            Job substatus
                          </label>
                        </div>
                        <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="description"
                          dropdownPosition="bottom"
                          [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.job.sub_status"
                          [items]="resources.initialSubStatuses" [(ngModel)]="addTojob.substatus" [clearable]="false"
                          [ngModelOptions]="{standalone: true}" (open)="getInitialSubStatuses()">
                        </ng-select>
                      </div>
                    </div>
                    <div [ngClass]="ratingCSS(addTojob)" *ngIf="addTojob.job">
                      <div class="card-body-title-basic">
                        <label> Candidate rating </label>
                      </div>
                      <div class="card-body-content">
                        <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="description"
                          dropdownPosition="bottom" placeholder="Select candidate rating"
                          [items]="resources.candidate_application_rating" [(ngModel)]="addTojob.candidate_rating"
                          [ngModelOptions]="{standalone: true}"
                          (open)="getResourcesByType('candidate_application_rating')" (clear)="addTojob.comment = null">
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-12" *ngIf="addTojob.stage">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Comment
                          </label>
                        </div>
                        <textarea rows="2" class="form-control" type="text" [(ngModel)]="addTojob.comment"
                          [ngModelOptions]="{standalone: true}" placeholder="Add Comment"></textarea>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <button type="button" class="btn pull-right bg-secondary-color col-3"
                          (click)="addCandidateToJob()">
                          Add to Job</button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="navis1Searches">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('navis1Searches')">
                    <div class="form-section">
                      Navis1 searches
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="card card-top pl-0">
                    <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" #accordionJobs="ngbAccordion"
                      class="ng-accordion-append" (panelChange)="preventDefault ? beforeChange($event) : ''"
                      *ngIf="candidate.job_history.visible || candidate.job_history.hidden; else emptyNavis1History">
                      <ngb-panel id="openJobs">
                        <ng-template ngbPanelHeader>
                          <div class="form" (click)="accordionJobs.toggle('openJobs');accordion.openJobs = !accordion.openJobs">
                            <div class="form-section">
                              Open jobs
                              <i class="pull-right mt-1 pt-2"
                                [ngClass]="{'ft-chevron-right': !accordion.openJobs,'ft-chevron-down': accordion.openJobs}"></i>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div *ngIf="candidate.job_history.visible.length;then showOpenJobs else showEmptyOpenJobs">
                          </div>
                          <ng-template #showOpenJobs>
                            <div class="row card-block">
                              <div class="col-12">
                                <div *ngFor="let openJob of candidate.job_history.visible">
                                  <div class="card-body-title-basic">
                                    <div class="form-group display-inline-flex">
                                      <a (click)="navidateToOverview(openJob.job_uuid, openJob.job)"><u>{{openJob.job}}</u></a>
                                      <a *ngIf="globals.checkIfExists('permission', 'edit_candidates')"
                                        (click)="updateApplication(openJob)">
                                        <i class="tt-icon-sm icon-note ml-2" data-toggle="tooltip" data-placement="bottom"
                                          [title]="globals.labels.tooltips.global.edit"></i>
                                      </a>
                                      <span class="badge-tags-inverse ml-3 bg-success-color cursor-pointer"
                                        *ngIf="openJob.is_vetted"
                                        (click)="navigateToCIT(openJob.cit_uuid, openJob.job_uuid, openJob.job)">
                                        Vetted
                                      </span>
                                      <span *ngIf="openJob.candidate_rating"
                                        [ngStyle]="{'background-color':openJob.candidate_rating.background_color, 'color':openJob.candidate_rating.text_color}"
                                        class="badge-tags-inverse nowrap align-self-center ml-2 display-inline-flex"
                                        title="{{openJob.candidate_rating.hover_data}}">
                                        {{openJob.candidate_rating.description}}
                                      </span>
        
                                    </div>
                                  </div>
                                  <div class="card-body-content">
                                    <div class="row"
                                      *ngIf="openJob.stage.value || (openJob.substatus && openJob.substatus.description)">
                                      <div class="col-6" *ngIf="openJob.stage.value">
                                        <div class="form-group">
                                          Stage
                                          <ng-template #tooltipOpen>
                                            <div class="card card-preview">
                                              <div class="card-body">
                                                <div class="card-body-title-basic">
                                                  Stage: {{openJob.stage.description}}
                                                </div>
                                                <div class="card-body-content">
                                                  <div *ngIf="openJob.shortlisted_date">
                                                    Shortlisted date:
                                                    {{openJob.shortlisted_date |
                                                    dateDiffTimestampFullNav:openJob.shortlisted_date}}
                                                  </div>
                                                  <div *ngIf="openJob.sent_date">
                                                    Sent date: {{openJob.sent_date |
                                                    dateDiffTimestampFullNav:openJob.sent_date}}
                                                  </div>
                                                  <div *ngIf="openJob.first_interview_date">
                                                    First interview date:
                                                    {{openJob.first_interview_date |
                                                    dateDiffTimestampFullNav:openJob.first_interview_date}}
                                                  </div>
                                                  <div *ngIf="openJob.second_interview_date">
                                                    Second interview date:
                                                    {{openJob.second_interview_date |
                                                    dateDiffTimestampFullNav:openJob.second_interview_date}}
                                                  </div>
                                                  <div *ngIf="openJob.offer_date">
                                                    Offer date:
                                                    {{openJob.offer_date | dateDiffTimestampFullNav:openJob.offer_date}}
                                                  </div>
                                                  <div *ngIf="openJob.placed_date">
                                                    Placed date:
                                                    {{openJob.placed_date | dateDiffTimestampFullNav:openJob.placed_date}}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </ng-template>
                                          <ng-template #t let-fill="fill" let-last="last" let-index="index">
                                            <span class="bullet" [class.full]="fill === 100">
                                              <span class="bullet"
                                                [ngClass]="fill === 100 && (openJob.substatus && openJob.substatus.success) ? 'success' : index == openJob.stage.current_stage-1 ? 'error' : 'success'"
                                                [style.width.%]="fill" [ngbTooltip]="tooltipOpen"  placement="bottom-left"
                                                [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                                              <span
                                                [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                                            </span>
                                          </ng-template>
                                          <ngb-rating [(rate)]="openJob.stage.current_stage" [starTemplate]="t"
                                            [readonly]="true" [max]="openJob.stage.max_stage">
                                          </ngb-rating>
                                        </div>
                                      </div>
                                      <div class="col-6" *ngIf="openJob.substatus && openJob.substatus.description">
                                        <div class="form-group">
                                          <span class="badge m-0" [ngStyle]="{'color': openJob.substatus.color_code}">
                                            <i class="fa fa-circle"></i>
                                          </span>
                                          <label>{{openJob.substatus.description}}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template #showEmptyOpenJobs>
                            <div class="row card-block">
                              <div class="col-12">
                                This candidate is not shortlisted on any ongoing jobs
                              </div>
                            </div>
                          </ng-template>
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel id="closedJobs">
                        <ng-template ngbPanelHeader>
                          <div class="form"
                            (click)="accordionJobs.toggle('closedJobs');accordion.closedJobs = !accordion.closedJobs">
                            <div class="form-section">
                              Closed jobs
                              <i class="pull-right mt-1 pt-2"
                                [ngClass]="{'ft-chevron-right': !accordion.closedJobs,'ft-chevron-down': accordion.closedJobs}"></i>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div *ngIf="candidate.job_history.hidden.length;then showClosedJobs else showEmptyClosedJobs">
                          </div>
                          <ng-template #showClosedJobs>
                            <div class="row card-block">
                              <div class="col-12">
                                <div *ngFor="let closedJob of candidate.job_history.hidden">
                                  <div class="card-body-title-basic">
                                    <div class="form-group display-inline-flex">
                                      <a
                                        (click)="navidateToOverview(closedJob.job_uuid, closedJob.job)"><u>{{closedJob.job}}</u></a>
                                      <span *ngIf="closedJob.candidate_rating"
                                        [ngStyle]="{'background-color':closedJob.candidate_rating.background_color, 'color':closedJob.candidate_rating.text_color}"
                                        class="badge-tags-inverse nowrap align-self-center ml-2 display-inline-flex"
                                        title="{{closedJob.candidate_rating.hover_data}}">
                                        {{closedJob.candidate_rating.description}}
                                      </span>
                                      <span class="badge-tags-inverse ml-3 bg-success-color cursor-pointer"
                                        *ngIf="closedJob.is_vetted"
                                        (click)="navigateToCIT(closedJob.cit_uuid, closedJob.job_uuid, closedJob.job)">
                                        Vetted
                                      </span>
                                    </div>
                                  </div>
                                  <div class="card-body-content">
                                    <div class="row"
                                      *ngIf="closedJob.stage.value || (closedJob.substatus && closedJob.substatus.description)">
                                      <div class="col-6" *ngIf="closedJob.stage.value">
                                        <div class="form-group">
                                          Stage
                                          <ng-template #tooltipClose>
                                            <div class="card card-preview">
                                              <div class="card-body">
                                                <div class="card-body-title-basic">
                                                  Stage: {{closedJob.stage.description}}
                                                </div>
                                                <div class="card-body-content">
                                                  <div *ngIf="closedJob.shortlisted_date">
                                                    Shortlisted date:
                                                    {{closedJob.shortlisted_date |
                                                    dateDiffTimestampFullNav:closedJob.shortlisted_date}}
                                                  </div>
                                                  <div *ngIf="closedJob.sent_date">
                                                    Sent date:
                                                    {{closedJob.sent_date | dateDiffTimestampFullNav:closedJob.sent_date}}
                                                  </div>
                                                  <div *ngIf="closedJob.first_interview_date">
                                                    First interview date:
                                                    {{closedJob.first_interview_date |
                                                    dateDiffTimestampFullNav:closedJob.first_interview_date}}
                                                  </div>
                                                  <div *ngIf="closedJob.second_interview_date">
                                                    Second interview date:
                                                    {{closedJob.second_interview_date |
                                                    dateDiffTimestampFullNav:closedJob.second_interview_date}}
                                                  </div>
                                                  <div *ngIf="closedJob.offer_date">
                                                    Offer date:
                                                    {{closedJob.offer_date | dateDiffTimestampFullNav:closedJob.offer_date}}
                                                  </div>
                                                  <div *ngIf="closedJob.placed_date">
                                                    Placed date:
                                                    {{closedJob.placed_date | dateDiffTimestampFullNav:closedJob.placed_date}}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </ng-template>
                                          <ng-template #t let-fill="fill" let-last="last" let-index="index">
                                            <span class="bullet" [class.full]="fill === 100">
                                              <span class="bullet"
                                                [ngClass]="fill === 100 && (closedJob.substatus && closedJob.substatus.success) ? 'success' : index == closedJob.stage.current_stage-1 ? 'error' : 'success'"
                                                [style.width.%]="fill" [ngbTooltip]="tooltipClose" placement="bottom-left"
                                                [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                                              <span
                                                [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                                            </span>
                                          </ng-template>
                                          <ngb-rating [(rate)]="closedJob.stage.current_stage" [starTemplate]="t"
                                            [readonly]="true" [max]="closedJob.stage.max_stage">
                                          </ngb-rating>
                                        </div>
                                      </div>
                                      <div class="col-6" *ngIf="closedJob.substatus && closedJob.substatus.description">
                                        <div class="form-group">
                                          <span class="badge m-0" [ngStyle]="{'color': closedJob.substatus.color_code}">
                                            <i class="fa fa-circle"></i>
                                          </span>
                                          <label>{{closedJob.substatus.description}}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template #showEmptyClosedJobs>
                            <div class="row card-block">
                              <div class="col-12">
                                No information available
                              </div>
                            </div>
                          </ng-template>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                    <ng-template #emptyNavis1History>
                      <div class="row card-block">
                        <div class="col-12">
                          This candidate is not part of Navis1 searches
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="navisSearches">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('navisSearches')">
                    <div class="form-section">
                      Navis searches
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="card card-top pl-0">
                    <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" #accordionJobs="ngbAccordion"
                    class="ng-accordion-append" (panelChange)="preventDefault ? beforeChange($event) : ''"
                    *ngIf="candidate.navis_jobs; else showEmptyNavisJobs">
                      <ngb-panel id="openNavisJobs">
                        <ng-template ngbPanelHeader>
                          <div class="form" (click)="accordionJobs.toggle('openNavisJobs');accordion.openNavisJobs = !accordion.openNavisJobs">
                            <div class="form-section">
                              Open jobs
                              <i class="pull-right mt-1 pt-2"
                                [ngClass]="{'ft-chevron-right': !accordion.openNavisJobs,'ft-chevron-down': accordion.openNavisJobs}"></i>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div *ngIf="candidate.navis_jobs.open_roles && candidate.navis_jobs.open_roles.length; else showEmptyNavisOpenJobs">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group" *ngFor="let job of candidate.navis_jobs.open_roles;let i = index"> 
                                  <div class="card card-preview" >
                                    <div class="row">
                                      <div class="col-11">
                                        <div class="card-body-title-basic">
                                          <div class="form-group display-inline-flex">
                                            <span>{{job.title + (job.company?.name ? ' @ ' + job.company.name: '')}}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-sm-12">
                                        <div class="form-group">
                                          <div class="card-body-title-basic">
                                            <label>
                                              Status
                                            </label>
                                          </div>
                                          <div class="card-body-content">
                                            {{job.current_stage ? job.current_stage.description : "No information available"}}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-sm-12">
                                        <div class="form-group">
                                          <div class="card-body-title-basic">
                                            <label>
                                              Substatus
                                            </label>
                                          </div>
                                          <div class="card-body-content">
                                            {{job.substatus ? job.substatus.description : "No information available"}}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ng-template #showEmptyNavisOpenJobs>
                            <div class="row card-block">
                              <div class="col-12">
                                No information available
                              </div>
                            </div>
                          </ng-template>
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel id="closedNavisJobs">
                        <ng-template ngbPanelHeader>
                          <div class="form"
                            (click)="accordionJobs.toggle('closedNavisJobs');accordion.closedNavisJobs = !accordion.closedNavisJobs">
                            <div class="form-section">
                              Closed jobs
                              <i class="pull-right mt-1 pt-2"
                                [ngClass]="{'ft-chevron-right': !accordion.closedNavisJobs,'ft-chevron-down': accordion.closedNavisJobs}"></i>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div *ngIf="candidate.navis_jobs.closed_roles && candidate.navis_jobs.closed_roles.length; else showEmptyNavisClosedJobs">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group" *ngFor="let job of candidate.navis_jobs.closed_roles;let i = index"> 
                                  <div class="card card-preview" >
                                    <div class="row">
                                      <div class="col-11">
                                        <div class="card-body-title-basic">
                                          <div class="form-group display-inline-flex">
                                            <span>{{job.title + (job.company?.name ? ' @ ' + job.company.name: '')}}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-sm-12">
                                        <div class="form-group">
                                          <div class="card-body-title-basic">
                                            <label>
                                              Status
                                            </label>
                                          </div>
                                          <div class="card-body-content">
                                            {{job.current_stage ? job.current_stage.description : "No information available"}}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-sm-12">
                                        <div class="form-group">
                                          <div class="card-body-title-basic">
                                            <label>
                                              Substatus
                                            </label>
                                          </div>
                                          <div class="card-body-content">
                                            {{job.substatus ? job.substatus.description : "No information available"}}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ng-template #showEmptyNavisClosedJobs>
                            <div class="row card-block">
                              <div class="col-12">
                                No information available
                              </div>
                            </div>
                          </ng-template>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                  </div>
                  <ng-template #showEmptyNavisJobs>
                    <div class="row card-block">
                      <div class="col-12">
                        This candidate is not part of Navis searches
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-2'" *ngIf="globals.checkIfExists('group', 'internal')">
        <a ngbNavLink>Files</a>
        <ng-template ngbNavContent>
          <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" class="ng-accordion-append"
              (panelChange)="preventDefault ? beforeChange($event) : ''">
              <ngb-panel id="files">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordion.files = !accordion.files">
                    <div class="form-section">
                      Files
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates') && candidate.files.length"
                        (click)="editFields('files')">
                        edit
                      </div>
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                        *ngIf="globals.checkIfExists('permission', 'edit_candidates')" (click)="addForm('fileForm')">
                        add
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="add.fileForm">
                    <div class="form-group">
                      <div class="card card-preview">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  {{globals.labels.placeholders.shared_page.add_job.file_type}}
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select class="ng-append" appendTo=".ng-accordion-append" [(ngModel)]="add.file.type"
                                  [ngModelOptions]="{standalone: true}" bindLabel="description"
                                  [items]="resources.fileType" dropdownPosition="bottom"
                                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.file_type"
                                  (open)="getFileType()">
                                </ng-select>
                              </div>
                            </div>
                            <div class="form-group" *ngIf="'cv/resume' == add.file.type.value">
                              <div class="card-body-content">
                                <div class="custom-control custom-checkbox m-0">
                                  <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="add.file.is_primary" class="custom-control-input" id="primary-CV">
                                  <label class="custom-control-label" for="primary-CV">Primary CV
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="form-group" *ngIf="add.file.type.value">
                              <div class="card-body-content">
                                <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                                  (fileOver)="fileOverBaseFile($event)" [uploader]="uploaderFile"
                                  class="p-3 text-center grey my-drop-zone">
                                  <span [innerHTML]="globals.labels.placeholders.global.drag_and_drop"></span>
                                  <button class="btn bg-primary-color col-md-3 col-lg-3 col-xl-3 btn-position"
                                    (click)="fileInputFile.click()">
                                    Upload file
                                  </button>
                                </div>
                                <input class="hiddenInput m-0 p-0 position-absolute overflow-hidden" type="file"
                                  ng2FileSelect [uploader]="uploaderFile" #fileInputFile
                                  (change)="OnContentChangeFile($event)">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="candidate.files.length; then showFiles else showEmptyFiles"></div>
                  <ng-template #showFiles>
                    <div class="form-group" *ngFor="let file of candidate.files;let i = index">
                      <div *ngIf="edit.files">
                        <div class="card card-preview">
                          <div class="row">
                            <div class="col-12">
                              <a *ngIf="globals.checkIfExists('permission', 'edit_candidates')" class="pull-right"
                                (click)="deleteFile(file.candidate_asset_uuid, i)">
                                <i class="tt-icon-lg icon-ttdelete secondary-color" data-toggle="tooltip"
                                  data-placement="bottom" [title]="globals.labels.tooltips.global.delete"></i>
                              </a>
                            </div>
                            <div class="col-12">
                              <div class="form-group">
                                <div class="card-body-title-basic">
                                  File type
                                </div>
                                <div class="card-body-content">
                                  <ng-select class="ng-append" appendTo=".ng-accordion-append" [(ngModel)]="file.type"
                                    [ngModelOptions]="{standalone: true}" bindLabel="description"
                                    [items]="resources.fileType" dropdownPosition="bottom"
                                    [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.file_type"
                                    (open)="getFileType()"
                                    (change)="changed && file.type ? editFile('type', file.type, file.candidate_asset_uuid): file.type ? '' :toastr.warning('File type is mandatory')"
                                    (ngModelChange)="edit.files ? onChange($event) : ''">
                                  </ng-select>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="card-body-title-basic">
                                  File name
                                </div>
                                <div class="card-body-content">
                                  <div class="card-body-content full-width display-inline-flex">
                                    <input type="text" class="form-control col-11" placeholder="Insert file name"
                                      [ngModel]="file.original_name.substr(0, file.original_name.indexOf('.'))"
                                      [ngModelOptions]="{standalone: true}" appOnFocusOut
                                      (focusout)="changed && file.name ? removeSpacesFileName(file) : file.name ? '':toastr.warning('File name is mandatory')"
                                      (ngModelChange)="edit.files ? onChange(file.name = $event) : ''">
                                    <div class="col-1 align-self-center text-center">
                                      {{file.original_name.substr(file.original_name.lastIndexOf('.'))}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group" *ngIf="'cv/resume' == file.type.value">
                                <div class="card-body-content">
                                  <div class="custom-control custom-checkbox m-0">
                                    <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                      [(ngModel)]="file.is_primary" class="custom-control-input" id="primary-CV+{{i}}"
                                      (change)="editPrimaryCV($event, file)">
                                    <label class="custom-control-label" for="primary-CV+{{i}}">Primary CV
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!edit.files">
                        <div class="form-group">
                          <div class="row card-block">
                            <div class="col-1">
                              <a [href]="file.url" target="_blank">
                                <div *ngIf="file.original_name">
                                  <div
                                    [ngSwitch]="file.original_name.substr(file.original_name.lastIndexOf('.')+1).toLowerCase()">
                                    <div *ngSwitchCase="'pdf'">
                                      <i class="fa fa-file-pdf-o tt-icon-xl primary-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'xlsx'">
                                      <i class="fa fa-file-excel-o tt-icon-xl text-success m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'doc'">
                                      <i class="fa fa-file-word-o tt-icon-xl big-search-primary-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'docx'">
                                      <i class="fa fa-file-word-o tt-icon-xl big-search-primary-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'pptx'">
                                      <i class="fa fa-file-powerpoint-o tt-icon-xl warning-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'png'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'jpg'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'jpeg'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchDefault>
                                      <i class="fa fa-file-o tt-icon-xl m-2" aria-hidden="true"></i>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div class="col-10">
                              <div class="form-group">
                                <div class="card-body-title-basic" *ngIf="file.type.description">
                                  {{file.type.description}}
                                  <span *ngIf="file.is_primary && 'cv/resume' == file.type.value"
                                    class="tag secondary-color ml-5">
                                    <em>Primary CV/Resume</em>
                                  </span>
                                </div>
                              </div>
                              <div class="form-group" *ngIf="file.original_name">
                                <div class="card-body-content">
                                  <a [href]="file.url" target="_blank" [ngClass]="{'secondary-color':file.url}">
                                    <u>{{file.original_name}}</u>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #showEmptyFiles>
                    <div class="row card-block">
                      <div class="col-12">
                        No information available
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-3'">
        <a ngbNavLink>Comments</a>
        <ng-template ngbNavContent>
          <div class="card-body" *ngIf="!comments.length">
            <div [ngClass]="{'scroll scroll-comments':innerWidth > 1200}">
              <div class="hcenter text-center">
                <i class="icon-ttjoboverview tt-icon-job "></i>
                <div class="card-body-content m-3">
                  Click below to create a note
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" *ngIf="comments.length">
            <div class="mb-2" [ngClass]="{'scroll scroll-comments':innerWidth > 1200}" #commentsDiv
              [scrollTop]="commentsDiv.scrollHeight">
              <div *ngFor="let comment of comments; let i = index">
                <div class="card-body">
                  <div class="card-block">
                    <div class="media">
                      <img class="media-object align-self-center d-flex height-40 width-40 rounded-circle"
                        [src]="comment.photo ? comment.photo : globals.avatar"
                        (error)="comment.photo = globals.avatar" />
                      <div class="media-body align-self-center pagination-centered">
                        <div class="media-body-title">{{comment.user}}
                          <span class="media-time-ago">{{comment.created_date | dateDiffNav:comment.created_date
                            }}</span>
                          <span class="display-inline-flex" *ngIf="comment.market_mapping_tag">
                            <div class="badge-tag ml-3 white"
                              [ngStyle]="{'background-color': comment.market_mapping_tag.color_code}">
                              {{comment.market_mapping_tag.description}}
                            </div>
                          </span>
                        </div>
                        <div [innerHTML]="comment.body"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div *ngIf="globals.checkIfExists('group', 'internal')" ngbDropdown placement="top-left"
              class="full-width display-inline-flex mt-2" #tagsDropDown="ngbDropdown">
              <div class="scroll tag-height" ngbDropdownMenu aria-labelledby="dropdownBasic2"
                [ngClass]="{'border': filteredUsers.length > 0}">
                <div *ngFor="let user of filteredUsers">
                  <div class="media p-2 tag-hover" (click)="chooseUser(user);tagsDropDown.close();">
                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle align-self-center"
                      [src]="user.photo ? user.photo : globals.avatar" (error)="user.photo = globals.avatar" />
                    <div class="media-body align-self-center">
                      <p class="media-body-title mb-0 ">
                        {{user.name}}
                      </p>
                      <p class="mb-0">
                        {{user.email ? user.email : globals.labels.placeholders.shared_page.add_job.empty_email}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <input class="form-control form-control-comments position-relative" id="dropdownBasic2" ngbDropdownToggle
                (keyup)="getTagName(newComment)"
                (keyup.enter)="disableSendBtn || onCommentSave(newComment)" type="text"
                [(ngModel)]="newComment" (click)="tagsDropDown.close();"
                placeholder="Click here to insert note. Use @ mentions to notify team members." />
              <div class="input-group-append">
                <span class="input-group-btn">
                  <a (click)="this.disableSendBtn || onCommentSave(newComment)">
                    <i class="fa p-3 icon-ttsend-button tt-icon-lg"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-4'"
        *ngIf="globals.checkIfExists('permission', 'read_activities') && globals.checkIfExists('group', 'internal')">
        <a ngbNavLink>Activity</a>
        <ng-template ngbNavContent>
          <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" #accordionActivity="ngbAccordion"
              class="ng-accordion-append" (panelChange)="preventDefault ? beforeChange($event) : ''">
              <ngb-panel id="filters">
                <ng-template ngbPanelHeader>
                  <div class="form"
                    (click)="accordionActivity.toggle('filters');accordion.filters = !accordion.filters">
                    <div class="form-section">
                      Filters
                      <i class="pull-right mt-1 pt-2"
                        [ngClass]="{'ft-chevron-right': !accordion.filters,'ft-chevron-down': accordion.filters}"></i>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="form-group">
                    <div class="card card-preview">
                      <div class="row">
                        <div class="col-md-5 col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Filter type
                              </label>
                            </div>
                            <div class="card-body-content row">
                              <div class="col-10">
                                <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="description"
                                  bindValue="value" [(ngModel)]="add.activityFilter.filter_type"
                                  [items]="resources.activity" bindValue="value" [ngModelOptions]="{standalone: true}"
                                  [clearable]="false" (change)="getActivity(true, add.activityFilter.filter_type)"
                                  dropdownPosition="bottom" placeholder="Select filter type">
                                </ng-select>
                              </div>
                              <div class="col-2">
                                <div *ngIf="add.activityFilter.filter_type" (clickOutside)="closePopContentFilters()">
                                  <ng-template #popContentFilters>
                                    <div class="mt-2">
                                      <ng-select class="ng-append" appendTo=".ng-accordion-append"
                                        bindLabel="description" placeholder="Select filter"
                                        (open)="getFilteredFilters(add.activityFilter.filter_type)"
                                        (change)="prepareFilterBody(add.activityFilter.selected_filter.value, [], add.activityFilter.filter_type)"
                                        dropdownPosition="bottom" [(ngModel)]="add.activityFilter.selected_filter"
                                        [ngModelOptions]="{standalone: true}"
                                        [items]="resources.filters">
                                      </ng-select>
                                    </div>
                                  </ng-template>
                                  <span class="input-group-btn">
                                    <a [ngbPopover]="popContentFilters" placement="bottom-left" #popOver="ngbPopover" [autoClose]="'outside'">
                                      <i class="fa fa-filter tt-icon-lg pt-2">
                                      </i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-7 col-12 pl-0"
                          *ngIf="add.activityFilter.selected_filter && add.activityFilter.selected_filter.tags">
                          <div class="form-group">
                            <div class="card-body-title-basic"
                              *ngIf="'users' != add.activityFilter.selected_filter.value">
                              <label>
                                Filters
                              </label>
                              <a data-toggle="tooltip" class="badge-tag badge-tag-clear ml-3" data-placement="bottom"
                                title="Clear all filters"
                                (click)="deleteFilter(add.activityFilter.selected_filter.value, true)">
                                Clear {{add.activityFilter.selected_filter.value}} filter
                              </a>
                            </div>
                            <div class="card-body-content">
                              <div [ngSwitch]="add.activityFilter.selected_filter.value">
                                <div *ngSwitchCase="'users'">
                                  <div class="row">
                                    <div class="col-md-6 col-12">
                                      <div class="custom-control custom-checkbox pb-1 mb-1">
                                        <input type="checkbox"
                                          [(ngModel)]="add.activityFilter.filters.users.internalBoolean" name="internal"
                                          id="internal" value="internal" class="custom-control-input"
                                          (ngModelChange)="changeUsersState(add.activityFilter.filters.users.internalBoolean, 'internal')">
                                        <label class="custom-control-label"
                                          for="internal"><strong>Internal</strong></label>
                                      </div>
                                      <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="name"
                                        placeholder="Select user" dropdownPosition="bottom"
                                        [(ngModel)]="add.activityFilter.filters.users.internal" [multiple]="true"
                                        [disabled]="!add.activityFilter.filters.users.internalBoolean"
                                        (open)="getUsers('internal')" [ngModelOptions]="{standalone: true}"
                                        [items]="resources.internal"
                                        (change)="getActivity(false, add.activityFilter.filter_type)">
                                        <ng-template ng-option-tmp let-item="item" let-index="i">
                                          <div class="media mb-1">
                                            <div class="media-body">
                                              <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                                              <p><i class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ?
                                                item.email :
                                                globals.labels.placeholders.shared_page.add_job.empty_email}}<br>
                                                <i class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ?
                                                item.job_title
                                                : globals.labels.placeholders.shared_page.add_job.empty_title}}
                                              </p>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </ng-select>
                                    </div>
                                    <div class="col-md-6 col-12">
                                      <div class="custom-control custom-checkbox pb-1 mb-1">
                                        <input type="checkbox"
                                          [(ngModel)]="add.activityFilter.filters.users.clientBoolean" name="client"
                                          id="client" value="client" class="custom-control-input"
                                          (ngModelChange)="changeUsersState(add.activityFilter.filters.users.clientBoolean, 'client')">
                                        <label class="custom-control-label" for="client"><strong>Client</strong></label>
                                      </div>
                                      <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="name"
                                        placeholder="Select client" (open)="getUsers('client')"
                                        dropdownPosition="bottom" [multiple]="true"
                                        [disabled]="!add.activityFilter.filters.users.clientBoolean"
                                        [(ngModel)]="add.activityFilter.filters.users.client"
                                        [ngModelOptions]="{standalone: true}" [items]="resources.client"
                                        (change)="getActivity(false, add.activityFilter.filter_type)">
                                        <ng-template ng-option-tmp let-item="item" let-index="i">
                                          <div class="media mb-1">
                                            <div class="media-body">
                                              <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                                              <p><i class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ?
                                                item.email :
                                                globals.labels.placeholders.shared_page.add_job.empty_email}}<br>
                                                <i class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ?
                                                item.job_title
                                                : globals.labels.placeholders.shared_page.add_job.empty_title}}
                                              </p>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </ng-select>
                                    </div>
                                  </div>
                                </div>
                                <div *ngSwitchCase="'events'">
                                  <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="description"
                                    (change)="getActivity(false, add.activityFilter.filter_type)"
                                    placeholder="Select event type" dropdownPosition="bottom" [multiple]="true"
                                    [(ngModel)]="add.activityFilter.filters.events"
                                    [ngModelOptions]="{standalone: true}"
                                    [items]="add.activityFilter.selected_filter.tags">
                                  </ng-select>
                                </div>
                                <div *ngSwitchCase="'origin'">
                                  <ng-select class="ng-append" appendTo=".ng-accordion-append"
                                    bindLabel="description"
                                    (change)="getActivity(false, add.activityFilter.filter_type)"
                                    placeholder="Select event type" dropdownPosition="bottom" [multiple]="true"
                                    [(ngModel)]="add.activityFilter.filters.origin"
                                    [ngModelOptions]="{standalone: true}"
                                    [items]="add.activityFilter.selected_filter.tags">
                                  </ng-select>
                                </div>
                                <div *ngSwitchCase="'from'">
                                  <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="name"
                                    placeholder="Select from contact" dropdownPosition="bottom"
                                    [(ngModel)]="add.activityFilter.filters.from" [multiple]="true"
                                    (open)="getUsers('internal')" [ngModelOptions]="{standalone: true}"
                                    [items]="resources.internal"
                                    (change)="getActivity(false, add.activityFilter.filter_type);changeTags('from')">
                                    <ng-template ng-option-tmp let-item="item" let-index="i">
                                      <div class="media mb-1">
                                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                          [src]="item.photo ? item.photo : globals.avatar"
                                          (error)="item.photo = globals.avatar" />
                                        <div class="media-body">
                                          <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                                          <p><i class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ?
                                            item.email :
                                            globals.labels.placeholders.shared_page.add_job.empty_email}}<br>
                                            <i class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ? item.job_title :
                                            globals.labels.placeholders.shared_page.add_job.empty_title}}
                                          </p>
                                        </div>
                                      </div>
                                    </ng-template>
                                  </ng-select>
                                </div>
                                <div *ngSwitchCase="'to'">
                                  <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="name"
                                    placeholder="Select to contact" dropdownPosition="bottom"
                                    [(ngModel)]="add.activityFilter.filters.to" [multiple]="true"
                                    (open)="getUsers('internal')" [ngModelOptions]="{standalone: true}"
                                    [items]="resources.internal"
                                    (change)="getActivity(false, add.activityFilter.filter_type);changeTags('to')">
                                    <ng-template ng-option-tmp let-item="item" let-index="i">
                                      <div class="media mb-1">
                                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                          [src]="item.photo ? item.photo : globals.avatar"
                                          (error)="item.photo = globals.avatar" />
                                        <div class="media-body">
                                          <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                                          <p><i class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ?
                                            item.email :
                                            globals.labels.placeholders.shared_page.add_job.empty_email}}<br>
                                            <i class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ? item.job_title :
                                            globals.labels.placeholders.shared_page.add_job.empty_title}}
                                          </p>
                                        </div>
                                      </div>
                                    </ng-template>
                                  </ng-select>
                                </div>
                                <div *ngSwitchCase="'jobs'">
                                  <ng-select class="ng-append" appendTo=".ng-accordion-append"
                                    bindLabel="job_title" placeholder="Select job"
                                    (open)="getJobs(null, candidate.uuid)" dropdownPosition="bottom" [multiple]="true"
                                    (change)="getActivity(false, add.activityFilter.filter_type)"
                                    [(ngModel)]="add.activityFilter.filters.jobs" [ngModelOptions]="{standalone: true}"
                                    [items]="resources.jobs.data">
                                  </ng-select>
                                </div>
                                <div *ngSwitchCase="'campaigns'">
                                  <ng-select class="ng-append" appendTo=".ng-accordion-append" bindLabel="title"
                                    placeholder="Select campaigns" (open)="getCampaigns()" dropdownPosition="bottom"
                                    [multiple]="true" [(ngModel)]="add.activityFilter.filters.campaigns"
                                    [ngModelOptions]="{standalone: true}" [items]="resources.campaigns"
                                    (change)="getActivity(false, add.activityFilter.filter_type)">
                                  </ng-select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngFor="let filter of objectKeys(add.activityFilter.filters);let i = index">
                        <div class="col-12">
                          <div class="form-group">
                            <div class="card-body-content">
                              <div *ngIf="'users'== filter;then showUser else showTags"></div>
                              <ng-template #showUser class="display-inline-flex">
                                <a (click)="showFilters(add.activityFilter.filter_type, filter)">
                                  <span class="badge-tag mr-3"
                                    [ngStyle]="{'background-color':getFilterColor(add.activityFilter.filter_type, filter),
                                'color':getContrastYIQ(getFilterColor(add.activityFilter.filter_type, filter).substr(1))}"
                                    *ngIf="add.activityFilter.filters.users.internalBoolean">
                                    <strong>Internal</strong>
                                  </span>
                                </a>
                                <a (click)="showFilters(add.activityFilter.filter_type, filter)">
                                  <span class="badge-tag mr-3"
                                    [ngStyle]="{'background-color':getFilterColor(add.activityFilter.filter_type, filter),
                                'color':getContrastYIQ(getFilterColor(add.activityFilter.filter_type, filter).substr(1))}"
                                    *ngIf="add.activityFilter.filters.users.clientBoolean">
                                    <strong>Client</strong>
                                  </span>
                                </a>
                                <div *ngFor="let value of add.activityFilter.filters[filter].internal;let j = index"
                                  class="display-inline-flex">
                                  <a (click)="showFilters(add.activityFilter.filter_type, filter)">
                                    <span class="badge-tag mr-3"
                                      [ngStyle]="{'background-color':getFilterColor(add.activityFilter.filter_type, filter),
                                  'color':getContrastYIQ(getFilterColor(add.activityFilter.filter_type, filter).substr(1))}">
                                      {{filter | removeUndersoreCapitalizeFirst}}: <strong> {{value.description}}
                                        {{value.email}}</strong>
                                    </span>
                                  </a>
                                </div>
                                <div *ngFor="let value of add.activityFilter.filters[filter].client;let j = index"
                                  class="display-inline-flex">
                                  <a (click)="showFilters(add.activityFilter.filter_type, filter)">
                                    <span class="badge-tag mr-3"
                                      [ngStyle]="{'background-color':getFilterColor(add.activityFilter.filter_type, filter),
                                  'color':getContrastYIQ(getFilterColor(add.activityFilter.filter_type, filter).substr(1))}">
                                      {{filter | removeUndersoreCapitalizeFirst}}: <strong> {{value.description}}
                                        {{value.email}} </strong>
                                    </span>
                                  </a>
                                </div>
                              </ng-template>
                              <ng-template #showTags class="display-inline-flex">
                                <a (click)="showFilters(add.activityFilter.filter_type, filter)">
                                  <div
                                    *ngIf="'from' == filter || 'to' == filter; then showEmailFilters else showOtherFilters">
                                  </div>
                                  <ng-template #showEmailFilters>
                                    <div *ngIf="'from' == filter && showFilterTag.from">
                                      <span class="badge-tag mt-2 mr-3"
                                        [ngStyle]="{'background-color':getFilterColor(add.activityFilter.filter_type, filter),
                                      'color':getContrastYIQ(getFilterColor(add.activityFilter.filter_type, filter).substr(1))}">
                                        {{filter | removeUndersoreCapitalizeFirst}} <strong>Internal</strong> to
                                        <strong>{{candidate.primary_email}}</strong>
                                      </span>
                                    </div>
                                    <div *ngIf="'to' == filter && showFilterTag.to">
                                      <span class="badge-tag mt-2 mr-3"
                                        [ngStyle]="{'background-color':getFilterColor(add.activityFilter.filter_type, filter),
                                      'color':getContrastYIQ(getFilterColor(add.activityFilter.filter_type, filter).substr(1))}">
                                        From <strong>{{candidate.primary_email}}</strong> to <strong>Internal</strong>
                                      </span>
                                    </div>
                                  </ng-template>
                                  <ng-template #showOtherFilters>
                                    <span class="badge-tag mt-2 mr-3"
                                      [ngStyle]="{'background-color':getFilterColor(add.activityFilter.filter_type, filter),
                                  'color':getContrastYIQ(getFilterColor(add.activityFilter.filter_type, filter).substr(1))}">
                                      <strong> {{filter | removeUndersoreCapitalizeFirst}} </strong>
                                    </span>
                                  </ng-template>
                                </a>
                                <div *ngFor="let value of add.activityFilter.filters[filter];let j = index"
                                  class="display-inline-flex">
                                  <a (click)="showFilters(add.activityFilter.filter_type, filter)">
                                    <div
                                      *ngIf="'from' == filter || 'to' == filter; then showEmailTags else showOtherTags">
                                    </div>
                                    <ng-template #showEmailTags>
                                      <div *ngIf="'from' == filter && !showFilterTag.from">
                                        <span class="badge-tag mr-3"
                                          [ngStyle]="{'background-color':value.color_code ? value.color_code : getFilterColor(add.activityFilter.filter_type, filter),
                                    'color':value.color_code ? getContrastYIQ(value.color_code.substr(1)): getContrastYIQ(getFilterColor(add.activityFilter.filter_type, filter).substr(1))}">
                                          {{filter | removeUndersoreCapitalizeFirst}} <strong>{{value.email}}</strong>
                                          to
                                          <strong>{{candidate.primary_email}}</strong>
                                        </span>
                                      </div>
                                      <div *ngIf="'to' == filter && !showFilterTag.to">
                                        <span class="badge-tag mr-3"
                                          [ngStyle]="{'background-color':value.color_code ? value.color_code : getFilterColor(add.activityFilter.filter_type, filter),
                                      'color':value.color_code ? getContrastYIQ(value.color_code.substr(1)): getContrastYIQ(getFilterColor(add.activityFilter.filter_type, filter).substr(1))}">
                                          From <strong>{{candidate.primary_email}}</strong> to
                                          <strong>{{value.email}}</strong>
                                        </span>
                                      </div>
                                    </ng-template>
                                    <ng-template #showOtherTags>
                                      <span class="badge-tag mr-3"
                                        [ngStyle]="{'background-color':value.color_code ? value.color_code : getFilterColor(add.activityFilter.filter_type, filter),
                                    'color':value.color_code ? getContrastYIQ(value.color_code.substr(1)): getContrastYIQ(getFilterColor(add.activityFilter.filter_type, filter).substr(1))}">
                                        {{filter | removeUndersoreCapitalizeFirst}}: <strong> {{value.description}}
                                          {{value.email}}
                                          {{filter == 'jobs' ? value.job_title : ''}}</strong>
                                      </span>
                                    </ng-template>
                                  </a>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
              <div *ngIf="loadingActivity" class="loading-view">
                <img [src]="globals.loading" class="loading-element" />
              </div>
              <div *ngIf="activities.length>0; then showActivity else hideActivity;"></div>
              <ng-template #hideActivity>
                <div class="row card-block">
                  <div class="col-12">
                    No information available
                  </div>
                </div>
              </ng-template>
              <ng-template #showActivity>
                <div [ngClass]="{'scroll scroll-comments':innerWidth > 1200}">
                  <div class="timeline-centered full-width">
                    <article class="timeline-entry" *ngFor="let activity of activities">
                      <div class="timeline-entry-inner" *ngIf="activity">
                        <div class="timeline-icon">
                          <i class="fa fa-circle big-search-primary-color"></i>
                        </div>
                        <div class="timeline-label">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="timeline-body mr-4" [innerHTML]="activity.title" *ngIf="activity.title"></div>
                              <span class="timeline-time-ago mb-2">{{activity.created_at |
                                dateDiffNav:activity.created_at
                                }}</span>
                              <div *ngFor="let tag of activity.tags" class="mt-2 display-inline-flex">
                                <span class="badge-tag ml-3"
                                  [ngStyle]="{'background-color': tag.color_code, 'color': getContrastYIQ(tag.color_code.substr(1))}">
                                  {{tag.description}}
                                </span>
                              </div>
                              <div *ngIf="activity.body" class="timeline-body mt-3 mr-4">
                                <div *ngIf="'email' == add.activityFilter.filter_type;then emailBody else activityBody">
                                </div>
                                <ng-template #emailBody>
                                  <div class="timeline-body-activity p-2" [innerHTML]="activity.body"></div>
                                </ng-template>
                                <ng-template #activityBody>
                                  <div class="timeline-body-activity p-2">
                                    <div *ngFor="let key of objectKeys(activity.body)">
                                      <span *ngIf="isArray(activity.body[key])">
                                        <div *ngIf="activity.body[key].length>0">
                                          <div *ngIf="checkArrayObjectValues(activity.body[key])">
                                            <strong> {{key | removeUndersoreCapitalizeFirst}}: </strong>
                                          </div>
                                          <div *ngFor="let child of activity.body[key]">
                                            <div *ngFor="let childKey of objectKeys(child)" class="pl-4">
                                              <div *ngIf="'skill' === childKey && child[childKey] ">
                                                {{child[childKey]}}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </span>
                                      <span *ngIf="!isArray(activity.body[key])">
                                        <span *ngIf="isObject(activity.body[key])">
                                          <div *ngIf="checkObjectValues(activity.body[key])">
                                            <strong> {{key | removeUndersoreCapitalizeFirst}}: </strong>
                                          </div>
                                          <div *ngFor="let childKey of objectKeys(activity.body[key])" class="pl-4">
                                            <span *ngIf="isObject(activity.body[key][childKey])">
                                              <div *ngIf="checkObjectValues(activity.body[key][childKey])">
                                                <strong> {{childKey | removeUndersoreCapitalizeFirst}}: </strong>
                                              </div>
                                              <div
                                                *ngFor="let nestedChildKey of objectKeys(activity.body[key][childKey])"
                                                class="pl-4">
                                                <div *ngIf="activity.body[key][childKey][nestedChildKey]">
                                                  <strong> {{nestedChildKey | removeUndersoreCapitalizeFirst}}:
                                                  </strong>
                                                  {{activity.body[key][childKey][nestedChildKey] |
                                                  removeUndersoreCapitalizeFirst}}
                                                </div>
                                              </div>
                                            </span>
                                            <span *ngIf="!isObject(activity.body[key][childKey])">
                                              <strong> {{childKey | removeUndersoreCapitalizeFirst}}: </strong>
                                              {{activity.body[key][childKey] | removeUndersoreCapitalizeFirst}}
                                            </span>
                                          </div>
                                        </span>
                                        <span *ngIf="!isObject(activity.body[key])">
                                          <div *ngIf="activity.body[key] !== null" class="display-inline-flex">
                                            <strong class="pr-1"> {{key | removeUndersoreCapitalizeFirst}}:</strong>
                                            <span [innerHTML]="activity.body[key]"></span>
                                          </div>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article class="timeline-entry begin">
                      <div class="timeline-entry-inner">
                        <div class="timeline-icon"
                          style="-webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg);">
                          <i class="fa fa-circle" style="color:#e5e5e5 !important;"></i>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="acc" *ngIf="!addActivity"></div>
    <div class="full-width" *ngIf="addActivity">
      <div class="card scroll scroll-closeJob">
        <div class="card-block">
          <div class="card-body">
            <div class="form">
              <label (click)="cancelActivity()">
                <i class="ft ft-arrow-left"></i>
                <strong class="cursor-pointer">Back to the candidate</strong>
              </label>
              <div class="form-section">
                Add activity
              </div>
              <div class="row card-block">
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Type *
                        <span *ngIf="!validForm && !candidateActivity.tags"
                          [ngClass]="{'primary-color': !candidateActivity.tags}">
                          {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                        </span>
                      </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select type"
                        [items]="resources.activity_tags" (open)="getActivityTags()"
                        [(ngModel)]="candidateActivity.type" [ngModelOptions]="{standalone: true}"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Job
                      </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="job_title" bindValue="uuid" dropdownPosition="bottom"
                        (open)="getJobs('open', null)" placeholder="Select job" [items]="resources.jobs.data"
                        [(ngModel)]="candidateActivity.jobs" [ngModelOptions]="{standalone: true}">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row card-block">
                <div class="col-md-12 col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Description *
                        <span *ngIf="!validForm && !candidateActivity.tags"
                          [ngClass]="{'primary-color': !candidateActivity.tags }">
                          {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                        </span>
                      </label>
                    </div>
                    <div class="card-body-content scroll quil-heigth">
                      <quill-editor id="data" name="data" [(ngModel)]="candidateActivity.data"
                        placeholder="Please enter data">
                      </quill-editor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-bottom full-width">
        <button type="button" class="btn pull-right bg-secondary-color" (click)="addActivityToDB()"> Add
          activity</button>
      </div>
    </div>
  </div>
</div>
