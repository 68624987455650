import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate{

  constructor(private authService: AuthService, private router: Router){ }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (!this.authService.isAuthenticated()){
      // Navigate to the login page
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    } else return true;
  }
}
