import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from 'app/globals';
import { DealroomWidgetService } from './dealroom-widget.service';

@Component({
  selector: 'app-dealroom-widget',
  templateUrl: './dealroom-widget.component.html',
  styleUrls: ['./dealroom-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DealroomWidgetComponent implements OnInit{
  @Input() companyName: string;

  showComponent = false;

  company: any = null;

  constructor(public globals: Globals, private service: DealroomWidgetService){}

  ngOnInit(){
  }

  getDealroomData(){
    const data = {
      keyword: this.companyName,
      keyword_type: "name",
      keyword_match_type: "exact",
      form_data: {
        must: {},
        must_not: {},
        should: {}
      },
      fields: "id,name,path,tagline,url,website_url,twitter_url,facebook_url,linkedin_url,google_url,crunchbase_url,angellist_url,playmarket_app_id,appstore_app_id,images(32x32, 74x74, 100x100),employees,employees_latest,industries,growth_stage,traffic_summary,hq_locations,client_focus,revenues,tags,delivery_method,launch_year,launch_month,total_funding,company_status,last_updated,kpi_summary,team,investors,fundings,sub_industries",
      limit: 1,
      sort: "-total_funding"
    };

    this.service.getCompanyDealroom(data)
      .subscribe((response) => {
        if (response.total){
          this.company = response.items[0];
          if (this.company.kpi_summary.valuations.length){
            this.company.valuation = {
              value: null,
              date: null
            };
            const convertM = 1000000;
            const valuation = this.company.kpi_summary.valuations[this.company.kpi_summary.valuations.length - 1].valuation;
            const minValuation = this.company.kpi_summary.valuations[this.company.kpi_summary.valuations.length - 1].valuation_min;
            const maxValuation = this.company.kpi_summary.valuations[this.company.kpi_summary.valuations.length - 1].valuation_max;
            this.company.valuation.value = valuation ? ((valuation / convertM) + "M") : minValuation ? ((minValuation / convertM) + "M") + (maxValuation ? ' - ' + (maxValuation / convertM) + "M" : '') : '';
            this.company.valuation.date = '(' + (this.company.kpi_summary.valuations[this.company.kpi_summary.valuations.length - 1].month ?
              this.company.kpi_summary.valuations[this.company.kpi_summary.valuations.length - 1].month + "/" : '')
              + this.company.kpi_summary.valuations[this.company.kpi_summary.valuations.length - 1].year + ')';
          }
        }
        this.showComponent = true;
      });
  }
}

