<div class="wrapper">
    <div class="app-sidebar" data-active-color="white" data-background-color="black">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <app-navbar></app-navbar>
    <div class="main-panel">
        <div class="main-content">
            <div class="content-wrapper">
                <div class="container-fluid">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <app-add-data-drawer></app-add-data-drawer>
        <app-candidate-drawer></app-candidate-drawer>
        <app-contact-drawer></app-contact-drawer>
        <app-company-drawer></app-company-drawer>
        <app-filter-drawer></app-filter-drawer>
        <app-job-drawer></app-job-drawer>
        <app-campaign-drawer></app-campaign-drawer>
    </div>
</div>