import { Component, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ToastrService } from 'ngx-toastr';
import { DataCandidateService } from '../candidatesData.service';

@Component({
  selector: 'app-candidate-rating-popup',
  templateUrl: './candidate-rating-popup.component.html',
  styleUrls: ['./candidate-rating-popup.component.scss']
})
export class CandidateRatingPopupComponent implements ICellEditorAngularComp, AfterViewInit{

  toggle = true;

  public candidateRatingOptions: any = [];

  public candidateRating: any = [];

  public applicationUuid: string;

  constructor(private dataCandidateService: DataCandidateService, private toastr: ToastrService){
  }

  ngAfterViewInit(){
  }

  agInit(params: any): void{
    this.candidateRating = params.node.data.candidate_rating;
    this.applicationUuid = params.node.data.application_uuid;
  }

  getValue(): any{
    return this.candidateRating;
  }

  destroy(): void{
  }

  isCancelBeforeStart(): boolean{
    return false;
  }

  isCancelAfterEnd(): boolean{
    return false;
  }

  isPopup(): boolean{
    return false;
  }

  getCandidateRating(){
    if (!this.candidateRatingOptions.length){
      this.dataCandidateService.getResourcesByType("candidate_application_rating")
      .subscribe((data) => {
        this.candidateRatingOptions = data;
      },
      (err) => {
        this.toastr.error(err.message);
      });
    }
  }

  changeRating(){
    const body = {
      candidate_rating: this.candidateRating
    };
    this.updateCandidate(this.applicationUuid, body);
  }

  updateCandidate(uuid, body){
    this.dataCandidateService.updateCandidateApplication(uuid, body).subscribe(
      () => {
        this.toastr.success('Successfully updated rating');
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp].length){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });
  }
}
