import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommunicationService } from 'app/shared/service/communication.service';
import { Globals } from 'app/globals';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements AfterViewInit, OnDestroy{
    paramsSubscription: Subscription;

    constructor(private activatedRoute: ActivatedRoute, private communicationService: CommunicationService, private globals: Globals){
    }

    ngAfterViewInit(){
        this.paramsSubscription = this.activatedRoute.queryParams.subscribe(params => {
            if (!params.filter && !params.feedback){
                this.openPlatformDrawer(params);
            }

        });
    }

    openPlatformDrawer(queryParams: any){
        switch (queryParams.drawer_parent){
            case 'add-data-drawer':
                {
                    if (queryParams.type == "add-job"){
                        if (this.globals.checkIfExists('permission', 'create_job') || (queryParams.job_uuid && this.globals.checkIfExists('permission', 'update_job'))){
                            this.communicationService.emitAddDataDrawer(queryParams.job_uuid ? queryParams.job_uuid : null, queryParams.type);
                        }
                    }
                    if (queryParams.type == "add-activity"){
                        if (this.globals.checkIfExists('permission', 'create_activities')){
                            this.communicationService.emitAddDataDrawer(queryParams.job_uuid ? queryParams.job_uuid : null, queryParams.type);
                        }
                    }
                    if (queryParams.type == "add-revenue"){
                        if (this.globals.checkIfExists('permission', 'create_revenue')){
                            this.communicationService.emitAddDataDrawer(queryParams.job_uuid ? queryParams.job_uuid : null, queryParams.type);
                        }
                    }
                    if (queryParams.type == "add-candidate"){
                        if (this.globals.checkIfExists('permission', 'add_candidates')){
                            this.communicationService.emitAddDataDrawer(null, queryParams.type);
                        }
                    }

                    if (queryParams.type == "add-contact"){
                        if (this.globals.checkIfExists('permission', 'create_contacts')){
                            this.communicationService.emitAddDataDrawer(null, queryParams.type);
                        }
                    }
                    break;
                }
            case 'candidate-drawer':
                {
                    if (queryParams.type == "view-candidate"){
                        if (this.globals.checkIfExists('permission', 'filter_candidates') || (queryParams.job_uuid && this.globals.checkIfExists('permission', 'read_candidates'))){
                            this.communicationService.emitCandidatetDataDrawer(
                                queryParams.candidate_uuid,
                                queryParams.type,
                                queryParams.job_uuid ? queryParams.job_uuid : null,
                                queryParams.parent_component ? queryParams.parent_component : null,
                                queryParams.candidate_index ? queryParams.candidate_index : null,
                                queryParams.filter_type ? queryParams.filter_type : null,
                                queryParams.recommended_job ? queryParams.recommended_job : null
                                );
                        }
                    }
                    break;
                }
            case 'company-drawer':
                {
                    if (queryParams.type == "company-activity" || queryParams.type == "company-report"){
                        if (this.globals.checkIfExists('permission', 'read_companies')){
                            this.communicationService.emitCompanyDataDrawer(queryParams.company_uuid, queryParams.type);
                        }
                    }
                    break;
                }
            case 'contact-drawer':
                {
                    if (queryParams.type == "view-contact"){
                        if (this.globals.checkIfExists('permission', 'read_contacts')){
                            this.communicationService.emitContactDataDrawer(queryParams.contact_uuid, queryParams.type);
                        }
                    }
                    break;
                }
            case 'job-drawer':
                {
                    if (queryParams.type == "close-job"){
                        if (this.globals.checkIfExists('permission', 'delete_job')){
                            this.communicationService.emitJobDataDrawer(queryParams.job_uuid, queryParams.type);
                        }
                    }
                    if (queryParams.type == "job-activity"){
                        if (this.globals.checkIfExists('permission', 'read_activities')){
                            this.communicationService.emitJobDataDrawer(queryParams.job_uuid, queryParams.type);
                        }
                    }
                    if (queryParams.type == "view-job"){
                        if (this.globals.checkIfExists('permission', 'create_job')){
                            this.communicationService.emitJobDataDrawer(queryParams.job_uuid, queryParams.type);
                        }
                    }
                    break;
                }
            case 'campaign-drawer':
                {
                    if (queryParams.type == "view-campaign"){
                        if (this.globals.checkIfExists('permission', 'view_campaigns')){
                            this.communicationService.emitCampaignDataDrawer(queryParams.campaign_uuid, queryParams.type);
                        }
                    }
                    break;
                }
            default: {
                break;
            }
        }
        this.closeOtherDrawers(queryParams.drawer_parent);
    }

    closeOtherDrawers(parent){
        const aside = document.getElementsByTagName('aside');
        if (document.getElementsByTagName('aside')){
            for (let i = 0; i < aside.length; i++){
                if (parent){
                    if (aside[i].getAttribute('id') != parent){
                        aside[i].style.visibility = "hidden";
                    }
                } else {
                    aside[i].style.visibility = "hidden";
                }
            }
        }
    }

    ngOnDestroy(){
        this.paramsSubscription.unsubscribe();
    }
}
